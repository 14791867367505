import { PURPOSE_NAMES } from './constants';

export type TemplateField = {
    purposeName: PURPOSE_NAMES;
    placeholder: string;
}

export function isPurposeName(value: string): value is PURPOSE_NAMES {
    return Object.values<string>(PURPOSE_NAMES).includes(value);
}
