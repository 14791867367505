import { DESIGN_VARIATION_ATTRIBUTE } from '~/shared/constants';

export const filterDesignVariationToVariableSelections = (
    productOptions: Gallery.ContentQuery.ProductOptions,
    variableOptions: string[],
    templateUseCases: Record<string, string>,
    numberOfPlaceholders?: string,
    variableAttributes?: string[],
    variableTemplateUseCases?: string[],
): DesignVariations.VariableSelections => {
    const variableSelections = Object.keys(productOptions)
        .reduce((accum, key) => {
            if (variableOptions.includes(key)) {
                return {
                    ...accum,
                    [key]: productOptions[key],
                };
            }

            return accum;
        }, {} as DesignVariations.VariableSelections);

    if (variableAttributes?.includes(DESIGN_VARIATION_ATTRIBUTE.NumberOfPhotos)) {
        variableSelections[DESIGN_VARIATION_ATTRIBUTE.NumberOfPhotos] = numberOfPlaceholders || '';
    }

    const variableTemplateUseCaseSelections = Object.keys(templateUseCases)
        .reduce((accum, key) => {
            if (variableTemplateUseCases?.includes(key)) {
                return {
                    ...accum,
                    [key]: templateUseCases[key],
                };
            }

            return accum;
        }, {} as DesignVariations.VariableSelections);

    return {
        ...variableSelections,
        ...variableTemplateUseCaseSelections,
    };
};
