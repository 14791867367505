import { Typography } from '@vp/swan';
import { useTranslations } from '~/client/hooks/useTranslations';

export const PersonalizationHeader = (props: { mobileStyle?: boolean }): JSX.Element => {
    const localize = useTranslations();
    const { mobileStyle } = props;

    return (
        <div className="personalization-header">
            <Typography fontSkin={mobileStyle ? 'title-section' : 'body-standard-bold'}>
                {localize('PersonalizationFilterHeader')}
            </Typography>
            <Typography fontSize={mobileStyle ? 'standard' : 'small'} fontWeight="normal" marginBottom={mobileStyle ? 'to-actions' : 0}>{localize('PersonalizationFilterDescription')}</Typography>
        </div>
    );
};

PersonalizationHeader.displayName = 'PersonalizationHeader';
