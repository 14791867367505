import { useSelector } from 'react-redux';
import config from 'config';

import { useTranslations } from 'client/hooks/useTranslations';
import { getCareData } from 'client/store/care';
import { getLocaleSelector } from 'client/store/config';
import { CareContactInfo } from 'client/components/Gallery/Footer/CareCallout/CareContactInfo';
import { queryChannelProviderPhoneData, QUERY_KEY } from 'client/queries/queryChannelProviderPhoneData';
import { useQuery } from '@tanstack/react-query';

export const CareCallout = (): JSX.Element | null => {
    const locale = useSelector(getLocaleSelector);
    const careData = useSelector(getCareData);
    const { data: channelProviderPhoneData } = useQuery(
        [QUERY_KEY, { locale, tenant: config.tenant }],
        queryChannelProviderPhoneData,
        {
            enabled: !!locale && !!config.tenant,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    );
    const localize = useTranslations();

    return (careData != null && channelProviderPhoneData != null) ? (
        <section aria-label={localize('CareRegionLabel')} className="care-callout care-callout-with-single-child">
            { careData.carePhone && careData.translations?.gallery && (
                <CareContactInfo carePhone={channelProviderPhoneData} translations={careData.translations} />
            )}
        </section>
    ) : null;
};

CareCallout.displayName = 'CareCallout';
