import { useSelector } from 'react-redux';
import { Typography, FlexBox } from '@vp/swan';
import { getProductName, getTLPL0Url } from 'client/store/config/reducer';
import { useTranslations } from 'client/hooks/useTranslations';

export const TLPL0Callout = (): JSX.Element | null => {
    const localize = useTranslations();
    const productName = useSelector(getProductName);
    const tlpL0Url = useSelector(getTLPL0Url);

    const ariaLabel = localize('ProductPageTLPL0Label', { productname: productName }, { prefix: '{{', suffix: '}}' });
    const anchor = `<a href=${tlpL0Url} aria-label=${ariaLabel}>${productName}</a>`;
    const text = localize('ProductPageTLPL0Label', { productname: anchor }, { prefix: '{{', suffix: '}}' });

    return (
        <FlexBox justifyContent="center">
            <Typography component="p" dangerouslySetInnerHTML={{ __html: text }} margin="0" />
        </FlexBox>
    );
};

TLPL0Callout.displayName = 'TLPL0Callout';
