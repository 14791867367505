import { memo, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { StickyHeader } from 'client/components/Gallery/Header/StickyHeader';
import { StaticHeader } from 'client/components/Gallery/Header/StaticHeader';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { PersonalizationWrapper } from '../Filters/PersonalizationFilter/PersonalizationWrapper';

export const Header = memo((): JSX.Element => {
    const { ref: headerRef, inView, entry } = useInView();
    const headerIsVisible = useMemo(() => (inView || !entry), [inView, entry]);

    return (
        <div className="gallery-header" id={galleryHeaderId} ref={headerRef}>
            <PersonalizationWrapper>
                {!headerIsVisible && <StickyHeader />}
                {headerIsVisible && <StaticHeader />}
            </PersonalizationWrapper>
        </div>
    );
});

Header.displayName = 'Header';
