import { useDispatch } from 'react-redux';

import { refinementRemove } from 'client/store/refinement';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

type CurryClearAllFunc = (selected: Refinements.Refinements) => (event: React.MouseEvent<HTMLButtonElement>) => void;

export const useClearRefinements = (): { curryClearAll: CurryClearAllFunc } => {
    const dispatch = useDispatch();
    const redirectToL0 = useRedirectToL0();

    const curryClearAll = (selected: Refinements.Refinements) => (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        const refinements = Object.values(selected);

        if (redirectToL0([], refinements)) {
            return;
        }

        dispatch(refinementRemove(refinements));
    };

    return { curryClearAll };
};
