import {
    Column,
    GridContainer,
    H2,
    Row,
    Typography,
} from '@vp/swan';
import { getMetadata } from 'client/store/config/reducer';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';

export const SecondaryContent = (): JSX.Element => {
    const galleryMetadata = useSelector(getMetadata);

    return (
        <GridContainer className="secondary-content-container" component="section">
            <Row>
                <Column span={12} spanLg={10} spanMd={10}>
                    <H2>
                        {galleryMetadata?.secondaryHeader}
                    </H2>
                </Column>
            </Row>
            {galleryMetadata?.secondaryContent && (
                <Row>
                    <Column span={12} spanLg={10} spanMd={10}>
                        <Typography component="p">
                            <ReactMarkdown>{galleryMetadata.secondaryContent}</ReactMarkdown>
                        </Typography>
                    </Column>
                </Row>
            )}
        </GridContainer>
    );
};
