import { AnyAction } from 'redux';

const initialState: State.ParentByChildTemplateUseCaseIdState = {};

export function reducer(
    state: State.ParentByChildTemplateUseCaseIdState = initialState,
    action: AnyAction,
): State.ParentByChildTemplateUseCaseIdState {
    switch (action.type) {
        default:
            return state;
    }
}
