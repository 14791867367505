import { useSelector } from 'react-redux';
import { RatingReviewData } from '@vp/rating-reviews-component';
import config from 'config';

import { getLocaleSelector, getMpvid } from 'client/store/config';
import { useQueryRatingsAndReviews } from 'client/hooks/useQueryRatingsAndReviews';
import { getTlpLevel } from 'client/store/config/reducer';

export const useRatingsAndReviews = (): RatingReviewData | undefined => {
    const locale = useSelector(getLocaleSelector);
    const mpvId = useSelector(getMpvid);
    const tlpLevel = useSelector(getTlpLevel);

    const { data: ratingsData } = useQueryRatingsAndReviews(config.tenant, locale, mpvId, tlpLevel, {
        enabled: !!config.tenant && !!locale && !!mpvId,
        staleTime: Infinity,
        retry: false,
    });

    return ratingsData;
};
