// If you add to this, also consider adding to dpcToBrand to include the new field in Brand Kit
export enum PURPOSE_NAMES {
    COMPANY_NAME = 'companyname',
    FULL_NAME = 'fullname',
    FAMILY_NAME = 'familyname'
}

export const TEXT_INPUT_DEBOUNCE_TIME_MS = 700;

// Increase this after we switch to using Design Memory
export const MAX_UPLOADS = 5;
