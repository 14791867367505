import { createSelector } from 'reselect';

import { currentPageSizeSelector, loadMoreCountSelector, hasMoreTemplatesSelector } from 'client/store/paging/selectors';
import { MINIMUM_TEMPLATES_SIZE } from 'client/store/paging/constants';

export const getIsLoadMoreAvailable = createSelector([
    loadMoreCountSelector,
    currentPageSizeSelector,
    hasMoreTemplatesSelector,
], (
    loadMoreCount,
    currentPageSize,
    hasMoreTemplates,
) => {
    const canLoadMoreWithinPageLimit = loadMoreCount + 1 < currentPageSize / MINIMUM_TEMPLATES_SIZE;

    return hasMoreTemplates && canLoadMoreWithinPageLimit;
});
