import {
    useContext,
} from 'react';
import {
    ModalDialog,
    Button,
    Icon,
} from '@vp/swan';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { designPersonalizationContextSelector, photoPreviewDataSelector } from '~/client/store/personalization/selectors';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { useTranslations } from 'client/hooks/useTranslations';
import { selectedPhotosState } from '~/client/atoms/selectedPhotosAtom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { mobilePersonalizationOpenState } from '~/client/atoms/mobilePersonalizationOpenAtom';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { getRawExperiments } from '~/client/store/experimentation';
import { useAuth } from '~/client/hooks/useAuth';
import { dpcToTextFields } from '../../../Filters/PersonalizationFilter/dpcConverters';
import { usePurposeNames } from '../hooks/usePurposeNames';
import { PersonalizationImageModalContent } from '../../../Filters/PersonalizationFilter/PersonalizationImageModalContent';
import { MobilePersonalizationModalContent } from './MobilePersonalizationModalContent';
import { usePersonalizationFlyoutOpen } from '../hooks';

export const MobilePersonalization = (): JSX.Element | null => {
    const {
        experimentName,
        experimentVariation,
        setMobileTextfieldValues,
    } = useContext(PersonalizationReactContext);
    const [personalizationModalOpen, setPersonalizationModalOpen] = useRecoilState(mobilePersonalizationOpenState);
    const [uploadModalOpen, setUploadModalOpen] = usePersonalizationFlyoutOpen();
    const localize = useTranslations();
    const dpc = useSelector(designPersonalizationContextSelector);
    const purposeNames = usePurposeNames();
    const personalizedText = dpcToTextFields(purposeNames, dpc);
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const photoPreviews = useSelector(photoPreviewDataSelector);
    const setSelectedPhotos = useSetRecoilState(selectedPhotosState);
    const auth = useAuth();

    const resetToTemplateData = (): void => {
        setMobileTextfieldValues?.(personalizedText);
        setSelectedPhotos(photoPreviews);
    };

    const handleModalOpen = ((): void => {
        setPersonalizationModalOpen(true);
        resetToTemplateData();

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            eventLabel: 'Personalization Button',
            eventDetail: 'Personalization Mobile Modal Open',
            ...analytics.getPageProperties(),
        });

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            'Personalization Button',
            experimentName,
            'Personalization Mobile Modal Open',
        );
    });

    const handleModalClose = ((): void => {
        if (!uploadModalOpen) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
                eventLabel: 'Personalization Close Button',
                eventDetail: 'Personalization Mobile Modal Closed',
                ...analytics.getPageProperties(),
            });
        }

        setPersonalizationModalOpen(false);
        setUploadModalOpen(false);
        resetToTemplateData();
    });

    return auth ? (
        <>
            <Button
                aria-labelledby="personalization-button-label"
                buttonShape="round"
                className={classnames('mobile-rounded-icon-button', 'mobile-personalization-button')}
                iconPosition="left"
                paddingX="5"
                skin="primary"
                width="full-width"
                onClick={handleModalOpen}
            >
                {localize('PersonalizationFilterHeader')}
                <Icon className="personalization-button-icon" iconType="magic" />
            </Button>
            <ModalDialog
                className={uploadModalOpen ? 'personalization-image-modal' : 'mobile-personalization-dialog'}
                isOpen={personalizationModalOpen}
                variant="panel-right"
                onRequestDismiss={handleModalClose}
            >
                {uploadModalOpen ? (
                    <PersonalizationImageModalContent />
                ) : (
                    <MobilePersonalizationModalContent />
                )}
            </ModalDialog>
        </>
    ) : (
        <Button
            loadingShimmer
            buttonShape="round"
            className="mobile-personalization-button"
            iconPosition="left"
            paddingX="5"
            skin="primary"
            width="full-width"
        />
    );
};

MobilePersonalization.displayName = 'MobilePersonalization';
