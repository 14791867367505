import { NoContentMessage } from 'client/components/Gallery/PreviewArea/NoResultsMessage/NoContentMessage';
import { NoKeywordResultMessage } from 'client/components/Gallery/PreviewArea/NoResultsMessage/NoKeywordResultMessage';
import { useSearchParams } from 'react-router-dom';

export const NoResultsMessage = (): JSX.Element => {
    const [searchParams] = useSearchParams();

    const keyword = searchParams.get('keyword');

    return (
        <div className="preview-message py-m">
            {!keyword && (<NoContentMessage />)}
            {keyword && (<NoKeywordResultMessage keyword={keyword} />)}
        </div>
    );
};

NoResultsMessage.displayName = 'NoResultsMessage';
