import {
    memo, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@vp/swan';
import classNames from 'classnames';

import { useTranslations } from 'client/hooks/useTranslations';
import { Skeleton } from 'client/components/common/Skeleton';
import { RenderProperty } from 'shared/renderProperties';
import {
    Tile,
    TileContent,
    TileHeader,
} from 'client/components/common/Tile';
import { useInView } from 'react-intersection-observer';
import { contentUpdate } from '~/client/store/content/actions';
import { stringRenderPropertySelector } from '~/client/store/config/reducer';
import { getIsLoadMoreAvailable } from '~/client/store/config/selectors';
import { LOAD_MORE_DELAY, ROOT_MARGIN, TileSize } from 'client/components/Gallery/PreviewArea/DesignSkeleton/data';
import { useShowNewTemplateTile } from '~/client/hooks/newTemplateTile/useShowNewTemplateTile';

interface DesignSkeletonProps {
    height: number
}

export const DesignSkeleton = memo(({ height }: DesignSkeletonProps): JSX.Element => {
    const localize = useTranslations();
    const dispatch = useDispatch();
    const shouldRenderNewTemplateTile = useShowNewTemplateTile();

    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const isLoadMoreAvailable = useSelector(getIsLoadMoreAvailable);

    const tilePreviewSize = stringRenderProperty(RenderProperty.TilePreviewSize);

    const isLargePreviewSize = tilePreviewSize === 'Large';
    const skeletonSize = isLargePreviewSize ? TileSize.Large : TileSize.Default;

    const { ref, inView } = useInView({ rootMargin: ROOT_MARGIN });
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const skeletonTiles = useMemo(() => Array.from(Array(skeletonSize).keys()), [skeletonSize]);

    const shouldLoadMore = inView && isLoadMoreAvailable;

    useEffect(() => {
        // Timeout adds delay before loading more. Сonveniently for Ratings & Reviews link
        clearTimeout(timerRef.current ?? 0);
        timerRef.current = setTimeout(() => {
            if (shouldLoadMore) {
                dispatch(contentUpdate({
                    shouldUpdateUrl: false,
                    loadMore: true,
                }));
            }

            return () => clearTimeout(timerRef.current ?? 0);
        }, LOAD_MORE_DELAY);
    }, [dispatch, shouldLoadMore]);

    if (shouldRenderNewTemplateTile) {
        return (
            <>
                {skeletonTiles.map((key) => (
                    <Tile
                        className="new-template-tile-skeleton-wave"
                        key={key}
                        ref={ref}
                        type="design-tile"
                    >
                        <TileHeader>
                            <div className="tile-preview">
                                <div className={classNames('skeleton-height', { 'skeleton-height-large': isLargePreviewSize })}>
                                    <div className="mcp-img-loading">
                                        <Spinner accessibleText={localize('Loading')} size="standard" />
                                    </div>
                                </div>
                            </div>
                        </TileHeader>
                    </Tile>
                ))}
            </>
        );
    }

    return (
        <>
            {skeletonTiles.map((key) => (
                <Tile
                    key={key}
                    ref={ref}
                    type="design-tile"
                >
                    <TileHeader>
                        <div className="tile-preview">
                            <div style={{ height }}>
                                <div className="mcp-img-loading">
                                    <Spinner accessibleText={localize('Loading')} size="standard" />
                                </div>
                            </div>
                        </div>
                    </TileHeader>
                    <TileContent>
                        <span className="price-block">
                            <Skeleton />
                        </span>
                    </TileContent>
                </Tile>
            ))}
        </>
    );
});
