import { atom } from 'recoil';

export const currentFavoritesState = atom<Map<string, string>>({
    key: 'currentFavoritesState',
    default: new Map<string, string>(),
});

export const merchModulesCurrentFavoritesState = atom<Map<string, string>>({
    key: 'merchModulesCurrentFavoritesState',
    default: new Map<string, string>(),
});
