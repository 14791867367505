import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { templateUseCaseOptionsSelectorFactory, selectedL1UseCaseSelectorFactory } from 'client/components/Gallery/Filters/util';
import { FILTER_TYPE } from '~/shared/constants';
import { FlatTemplateUseCaseFilter } from 'src/client/components/Gallery/Filters/FlatTemplateUseCaseFilter';
import { FacetedTemplateUseCaseFilter } from 'src/client/components/Gallery/Filters/FacetedTemplateUseCaseFilter';

export interface PropTypes {
    filter: State.Filter;
}

export const TemplateUseCaseFilter = (props: PropTypes): JSX.Element | null => {
    const { filter } = props;
    const templateUseCaseFilter = filter as State.TemplateUseCaseFilter;

    const templateUseCaseId = templateUseCaseFilter.templateUseCaseId.toString();

    const selectedL1Selector = useMemo(selectedL1UseCaseSelectorFactory, []);
    const templateUseCaseOptionsSelector = useMemo(templateUseCaseOptionsSelectorFactory, []);

    const { selectedTemplateUseCaseId } = useSelector(
        (state: State.GlobalState) => selectedL1Selector(state, templateUseCaseId),
    );

    const resolvedSelectedTemplateUseCaseId = selectedTemplateUseCaseId || templateUseCaseId;
    const isFlat = templateUseCaseFilter.type === FILTER_TYPE.FLAT_TEMPLATE_USE_CASE;
    const { options, selectedValues, visible } = useSelector((state: State.GlobalState) => (
        templateUseCaseOptionsSelector(
            state,
            isFlat ? templateUseCaseId : resolvedSelectedTemplateUseCaseId,
        )
    ));

    const shouldShowFilter = visible && options.length > 0;

    if (!shouldShowFilter) {
        return null;
    }

    return isFlat
        ? (
            <FlatTemplateUseCaseFilter
                filter={templateUseCaseFilter}
                options={options}
                selectedValues={selectedValues}
            />
        )
        : (
            <FacetedTemplateUseCaseFilter
                filter={templateUseCaseFilter}
                options={options}
                selectedTemplateUseCaseId={selectedTemplateUseCaseId}
                templateUseCaseId={templateUseCaseId}
            />
        );
};

TemplateUseCaseFilter.displayName = 'TemplateUseCaseFilter';
