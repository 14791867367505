/* eslint-disable @typescript-eslint/indent */
import { createSelector, OutputParametricSelector } from 'reselect';

import { facetByIdSelector } from 'client/store/facet';
import { filterOptionByIdSelector } from 'client/store/filterOption';
import { refinementsHasRefSelector } from 'client/store/refinement';
import { categoryByIdSelector } from 'client/store/filterCategories';
import { stringSort } from 'client/utils/sort';
import { templateUseCaseByIdSelector } from '~/client/store/filterTemplateUseCases';

/**
 * Builds a `RenderableFilterOption` for each option value, getting the label
 *   and other details like facet count, selected state, and disabled status
 * @param {State.GlobalState} state
 * @param {string[]} options
 */
export const buildFilterOptions = (state: State.GlobalState, options: string[]): RenderableFilterOption[] => {
    const getFacet = facetByIdSelector(state);
    const getOption = filterOptionByIdSelector(state);
    const isSelected = refinementsHasRefSelector(state);

    return options.map((o: string) => {
        const facet = getFacet(o);
        const option = getOption(o) as RenderableFilterOption;

        option.selected = !!isSelected(o);
        option.facetCount = 0;
        option.disabled = !facet || facet.count <= 0;
        return option;
    });
};

/**
 * Returns the first selected child category for the given root
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectedL1SelectorFactory = (): OutputParametricSelector<State.GlobalState, string, {
    selectedCategoryId: string | null;
}, (res1: (categoryId: string) => State.Category, res2: (refinement: string) => boolean, res3: string) => {
    selectedCategoryId: string | null;
}> => createSelector(
    categoryByIdSelector,
    refinementsHasRefSelector,
    (_state: State.GlobalState, rootId: string) => rootId,
    (getCategory, getSelected, rootId): { selectedCategoryId: string | null } => {
        const root = getCategory(rootId);

        if (!root || !root.children || root.children.length <= 0) {
            return {
                selectedCategoryId: null,
            };
        }

        const selectedId = root.children.find((c: string) => !!getSelected(c));

        return {
            selectedCategoryId: selectedId || null,
        };
    },
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectedChildrenSelectorFactory = (): OutputParametricSelector<
State.GlobalState,
string,
SelectedValues,
(resId: (categoryId: string) => State.Category,
resRefinement: (refinement: string) => boolean,
res3: string,) => SelectedValues> => createSelector(
    categoryByIdSelector,
    refinementsHasRefSelector,
    (_state: State.GlobalState, rootId: string) => rootId,
    (getCategory, getSelected, rootId): SelectedValues => {
        const root = getCategory(rootId);

        if (!root || !root.children || root.children.length <= 0) {
            return {};
        }

        return root.children
            .reduce((accum, c) => {
                const selected = !!getSelected(c);

                return {
                    ...accum,
                    [c]: selected,
                };
            }, {} as SelectedValues);
    },
);

/**
 * Returns a selector to generate sorted filter options for a given category filter
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const categoryOptionsSelectorFactory = (): OutputParametricSelector<State.GlobalState, string, {
    options: RenderableCategoryFilterOption[];
    selectedValues: SelectedValues;
    visible: boolean;
}, (
res1: (categoryId: string) => State.Category,
res2: (facet: string) => Gallery.ContentQuery.Facet,
res3: (refinement: string) => boolean,
res4: Gallery.ContentQuery.CategoryFilerOptions) => Record<string, unknown>> => createSelector(
    categoryByIdSelector,
    facetByIdSelector,
    refinementsHasRefSelector,
    (_state: State.GlobalState, rootId: string, shouldAddDisabled: boolean) => ({ rootId, shouldAddDisabled }),
    (getCategory, getFacet, getSelected, { rootId, shouldAddDisabled }) => {
        const root = getCategory(rootId);
        const selectedValues: SelectedValues = {};

        if (!root || !root.children || root.children.length <= 0) {
            return {
                options: [],
                selectedValues,
                visible: false,
            };
        }

        let enabled = false; // the filter will be rendered if there is at least one
        let options = root.children.map((c: string) => {
            const category = getCategory(c);
            const facet = getFacet(c);
            const disabled = !facet || facet.count <= 0;

            if (!disabled) {
                enabled = true;
            }

            const selected = !!getSelected(c);

            if (selected) {
                selectedValues[c] = selected;
            }

            return {
                disabled,
                selected,
                value: c,
                title: category.title,
                facetCount: 0,
            };
        }) as RenderableCategoryFilterOption[];

        // sort, rank alphabetically, removing unavailable options
        options = options.filter((o) => shouldAddDisabled || !o.disabled)
            .sort(stringSort('title'))
            .map((c: RenderableCategoryFilterOption, i: number) => ({
                ...c,
                rank: i,
            }));

        return {
            options,
            selectedValues,
            visible: enabled,
        };
    },
);

/**
 * Returns a selector to generate sorted filter options for a given use case filter
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const templateUseCaseOptionsSelectorFactory = (): OutputParametricSelector<State.GlobalState, string, {
    options: RenderableTemplateUseCaseFilterOption[];
    selectedValues: SelectedValues;
    visible: boolean;
}, (
    resTemplateUseCaseId: (templateUseCaseId: string) => State.TemplateUseCase,
    resFacet: (facet: string) => Gallery.ContentQuery.Facet,
    resRefinement: (refinement: string) => boolean,
    res4: Gallery.ContentQuery.TemplateUseCaseFilterOptions) => Record<string, unknown>> => createSelector(
        templateUseCaseByIdSelector,
        facetByIdSelector,
        refinementsHasRefSelector,
        (_state: State.GlobalState, rootId: string, shouldAddDisabled: boolean) => ({ rootId, shouldAddDisabled }),
        (getTemplateUseCase, getFacet, getSelected, { rootId, shouldAddDisabled }) => {
            const root = getTemplateUseCase(rootId);
            const selectedValues: SelectedValues = {};

            if (!root || !root.children || root.children.length <= 0) {
                return {
                    options: [],
                    selectedValues,
                    visible: false,
                };
            }
            let enabled = false; // the filter will be rendered if there is at least one
            let options = root.children.map((c: string) => {
                const templateUseCase = getTemplateUseCase(c);
                const facet = getFacet(c);
                const disabled = !facet || facet.count <= 0;

                if (!disabled) {
                    enabled = true;
                }

                const selected = !!getSelected(c);

                if (selected) {
                    selectedValues[c] = selected;
                }

                return {
                    disabled,
                    selected,
                    value: c,
                    title: templateUseCase.title,
                    facetCount: 0,
                };
            }) as RenderableTemplateUseCaseFilterOption[];

            // sort, rank alphabetically, removing unavailable options
            options = options.filter((o) => shouldAddDisabled || !o.disabled)
                .sort(stringSort('title'))
                .map((c: RenderableTemplateUseCaseFilterOption, i: number) => ({
                    ...c,
                    rank: i,
                }));

            return {
                options,
                selectedValues,
                visible: enabled,
            };
        },
    );

/**
* Returns the first selected child use case for the given root
*/
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectedL1UseCaseSelectorFactory = (): OutputParametricSelector<State.GlobalState, string, {
    selectedTemplateUseCaseId: string | null;
}, (resTemplateUseCaseId: (templateUseCaseId: string) => State.TemplateUseCase,
    resRefinement: (refinement: string) => boolean, res3: string) => {
        selectedTemplateUseCaseId: string | null;
}> => createSelector(
    templateUseCaseByIdSelector,
    refinementsHasRefSelector,
    (_state: State.GlobalState, rootId: string) => rootId,
    (getUseCase, getSelected, rootId): { selectedTemplateUseCaseId: string | null } => {
        const root = getUseCase(rootId);

        if (!root || !root.children || root.children.length <= 0) {
            return {
                selectedTemplateUseCaseId: null,
            };
        }

        const selectedId = root.children.find((c: string) => !!getSelected(c));

        return {
            selectedTemplateUseCaseId: selectedId || null,
        };
    },
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectedUseCaseChildrenSelectorFactory = (): OutputParametricSelector<
    State.GlobalState,
    string,
    SelectedValues,
    (res1: (templateUseCaseId: string) => State.TemplateUseCase,
        res2: (refinement: string) => boolean,
        res3: string,) => SelectedValues> => createSelector(
            templateUseCaseByIdSelector,
            refinementsHasRefSelector,
            (_state: State.GlobalState, rootId: string) => rootId,
            (getUseCase, getSelected, rootId): SelectedValues => {
                const root = getUseCase(rootId);

                if (!root || !root.children || root.children.length <= 0) {
                    return {};
                }

                return root.children
                    .reduce((accum, c) => {
                        const selected = !!getSelected(c);

                        return {
                            ...accum,
                            [c]: selected,
                        };
                    }, {} as SelectedValues);
            },
        );
