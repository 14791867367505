import { Typography } from '@vp/swan';
import DOMPurify from 'isomorphic-dompurify';

import { useTranslations } from 'client/hooks/useTranslations';

export interface PropTypes extends React.HTMLProps<HTMLDivElement> {
    keyword: string | undefined;
}

export const NoKeywordResultMessage = (props: PropTypes): JSX.Element => {
    const { keyword } = props;
    const localize = useTranslations();

    const sanitizedKeyword = keyword ? DOMPurify.sanitize(keyword) : '';

    const message = `<span class="search-term">${sanitizedKeyword}</span>`;
    const messageTitle = localize('GalleryDefaultResultMessage', { searchterm: message });

    return (
        <>
            <Typography component="p" dangerouslySetInnerHTML={{ __html: messageTitle }} fontSize="large" fontWeight="bold" />
            <Typography component="p" fontSize="small" fontWeight="bold">
                {localize('GalleryDefaultSearchMessageLine')}
            </Typography>
            <ul className="stylized-list">
                <li>{localize('GalleryDefaultSearchMessageLineThree')}</li>
                <li>{localize('GalleryDefaultSearchMessageLineTwo')}</li>
                <li>{localize('GalleryDefaultSearchMessageLineOne')}</li>
            </ul>
        </>
    );
};

NoKeywordResultMessage.displayName = 'NoKeywordResultMessage';
