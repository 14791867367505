import { atom } from 'recoil';

// Header Hierarchry Test: Boths recoil states are needed to allow the mobile search button to open
// the filters flyout and focus the search input.

export const filtersFlyoutOpenState = atom<boolean>({
    key: 'filtersFlyoutOpenState',
    default: false,
});

export const searchAutoFocusedState = atom<boolean>({
    key: 'searchAutoFocusedState',
    default: false,
});
