import { AnyAction } from 'redux';

export const SET_EXPANDED = 'SET_EXPANDED';

export function setExpandedForFilter(filterId: string, expanded: boolean): AnyAction {
    return {
        type: SET_EXPANDED,
        payload: {
            filterId,
            expanded,
        },
    };
}
