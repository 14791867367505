import { useContext } from 'react';
import { FilterMenu } from 'client/components/Gallery/Header/FilterMenu';
import { SearchBox } from 'client/components/Gallery/Header/Searchbox';
import { FavoritesCTA } from 'client/components/Gallery/Header/Favorites';
import { useAuth } from 'client/hooks/useAuth';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { SearchButton } from 'client/components/Gallery/Header/SearchButton';
import { WarningBox } from 'client/components/Gallery/Header/WarningBox';
import { MobilePersonalization } from '~/client/components/Gallery/Header/Personalization/MobilePersonalization';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';

export const StaticHeader = (): JSX.Element => {
    const { isSmallScreenPersonalizationEnabled } = useContext(PersonalizationReactContext);
    const showFavorites = useFavorites();
    const auth = useAuth();

    const shouldShowFavorites = showFavorites && auth;

    return (
        <div className="header-wrapper">
            <div className="subheader-actions">
                <SearchBox />
                <SearchButton shouldHideText={isSmallScreenPersonalizationEnabled} />
                {shouldShowFavorites && (<FavoritesCTA className="mobile-rounded-icon-button" userInteractionLocation="staticHeader" />)}
                <FilterMenu shouldRenderMobilePersonalizationFilter={isSmallScreenPersonalizationEnabled} />
                {isSmallScreenPersonalizationEnabled && <MobilePersonalization />}
            </div>
            <WarningBox />
        </div>
    );
};

StaticHeader.displayName = 'StaticHeader';
