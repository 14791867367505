export const mapFavoritesWorkIdsToEntityIds = (
    tileEntityIds: string[],
    favorites: VP.DTT.Models.WorkEntityService.WorkEntity<PreviewInfo>[] | undefined,
): Map<string, string> => tileEntityIds.reduce((map, tileEntityId) => map.set(
    tileEntityId,
    favorites?.find(
        (favorite) => (
            favorite.design.metadata.designId === tileEntityId
        ),
    )?.workId,
), new Map());
