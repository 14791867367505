import { AnyAction } from 'redux';
import { PAGE_SIZE_UPDATE, PAGE_UPDATE } from 'client/store/paging/actions';

import {
    CONTENT_UPDATE,
    CONTENT_REVERT,
    LOCATION_POP,
} from 'client/store/constants';
import { REFINEMENT_UPDATE } from 'client/store/refinement/actions';
import { INITIAL_STATE, INITIAL_STATE as DEFAULT_PAGE_STATE } from 'client/store/paging/constants';
import { SORTING_STRATEGY_UPDATE, TEMPLATE_PURPOSES_UPDATE } from '../debug/actions';

export function reducer(
    state: State.PagingState = INITIAL_STATE,
    action: AnyAction,
): State.PagingState {
    switch (action.type) {
        case LOCATION_POP:
            return {
                ...state,
                page: (action.payload as Gallery.Models.Url.QueryStringState).paging.page || INITIAL_STATE.page,
                pageSize:
                    (action.payload as Gallery.Models.Url.QueryStringState).paging.pageSize || INITIAL_STATE.pageSize,
            };
        case PAGE_SIZE_UPDATE:
            return {
                ...state,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                loadMoreCount: DEFAULT_PAGE_STATE.loadMoreCount,
            };
        case PAGE_UPDATE:
            return {
                ...state,
                page: action.payload.page,
                loadMoreCount: DEFAULT_PAGE_STATE.loadMoreCount,
            };
        case TEMPLATE_PURPOSES_UPDATE:
        case SORTING_STRATEGY_UPDATE:
        case REFINEMENT_UPDATE:
            // reset the page to page 1 when a filter is added
            return {
                ...state,
                page: INITIAL_STATE.page,
            };
        case CONTENT_UPDATE:
            return {
                ...state,
                ...action.payload.paging,
            };
        case CONTENT_REVERT:
            return {
                ...action.payload.paging,
            };
        default:
            return state;
    }
}
