import { BasicCollapsible } from '@vp/swan';
import { FilterCheckboxList } from 'client/components/Gallery/Filters/Shared/FilterCheckboxList';
import { REFINEMENT_DIMENSION } from '~/shared/constants';

export interface PropTypes {
    filter: State.TemplateUseCaseFilter;
    options: RenderableTemplateUseCaseFilterOption[];
    selectedValues: SelectedValues;
}

export const FlatTemplateUseCaseFilter = (props: PropTypes): JSX.Element | null => {
    const { filter, options, selectedValues } = props;

    return (
        <BasicCollapsible
            aria-label={filter.title}
            className="filter"
            collapsibleId={filter.name}
            heading={filter.title}
            role="button"
        >
            <FilterCheckboxList
                options={options}
                refinementDimension={REFINEMENT_DIMENSION.TEMPLATE_USE_CASE}
                selectedValues={selectedValues}
                title={filter.title}
            />
        </BasicCollapsible>
    );
};

FlatTemplateUseCaseFilter.displayName = 'FlatTemplateUseCaseFilter';
