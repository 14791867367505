import classnames from 'classnames';
import {
    ANALYTICS_CATEGORY, ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENT_DETAILS, ANALYTICS_LABEL,
} from '~/shared/constants';
import { MERCH_MODULES_VARIATIONS } from '~/experiments/MerchModules/constants';
import { isExperimentActive } from '~/shared/ab-testing';

export const handleMerchAnalytics = (
    analytics: Gallery.Analytics.IAnalytics,
    eventLabel: ANALYTICS_LABEL,
): void => analytics.trackEvent({
    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
    category: ANALYTICS_CATEGORY.ALL,
    eventDetail: ANALYTICS_EVENT_DETAILS.MERCH_MODULES,
    eventLabel,
});

export const getMerchModulesRowClass = (rawExperiments: string | undefined): string | null => {
    const isMerchModulesEnabledRow2 = isExperimentActive(
        MERCH_MODULES_VARIATIONS.EnabledRow2,
        rawExperiments,
    );

    const isMerchModulesEnabledRow6 = isExperimentActive(
        MERCH_MODULES_VARIATIONS.EnabledRow6,
        rawExperiments,
    );

    const isMerchModulesEnabledRow12 = isExperimentActive(
        MERCH_MODULES_VARIATIONS.EnabledRow12,
        rawExperiments,
    );

    const isMerchModulesEnabledRow18 = isExperimentActive(
        MERCH_MODULES_VARIATIONS.EnabledRow18,
        rawExperiments,
    );

    return classnames({
        'row-2': isMerchModulesEnabledRow2,
        'row-6': isMerchModulesEnabledRow6,
        'row-12': isMerchModulesEnabledRow12,
        'row-18': isMerchModulesEnabledRow18,
    });
};

export const isMerchModulesEnabled = (rawExperiments: string | undefined): boolean => !!getMerchModulesRowClass(rawExperiments);
