import { PaginationStep as SwanPaginationStep } from '@vp/swan';

import { useLinkParameters } from 'client/hooks/useLinkParameters';
import { ComponentProps } from 'react';

export interface PropTypes extends ComponentProps<typeof SwanPaginationStep> {
    current?: boolean;
    pageText: string;
    pageNumber: number;
}

export const PaginationStep = (props: PropTypes): JSX.Element => {
    const {
        current,
        pageText,
        pageNumber,
        ...restProps
    } = props;

    const linkHref = useLinkParameters(pageNumber);

    return (
        <SwanPaginationStep
            active={!!current}
            aria-current={!!current}
            href={linkHref}
            {...restProps}
        >
            {pageText}
        </SwanPaginationStep>
    );
};

PaginationStep.displayName = 'PaginationStep';
