import { AbstractService, AbstractServiceConfig, LoggerParameter } from 'services/AbstractService';
import { ServiceError } from 'services/errors';

export class GlobalChannelProviderServiceApi extends AbstractService implements VP.Care.ChannnelProvider.Services.IChannelProviderService {
    apiVersion: number;

    constructor({ version, ...rest }: AbstractServiceConfig, logger: LoggerParameter) {
        super(rest, logger);
        this.apiVersion = version;
    }

    /**
     * Request Channel Provider Phone Data and Schedule
     * @param locale
     * @param tenant
     */
    public async getPhoneData(locale: i18n.Locale, tenant: string):
    Promise<VP.Care.Models.ChannelProviderService.PhoneData> {
        const url = 'v3/channels/phone/params';

        try {
            const response = await this.api.get<VP.Care.Models.ChannelProviderService.PhoneData>(
                url,
                {
                    params: {
                        locale,
                        tenant,
                    },
                    headers: {
                        'x-api-key': process.env.GCP_API_KEY,
                    },
                },
            );

            if (!response) {
                throw new Error(`No response recieved for request ${url}`);
            }

            return response.data as VP.Care.Models.ChannelProviderService.PhoneData;
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from Channel Provider Service: ${(e as Error).message}`,
            }, e as Error);
        }
    }
}
