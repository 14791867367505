import { ComponentProps } from 'react';
import { Typography, Link } from '@vp/swan';

import { useTranslations } from 'client/hooks/useTranslations';

interface PropTypes extends ComponentProps<typeof Link> {
    showMore: boolean;
}

export const ShowMoreButton = (props: PropTypes): JSX.Element => {
    const {
        showMore,
        title,
        ...rest
    } = props;
    const localize = useTranslations();
    const showMoreOrLessText = showMore ? localize('ShowLess') : localize('ShowMore');

    return (
        <Link
            className="secondary show-more"
            component="button"
            {...rest}
            aria-label={`${showMoreOrLessText} ${title}`}
        >
            <Typography component="span" fontSize="small" fontWeight="bold">
                { showMoreOrLessText }
            </Typography>
        </Link>
    );
};

ShowMoreButton.displayName = 'ShowMoreButton';
