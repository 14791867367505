import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { queryFavorites, QUERY_KEY } from 'client/queries/queryFavorites';

type Favorites = VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo>[];

type QueryKey = VP.DTT.Queries.WorkEntityService.ByProductKeyQueryKey;

export const useQueryFavorites = (
    locale: i18n.Locale,
    wesTenant: string,
    ownerId?: string,
    accessToken?: string | null,
    productKey?: string,
    options?: UseQueryOptions<Favorites, Error, Favorites, QueryKey>,
): UseQueryResult<Favorites, Error> => useQuery<Favorites, Error, Favorites, QueryKey>(
    [QUERY_KEY, {
        locale, wesTenant, ownerId, accessToken, productKey,
    }],
    queryFavorites,
    { ...options },
);
