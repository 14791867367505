import { QUICK_VIEW_PRODUCT_OPTIONS_FEATURES } from '~/experiments/QuickViewProductOptions/constatants';
import { isExperimentActive } from 'shared/ab-testing';
import { FILTERS_REORDER_FEATURE_FLAG } from '~/experiments/FilterOrder/constatants';

export function getQuickViewProductOptionsFeatureFlags(
    rawExperimenationData?: string,
): string[] {
    const result = [];

    const quickViewFeatureFlag = QUICK_VIEW_PRODUCT_OPTIONS_FEATURES.find(
        (ex) => isExperimentActive(ex, rawExperimenationData),
    );

    if (quickViewFeatureFlag) {
        result.push(quickViewFeatureFlag);
    }

    return result;
}

export function getFilterReorderingFeatureFlags(
    rawExperimenationData?: string,
): string[] {
    const result = [];

    if (isExperimentActive(FILTERS_REORDER_FEATURE_FLAG, rawExperimenationData)) {
        result.push(FILTERS_REORDER_FEATURE_FLAG);
    }

    return result;
}

export function getContentAPIFeatureFlags(rawExperimenationData?: string): string[] {
    return [
        ...getQuickViewProductOptionsFeatureFlags(rawExperimenationData),
        ...getFilterReorderingFeatureFlags(rawExperimenationData),
    ];
}

export function getContentQueryConfigExperimentFlags(rawExperimenationData?: string): string[] {
    return getQuickViewProductOptionsFeatureFlags(rawExperimenationData);
}
