import { useState } from 'react';
import {
    Button,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
} from '@vp/swan';

import { useTranslations } from '~/client/hooks/useTranslations';
import { SURVEY_LINK } from '~/client/components/Gallery/Header/SurveyLink/constants';

export const SurveyLink = (): JSX.Element | null => {
    const localize = useTranslations();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (): void => {
        setIsOpen(true);
    };

    const handleClose = (): void => {
        setIsOpen(false);
    };

    return (
        <>
            <span>
                Say &apos;I do&apos; to other wedding offers, ideas & more.&nbsp;
                <Button className="swan-link" skin="link" onClick={handleOpen}>Sign up</Button>
            </span>

            <ModalDialog onlyRenderWhenOpen isOpen={isOpen} onRequestDismiss={handleClose}>
                <ModalDialogContent className="survey-link-modal-dialog-content">
                    <ModalDialogCloseButton
                        visuallyHiddenLabel={localize(
                            'FilterMenuCloseButtonText',
                        )}
                    />
                    <ModalDialogBody className="survey-link-modal-dialog-body">
                        <iframe
                            className="survey-link-iframe-container"
                            src={SURVEY_LINK}
                            title="Survey"
                        />
                    </ModalDialogBody>
                </ModalDialogContent>
            </ModalDialog>
        </>
    );
};

SurveyLink.displayName = 'SurveyLink';
