import config from 'config';
import { QueryFunctionContext } from '@tanstack/react-query';
import { DesignVariationsService } from 'services/DesignVariationsService';
import { getLogger } from 'client/utils/gallery/logger';

const designVariationsService = new DesignVariationsService(config.services.designVariationsService, getLogger);

export const queryDesignVariations = async (
    { queryKey }: QueryFunctionContext<DesignVariations.QueryKey>,
): Promise<DesignVariations.DesignVariationResponse[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, input] = queryKey;

    const offset = 0;
    // just get all at once
    const limit = 50000;
    const query = {
        ...input,
        offset,
        limit,
    };
    // eslint-disable-next-line
    const response = await designVariationsService.getDesignVariations(query);

    return response.results.content;
};
