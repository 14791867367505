import { AnyAction } from 'redux';

const initialState: State.FilterByOptionState = {};

export function reducer(
    state: State.FilterByOptionState = initialState,
    action: AnyAction,
): State.FilterByOptionState {
    switch (action.type) {
        default:
            return state;
    }
}

export const filterByOptionSelector = (state: State.GlobalState) => (id: string): string => state.filterByOption[id];
