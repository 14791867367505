import { useState } from 'react';
import {
    List,
    ListItem,
    Button,
} from '@vp/swan';
import { useDispatch, useSelector } from 'react-redux';
import { ShowMoreButton } from 'client/components/Gallery/Filters/Shared/ShowMoreButton';
import { FilterOption } from 'client/components/Gallery/Filters/FilterOption';
import { buildRefinement, refinementAdd } from 'client/store/refinement';
import { ANALYTICS_LABEL, REFINEMENT_DIMENSION } from 'shared/constants';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { getRawExperiments } from '~/client/store/experimentation';
import {
    PERSONALIZATION_UX_VARIATIONS, PERSONALIZATION_UX_EXPERIMENT_NAME,
    PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME, PERSONALIZATION_UX_PHASE_2_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
    PERSONALIZATION_UX_MOBILE_VARIATIONS,
    PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
} from '~/experiments/tilePersonalization/constants';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

export interface L1FilterProps {
    filter: State.Filter;
    options: RenderableFilterOption[];
    refinementDimension: REFINEMENT_DIMENSION;
}

export const L1Filter = (props: L1FilterProps): JSX.Element => {
    const { options, filter, refinementDimension } = props;
    const dispatch = useDispatch();
    const [showMore, setShowMore] = useState(false);
    const bestSellingCount = (refinementDimension === REFINEMENT_DIMENSION.CATEGORY)
        ? (filter as State.CategoryFilter).bestSellingCount || Number.MAX_SAFE_INTEGER
        : Number.MAX_SAFE_INTEGER;
    const availableOptions = options.filter((o) => showMore || !o.rank || o.rank < bestSellingCount);

    const isExperimentActive = useExperimentation();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const redirectToL0 = useRedirectToL0();

    const curryHandleL1Selection = (id: string) => (
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.preventDefault();

            if (!isSmallScreen()) {
                if (isExperimentActive(PERSONALIZATION_UX_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_VARIATIONS.Enabled,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (isExperimentActive(PERSONALIZATION_UX_VARIATIONS.Control)) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_VARIATIONS.Control,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (
                    isExperimentActive(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)
                ) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (isExperimentActive(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Control)) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_PHASE_2_VARIATIONS.Control,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (
                    isExperimentActive(
                        PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled,
                        TRACKING_BEHAVIOR.Suppress,
                    )
                ) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (
                    isExperimentActive(
                        PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Control,
                        TRACKING_BEHAVIOR.Suppress,
                    )
                ) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Control,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (
                    isExperimentActive(
                        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled,
                        TRACKING_BEHAVIOR.Suppress,
                    )
                ) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (
                    isExperimentActive(
                        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Control,
                        TRACKING_BEHAVIOR.Suppress,
                    )
                ) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Control,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
                        filter.name,
                    );
                }
            }
            if (isSmallScreen()) {
                if (
                    isExperimentActive(
                        PERSONALIZATION_UX_MOBILE_VARIATIONS.Control,
                        TRACKING_BEHAVIOR.Suppress,
                    )
                ) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_MOBILE_VARIATIONS.Control,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
                        filter.name,
                    );
                } else if (isExperimentActive(
                    PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
                    TRACKING_BEHAVIOR.Suppress,
                )) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
                        rawExperiments,
                        analytics,
                        ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                        'Filter Update',
                        PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
                        filter.name,
                    );
                }
            }

            const refinement = buildRefinement(id, refinementDimension);

            if (redirectToL0([refinement], [])) {
                return;
            }

            dispatch(refinementAdd([refinement]));
            if (isFilterModalHidden()) {
                scrollUpToElement(galleryHeaderId);
            }
        }
    );

    return (
        <>
            <List aria-label={filter.title} role="radiogroup" skin="minimal">
                {availableOptions.map((o) => (
                    <ListItem key={o.value}>
                        <Button
                            disabled={o.disabled}
                            skin="unstyled"
                            onClick={curryHandleL1Selection(o.value)}
                        >
                            <FilterOption facetCount={o.facetCount} title={o.title} />
                        </Button>
                    </ListItem>
                ))}
            </List>

            {options.length > bestSellingCount && (
                <ShowMoreButton showMore={showMore} title={filter.title} onClick={(): void => setShowMore(!showMore)} />
            )}
        </>
    );
};

L1Filter.displayName = 'L1Filter';
