import { useContext, useMemo } from 'react';
import { useAsyncEffect } from '@design-stack-ct/utility-react';
import { useUploadManager } from '@design-stack-vista/upload-components';
import { convertVistaAssetToPhotoPreviewData } from 'client/components/Gallery/Header/Personalization/utils';
import { PHOTO_UPLOAD_STATES } from 'src/client/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { selectedPhotosState } from 'src/client/atoms/selectedPhotosAtom';
import { photoUploadState } from 'src/client/atoms/photoUploadStateAtom';
import { shouldRestorePhotosState } from 'src/client/atoms/shouldRestorePhotosAtom';
import { isNotFalse } from '~/shared/heplers';
import { customImageDataUpdate, logoAppliedUpdate, personalizedTextUpdate } from 'client/store/personalization/actions';
import { designPersonalizationContextSelector } from '~/client/store/personalization/selectors';
import { contentUpdate } from '~/client/store/content';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { useExpectImagesToBePhotos } from '../../../Filters/PersonalizationFilter/useSpecifyImageCopy';
import { usePersistedDpcKey } from './usePersistedDpcKey';
import { usePurposeNames } from './usePurposeNames';
import { dpcToTextFields } from '../../../Filters/PersonalizationFilter/dpcConverters';

function getAssetIdsFromDpc(dpc: Gallery.Models.Personalization.DesignPersonalizationContext): string[] {
    return dpc?.images?.map((i) => i.image.assetId) ?? [];
}

export const useRestoreDpc = (): void => {
    const setUploadState = useSetRecoilState(photoUploadState);
    const setSelectedPhotos = useSetRecoilState(selectedPhotosState);
    const [shouldRestorePhotos, setShouldRestorePhotos] = useRecoilState(shouldRestorePhotosState);
    const purposeNames = usePurposeNames();
    const {
        setTextfieldValues,
        setMobileTextfieldValues,
    } = useContext(PersonalizationReactContext);
    const defaultToPhoto = useExpectImagesToBePhotos();
    const analytics = useAnalytics();
    // Used for removing dpc data
    const localStorageKey = usePersistedDpcKey();

    const { assetStore } = useUploadManager();

    const dispatch = useDispatch();
    const dpc = useSelector(designPersonalizationContextSelector);

    const fetchAndPresignAsset = useMemo(() => async (
        id: string,
    ): Promise<Gallery.Models.Personalization.PhotoPreviewData | undefined> => {
        if (!assetStore) {
            return undefined;
        }

        const asset = await assetStore.fetchSingleAsset({ id });

        await asset.presign();

        return convertVistaAssetToPhotoPreviewData(asset, 30000, defaultToPhoto);
    }, [assetStore, defaultToPhoto]);

    useAsyncEffect(({ runIfMounted }) => {
        const restoreDpc = async (): Promise<void> => {
            try {
                setUploadState({ status: PHOTO_UPLOAD_STATES.LOADING });

                const storedAssetIds = getAssetIdsFromDpc(dpc);

                if (storedAssetIds.length > 0) {
                    const photoPreviewData = (await Promise.all(storedAssetIds.map(fetchAndPresignAsset)))
                        .filter(isNotFalse);

                    analytics.trackEvent({
                        action: ANALYTICS_EVENT_ACTIONS.PRODUCT_VIEWED,
                        eventLabel: 'Images repopulated from DPC',
                        eventDetail: 'Images repopulated from DPC',
                        ...analytics.getPageProperties(),
                    });

                    dispatch(customImageDataUpdate(photoPreviewData));
                    dispatch(logoAppliedUpdate(true));
                    dispatch(contentUpdate());
                    setSelectedPhotos(photoPreviewData);
                }

                const dpcTextFields = dpcToTextFields(purposeNames, dpc);

                if (Object.keys(dpcTextFields).length > 0) {
                    setTextfieldValues?.(dpcTextFields);
                    analytics.trackEvent({
                        action: ANALYTICS_EVENT_ACTIONS.PRODUCT_VIEWED,
                        eventLabel: 'Text fields repopulated from DPC',
                        eventDetail: 'Text fields repopulated from DPC',
                        ...analytics.getPageProperties(),
                    });
                }
            // if there was an error retrieving the uploads from localStorage, don't give the user access to dpc data
            } catch {
                dispatch(customImageDataUpdate([]));
                dispatch(personalizedTextUpdate({}));
                setSelectedPhotos([]);
                setTextfieldValues?.({});
                setMobileTextfieldValues?.({});
                localStorage.removeItem(localStorageKey);
            } finally {
                setUploadState({ status: PHOTO_UPLOAD_STATES.READY });
                setShouldRestorePhotos(false);
            }
        };

        if (shouldRestorePhotos) {
            runIfMounted(restoreDpc);
        }
    }, [
        setUploadState,
        fetchAndPresignAsset,
        shouldRestorePhotos,
        setSelectedPhotos,
        setShouldRestorePhotos,
    ]);
};
