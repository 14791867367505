/* eslint-disable no-underscore-dangle */
import {
    BrowserRouter, Routes, unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import { history as galleryHistory } from 'client/utils/history';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { useSelector } from 'react-redux';
import { galleryImpressionsTrackingSelector } from '~/client/store/analytics';
import { AnalyticsNames } from '~/shared/constants';
import { useOnLoadImpression } from '~/client/hooks/useOnLoadImpression';
import { App } from './App';

export const Router = (): JSX.Element => {
    const analytics = useAnalytics();
    const galleryImpressionsTracking = useSelector(
        (state: State.GlobalState) => galleryImpressionsTrackingSelector(state, analytics, AnalyticsNames.DesignTile),
    );

    const messages = (window as any).GALLERY.__I18N__;
    const sitewidePromobarConfig = (window as any).GALLERY.__SITEWIDE_PROMOBAR_CONFIG__;

    useOnLoadImpression(() => {
        analytics.trackImpression(galleryImpressionsTracking);
    });

    const routes = (
        <Routes>
            {App({
                messages, sitewidePromobarConfig,
            })}
        </Routes>
    );

    return (
        <>
            {galleryHistory && <HistoryRouter history={galleryHistory as any}>{routes}</HistoryRouter>}
            {!galleryHistory && <BrowserRouter>{routes}</BrowserRouter>}
        </>
    );
};
