import config from 'config';
import { getLogger } from 'client/utils/gallery/logger';
import { QueryFunctionContext } from '@tanstack/react-query';
import { GlobalChannelProviderServiceApi } from 'services/ChannelProviderService';

export const QUERY_KEY = 'channelProviderPhoneData';

const globalChannelProviderService = new GlobalChannelProviderServiceApi(
    config.services.channelProviderService,
    getLogger,
);

export const queryChannelProviderPhoneData = (
    context: QueryFunctionContext<VP.Care.Queries.ChannelProviderService.ByPhoneDataQueryKey>,
): Promise<VP.Care.Models.ChannelProviderService.PhoneData> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        locale,
        tenant,
    }] = queryKey;

    return globalChannelProviderService.getPhoneData(locale, tenant);
};
