import { AnyAction } from 'redux';

const initialState: State.FilterByCategoryState = {};

export function reducer(
    state: State.FilterByCategoryState = initialState,
    action: AnyAction,
): State.FilterByCategoryState {
    switch (action.type) {
        default:
            return state;
    }
}

export const filterByCategorySelector = (state: State.GlobalState) => (id: string): string => state.filterByCategory[id];
