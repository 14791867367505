import {
    Box, Icon, Typography,
} from '@vp/swan';
import classnames from 'classnames';
import { TemplateTileFallbackPreviewProps } from '~/client/components/common/TemplateTile/components/TemplateTileFallbackPreview/interface';
import { TILE_PREVIEW_SIZE } from '~/client/constants';
import { useTranslations } from '~/client/hooks/useTranslations';

export const TemplateTileFallbackPreview = ({ size = TILE_PREVIEW_SIZE.DEFAULT }: TemplateTileFallbackPreviewProps): JSX.Element => {
    const localize = useTranslations();

    return (
        <Box className={classnames('template-tile-fallback-preview', { 'fallback-preview-large': size === TILE_PREVIEW_SIZE.LARGE })}>
            <Icon className="fallback-icon" iconType="image" size="60p" />
            <Typography
                component="span"
                fontSize="standard"
                fontWeight="bold"
                py="3"
            >
                {localize('NoPreviewAvailable')}
            </Typography>
        </Box>
    );
};
