import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { queryRatingsAndReviews, QUERY_KEY } from 'client/queries/queryRatingsAndReviews';

type RatingsSummary = VP.MTT.Models.RatingsAndReviews.RatingsReviewsResponse;

type QueryKey = VP.MTT.Queries.RatingsAndReviews.RatingsAndReviewsQueryKey;

export const useQueryRatingsAndReviews = (
    tenant: string,
    locale: i18n.Locale,
    mpvId: string,
    tlpLevel: Gallery.Models.Url.ValidParsedQsValue<string>,
    options?: UseQueryOptions<RatingsSummary, Error, RatingsSummary, QueryKey>,
): UseQueryResult<RatingsSummary, Error> => useQuery<RatingsSummary, Error, RatingsSummary, QueryKey>(
    [QUERY_KEY, {
        tenant, locale, mpvId, tlpLevel,
    }],
    queryRatingsAndReviews,
    { ...options },
);
