import config from 'config';
import { AnyAction } from 'redux';
import { getLogger } from 'client/utils/gallery/logger';
import { ContentService, ContentQuery } from 'services/ContentService';
import {
    buildContentState,
    buildFacetState,
} from 'client/store/buildContentState';
import { loadMoreCountSelector, currentPageInfoSelector } from 'client/store/paging/selectors';
import {
    refinementIdsByFilterSelector,
} from 'client/store/refinement/selectors';
import { categoryByIdSelector } from 'client/store/filterCategories';
import { templateUseCaseByIdSelector } from 'client/store/filterTemplateUseCases';
import { updateUrl } from 'client/store/locationState/utils';
import {
    getGalleryNameSelector,
    getLocaleSelector,
    getMpvid,
    getQuantitySelector,
    selectedOptionsSelector,
} from 'client/store/config';
import {
    getBypassApproval,
    getNoCache,
    getUseConstraints,
    getRankingStrategyOverride,
    getForcedRankingStrategyOverride,
    getQuickViewId,
    getTemplatePurposes,
} from 'client/store/debug';
import { CONTENT_REVERT, CONTENT_UPDATE } from 'client/store/constants';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { isExperimentActive } from '~/shared/ab-testing';
import { getRawExperiments } from 'src/client/store/experimentation';
import { v4 } from 'uuid';
import {
    getImagePlaceholderAspectRatio,
    getImagePlaceholderAspectRatioTolerance,
    getPlaceholderPurposes,
    getRenderPropsOverrides,
    getSearchBackend,
    getDesignCreationTypes,
    getUseRealisationEngineService, getASPExperimentFlags,
    getDebugModeEnabled,
} from 'src/client/store/debug/reducer';
import { booleanRenderPropertySelector, getDesignId, getIsL1orL2 } from 'client/store/config/reducer';
import { RenderProperty } from 'shared/renderProperties';
import { isNotFalse } from '~/shared/heplers';
import { COLOR_PALETTE_VARIATIONS } from '~/experiments/ColorPalette/constants';
import { assembleASPExperimentFlags } from '~/experiments/ASPSearch/assembleASPExperimentFlags';
import { getMetadataQueryParams } from 'client/store/content/utils';
import { INITIAL_STATE as DEFAULT_PAGE_STATE } from 'client/store/paging/constants';
import { getContentQueryConfigExperimentFlags } from '~/experiments/utils';
import { MERCH_MODULES_COLLECTION, MERCH_MODULES_TILE_SIZE } from '~/experiments/MerchModules/constants';
import {
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS,
} from '~/experiments/tilePersonalization/constants';
import { ASPGlobalRankingExperiment } from '~/experiments/ASPSearch/ASPGlobalRankingExperiment';
import { MAX_TEMPLATE_USE_CASE_DEPTH_LEVEL } from '~/client/constants';
import { isMerchModulesEnabled } from '~/experiments/MerchModules/utils';
import { designPersonalizationContextSelector } from 'client/store/personalization/selectors';
import { parentByChildTemplateUseCaseSelector } from '../filterTemplateUseCases/reducer';

type ContentCategories = {
    categories: string[][];
    facetCategories: string[];
};
type ContentTemplateUseCases = {
    templateUseCases: string[][];
    facetTemplateUseCases: string[];
};

type GetContentCategoriesFn = (selectedCategories: Util.StringDictionary<string[]>) => ContentCategories;
type GetContentTemplateUseCasesFn = (selectedTemplateUseCases:
Util.StringDictionary<string[]>) => ContentTemplateUseCases;

/**
 * Gets categories to filter and facet on
 *
 * Prune L1s out of request when filtering on L2s to get the correct results back
 *   ex: (L1,L2) in the QS would behave like (L1 OR L2) and return all L1 content plus L2, which isn't correct
 * @param state
 */
function getContentCategories(state: State.GlobalState): GetContentCategoriesFn {
    const getCategory = categoryByIdSelector(state);

    return (selectedCategories: Util.StringDictionary<string[]>): ContentCategories => {
        const parentIds = {} as Util.StringDictionary<string>;

        const roots = Object.values(state.filters.byId)
            .filter((f) => f.dimension === REFINEMENT_DIMENSION.CATEGORY)
            .map((f) => (f as State.CategoryFilter).categoryId.toString());

        // iterate over the categories for that filter,
        //   finding the max depth and then filtering on that depth
        // exclude L2s from facetCategories since we dont show L3s
        const categories = Object.values(selectedCategories).map((catIds) => {
            let maxDepth = 0;

            catIds.forEach((catId) => {
                const category = getCategory(catId);

                if (!category) {
                    throw new Error(`Invalid category. Category ${catId} does not exist`);
                }

                const { level } = category;

                if (level > maxDepth) {
                    maxDepth = level;
                }

                // L3s will never be shown, so no facets needed
                if (level < 2) {
                    parentIds[catId] = catId;
                }
            });

            return catIds.filter((catId) => getCategory(catId).level === maxDepth);
        });

        return {
            categories,
            facetCategories: roots.concat(Object.keys(parentIds)),
        };
    };
}

/**
 * Gets template use cases to filter and facet on
 *
 * Prune L1s out of request when filtering on L2s to get the correct results back
 *   ex: (L1,L2) in the QS would behave like (L1 OR L2) and return all L1 content plus L2, which isn't correct
 * @param state
 */
function getContentTemplateUseCases(state: State.GlobalState): GetContentTemplateUseCasesFn {
    const getTemplateUseCase = templateUseCaseByIdSelector(state);

    return (selectedTemplateUseCases: Util.StringDictionary<string[]>): ContentTemplateUseCases => {
        const parentIds = {} as Util.StringDictionary<string>;

        const roots = Object.values(state.filters.byId)
            .filter((f) => f.dimension === REFINEMENT_DIMENSION.TEMPLATE_USE_CASE)
            .map((f) => (f as State.TemplateUseCaseFilter).templateUseCaseId.toString());

        // iterate over the categories for that filter,
        //   finding the max depth and then filtering on that depth
        // exclude L2s from facetCategories since we dont show L3s
        const templateUseCases = Object.values(selectedTemplateUseCases).map((tucIds) => {
            let maxDepth = 0;

            tucIds.forEach((tucId) => {
                const templateUseCase = getTemplateUseCase(tucId);

                if (!templateUseCase) {
                    throw new Error(`Invalid template use case. Template Use Case ${tucId} does not exist`);
                }

                const { level } = templateUseCase;

                if (level > maxDepth) {
                    maxDepth = level;
                }

                // L3s will never be shown, so no facets needed
                if (level < MAX_TEMPLATE_USE_CASE_DEPTH_LEVEL) {
                    parentIds[tucId] = tucId;
                }
            });

            return tucIds.filter((tucId) => getTemplateUseCase(tucId).level === maxDepth);
        });

        return {
            templateUseCases,
            facetTemplateUseCases: roots.concat(Object.keys(parentIds)),
        };
    };
}

interface ContentUpdateParams {
    newContent: Gallery.ContentQuery.Response,
    merchModules: Gallery.ContentQuery.Response | null,
    locale: i18n.Locale,
    query: ContentQuery,
    quantity: Gallery.Models.Url.ValidParsedQsValue<number>,
    qvContent?: Gallery.ContentQuery.TileEntityResponse,
    oldState?: State.GlobalState,
    getParentByChildTemplateUseCase: (templateUseCaseId: string) => string,
    selectedTemplateUseCases: string[],
}

/**
 * Action generator for the content api response
 *
 * @param newContent content api response
 * @param merchModules
 * @param locale locale code
 * @param query the original query used to request content, added to the action for information only
 * @param quantity
 * @param qvContent
 * @param oldState
 */
function generateContentUpdate({
    newContent,
    merchModules,
    locale,
    query,
    quantity,
    qvContent,
    oldState,
    getParentByChildTemplateUseCase,
    selectedTemplateUseCases,
}: ContentUpdateParams): AnyAction {
    const mergedContent = (oldState?.config.rawContent ?? []).concat(newContent.results.content);
    const merchModulesContent = oldState?.config?.lastRawMerchModulesContent ?? merchModules?.results?.content ?? null;

    const loadMoreCount = oldState ? oldState.paging.loadMoreCount + 1 : DEFAULT_PAGE_STATE.loadMoreCount;

    return {
        type: CONTENT_UPDATE,
        payload: {
            ...buildFacetState(newContent.results.facets),
            ...buildContentState(
                mergedContent,
                merchModulesContent,
                locale,
                quantity,
                getParentByChildTemplateUseCase,
                qvContent,
                selectedTemplateUseCases,
            ),
            paging: {
                totalEntities: newContent.total,
                loadMoreCount,
                hasMoreTemplates: newContent.length + newContent.offset < newContent.total,
            },
            requestUrl: newContent.requestUrl,
            config: {
                viewId: v4(),
                rawContent: mergedContent,
                lastRawContent: newContent.results.content,
                lastRawMerchModulesContent: merchModulesContent,
            },
        },
        meta: {
            query,
        },
    };
}

function generateContentRevert(originalState: State.GlobalState, query: ContentQuery): AnyAction {
    return {
        type: CONTENT_REVERT,
        payload: {
            paging: originalState.paging,
            refinements: originalState.refinements,
        },
        meta: {
            query,
        },
    };
}

interface ContentUpdateOptions {
    actions?: AnyAction | AnyAction[]
    shouldUpdateUrl?: boolean;
    loadMore?: boolean;
    callback?: () => void;
}

/**
 * Action creator similar to contentUpdate.
 * If the imageplacholder attribute is present, this will return all templates with those containing
 * image placeholders sorted to the top instead of a filtered list of image placholder templates.
 *
 * If the Personalization UX test wins, this should be replaced in favor of adding this logic into Content Query.
 */
export const personalizationExperimentContentUpdate = ({
    actions,
    shouldUpdateUrl = true,
    loadMore = false,
    callback = undefined,
}: ContentUpdateOptions = {}): Gallery.ContentQuery.Action => async (
    dispatch,
    getState,
): Promise<AnyAction> => {
    const originalState = getState();

    if (actions) {
        [actions].flat().forEach((action) => dispatch(action));
    }

    const state = getState();
    const refinements = refinementIdsByFilterSelector(state);
    const getParentByChildTemplateUseCase = parentByChildTemplateUseCaseSelector(state);
    const { categories, facetCategories } = getContentCategories(state)(refinements.categories);
    const { templateUseCases, facetTemplateUseCases } = getContentTemplateUseCases(state)(refinements.templateUseCases);
    const quantity = getQuantitySelector(state);
    const rawExperiments = getRawExperiments(state);
    const { keywords: metadataKeywords, attributes: metadataAttributes } = getMetadataQueryParams(state);
    const isL1orL2 = getIsL1orL2(state);
    const designId = getDesignId(state);
    const loadMoreCount = loadMoreCountSelector(state);

    const imagePlaceholderAspectRatio = getImagePlaceholderAspectRatio(state);
    const imagePlaceholderAspectRatioTolerance = getImagePlaceholderAspectRatioTolerance(state);

    const searchBackend = getSearchBackend(state);
    const debugEnabled = getDebugModeEnabled(state).enabled;
    const placeholderPurposes = getPlaceholderPurposes(state);
    const designCreationTypes = getDesignCreationTypes(state);
    const designPersonalizationContext = designPersonalizationContextSelector(state);

    const useRealisationEngineService = !!getUseRealisationEngineService(state);

    const useNewColorPalette = isExperimentActive(COLOR_PALETTE_VARIATIONS.Enabled, rawExperiments);

    const experimentFlags = assembleASPExperimentFlags(
        [ASPGlobalRankingExperiment],
        rawExperiments,
        getASPExperimentFlags(state),
    );
    const configExperimentFlags = getContentQueryConfigExperimentFlags(rawExperiments);

    const {
        pageSize,
        offset,
    } = currentPageInfoSelector(state, loadMore ? loadMoreCount + 1 : DEFAULT_PAGE_STATE.loadMoreCount);

    const attributes = isL1orL2 ? metadataAttributes
        : [...metadataAttributes, ...Object.values(refinements.attributes)];

    const query: ContentQuery = {
        categories,
        facetCategories,
        templateUseCases,
        facetTemplateUseCases,
        attributes,
        useRealisationEngineService,
        designPersonalizationContext,
        bypassApproval: getBypassApproval(state),
        locale: getLocaleSelector(state),
        galleryName: getGalleryNameSelector(state),
        keyword: [...metadataKeywords, refinements.keyword].filter(isNotFalse),
        collection: refinements.collection,
        length: pageSize,
        mpvId: getMpvid(state),
        offset,
        selectedOptions: selectedOptionsSelector(state),
        noCache: getNoCache(state),
        useConstraints: getUseConstraints(state),
        rankingStrategyOverride: getRankingStrategyOverride(state),
        forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
        renderProps: getRenderPropsOverrides(state),
        templatePurposes: getTemplatePurposes(state),
        imagePlaceholderAspectRatio,
        imagePlaceholderAspectRatioTolerance,
        placeholderPurposes,
        searchBackend,
        useNewColorPalette,
        experimentFlags,
        configExperimentFlags,
        designCreationTypes,
        debug: debugEnabled,
        includeCategoryAndKeywordData: true,
        filterAltTextCompatibleTagging: true,
    };

    try {
        const contentServiceConfig = config.services.contentQueryService;
        const contentService = new ContentService(contentServiceConfig, getLogger);

        // make the call to get templates without filtering on image placeholders
        const newContent = await contentService.getContent(query);

        if (originalState.personalization.logoApplied) {
            /**
                 * Function to get templates with image placeholders only.
                 *
                 * The function will recursively call itself if there are image placeholder templates in the full
                 * set of templates that don't exist within the set of image placeholder-only templates.
                 *
                 * @param unfilteredContent the full set of templates for the page the user is on
                 * @param imagePlaceholderOffset offset to use when retrieving additional image placeholder templates
                 */
            const getImagePlaceholderContent = async (
                unfilteredContent: Gallery.ContentQuery.TileEntityResponse[],
                imagePlaceholderOffset = 0,
            ): Promise<Gallery.ContentQuery.TileEntityResponse[]> => {
                const imagePlaceholderLength = 96;

                // make a request to content query for 96 image placholder templates
                const { results: { content: imagePlaceholderContent }, total } = await contentService.getContent({
                    ...query,
                    attributes: attributes.concat([['imageplaceholder_true']]),
                    length: imagePlaceholderLength,
                    offset: imagePlaceholderOffset,
                });

                // check if the the image placeholder templates have any overlap with the full set of templates
                const overlapExists = unfilteredContent.filter((content) => imagePlaceholderContent.findIndex(
                    (imagePlaceholderDesign) => imagePlaceholderDesign.designId === content.designId,
                ) >= 0).length > 0;

                // recursively retrieve more image placeholder templates if:
                // 1. there are still templates to retrieve (checked by seeing if the offset is greater than the total)
                // 2. there is no overlap of templates between the image placeholder-only and full set
                // 3. the last image placholder-only template exists within the full set
                if (
                    imagePlaceholderOffset < total
                        && (!overlapExists
                            || unfilteredContent.findIndex(
                                (design) => design.designId
                                    === imagePlaceholderContent[imagePlaceholderContent.length - 1].designId,
                            ) >= 0)
                ) {
                    return [
                        ...imagePlaceholderContent,
                        ...(await getImagePlaceholderContent(
                            unfilteredContent,
                            imagePlaceholderOffset + imagePlaceholderLength,
                        )),
                    ];
                }

                return imagePlaceholderContent;
            };

            const imagePlaceholderContent = await getImagePlaceholderContent(newContent.results.content, 0);
            const imagePlaceholderDesigns: Gallery.ContentQuery.TileEntityResponse[] = [];

            // filter the full set of templates to only those that do NOT have an image placeholder
            // and push all the image placeholder templates to a different array
            const newContentNoImagePlaceholder = newContent.results.content.filter((design) => {
                if (imagePlaceholderContent.findIndex(
                    (imagePlaceholderDesign) => imagePlaceholderDesign.designId === design.designId,
                ) >= 0) {
                    imagePlaceholderDesigns.push(design);
                    return false;
                }

                return true;
            });

            // prepend the image placeholder templates to the set of non-image placeholder templates, effectively
            // sorting the image placeholder templates to the top of the page
            newContent.results.content = [...imagePlaceholderDesigns, ...newContentNoImagePlaceholder];

            callback?.();
        }

        const designIdQSP = getQuickViewId(state);

        const quickViewDesignID = designIdQSP?.toString() ?? designId;

        const useMerchModules = isMerchModulesEnabled(rawExperiments);
        const shouldFetchMerchModules = useMerchModules
                && refinements.collection !== MERCH_MODULES_COLLECTION
                && offset === 0;

        const merchModules = shouldFetchMerchModules ? await contentService.getContent({
            ...query,
            collection: MERCH_MODULES_COLLECTION,
            length: MERCH_MODULES_TILE_SIZE,
            offset: 0,
        }) : null;

        let qvContent;

        if (quickViewDesignID) {
            const qvQuery: ContentQuery = {
                bypassApproval: getBypassApproval(state),
                locale: getLocaleSelector(state),
                galleryName: getGalleryNameSelector(state),
                mpvId: getMpvid(state),
                noCache: getNoCache(state),
                useConstraints: getUseConstraints(state),
                rankingStrategyOverride: getRankingStrategyOverride(state),
                forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
                designIds: [quickViewDesignID.toString()],
                length: 1,
                selectedOptions: selectedOptionsSelector(state),
                attributes: Object.values(refinements.attributes),
                renderProps: getRenderPropsOverrides(state),
                searchBackend,
                useNewColorPalette,
                experimentFlags,
                configExperimentFlags,
                debug: debugEnabled,
            };

            [qvContent] = (await contentService.getContent(qvQuery)).results.content;

            if (!qvContent) {
                const message = `Failed to open quickView for QSP: ${quickViewDesignID}`;

                getLogger().error({
                    message,
                    query,
                });
            }
        }

        let contentUpdateAction: AnyAction = generateContentUpdate({
            newContent,
            locale: state.config.locale,
            query,
            quantity,
            qvContent,
            oldState: loadMore ? state : undefined,
            merchModules,
            getParentByChildTemplateUseCase,
            selectedTemplateUseCases: templateUseCases.flat(),
        });

        if (shouldUpdateUrl) {
            contentUpdateAction = updateUrl(contentUpdateAction);
        }

        return dispatch(contentUpdateAction);
    } catch (e) {
        // revert on the client, otherwise throw
        if (process.env.IS_CLIENT) {
            getLogger().error(e as Error);
            return dispatch(generateContentRevert(originalState, query));
        }
        throw e;
    }
};

/**
 * Action Creator to update the content state.
 * Given a query to perform against the Content Service, retrieve the new
 * content and return the CONTENT_UPDATE action.
 */
export const contentUpdate = ({
    actions,
    shouldUpdateUrl = true,
    loadMore = false,
    callback = undefined,
}: ContentUpdateOptions = {}): Gallery.ContentQuery.Action => async (
    dispatch,
    getState,
): Promise<AnyAction> => {
    const originalState = getState();
    const showPersonalizationUI = booleanRenderPropertySelector(originalState)(RenderProperty.ShowPersonalizationUI);

    if (isExperimentActive(
        PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled,
        originalState.experimentation?.rawExperiments,
    )
        || isExperimentActive(
            PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled,
            originalState.experimentation?.rawExperiments,
        )
        || showPersonalizationUI
    ) {
        return personalizationExperimentContentUpdate({
            actions, shouldUpdateUrl, loadMore, callback,
        })(
            dispatch,
            getState,
            undefined,
        );
    }

    if (actions) {
        [actions].flat().forEach((action) => dispatch(action));
    }

    const state = getState();
    const refinements = refinementIdsByFilterSelector(state);
    const getParentByChildTemplateUseCase = parentByChildTemplateUseCaseSelector(state);
    const { categories, facetCategories } = getContentCategories(state)(refinements.categories);
    const { templateUseCases, facetTemplateUseCases } = getContentTemplateUseCases(state)(refinements.templateUseCases);
    const quantity = getQuantitySelector(state);
    const rawExperiments = getRawExperiments(state);
    const { keywords: metadataKeywords, attributes: metadataAttributes } = getMetadataQueryParams(state);
    const isL1orL2 = getIsL1orL2(state);
    const designId = getDesignId(state);
    const loadMoreCount = loadMoreCountSelector(state);

    const imagePlaceholderAspectRatio = getImagePlaceholderAspectRatio(state);
    const imagePlaceholderAspectRatioTolerance = getImagePlaceholderAspectRatioTolerance(state);

    const searchBackend = getSearchBackend(state);
    const debugEnabled = getDebugModeEnabled(state).enabled;
    const placeholderPurposes = getPlaceholderPurposes(state);
    const designCreationTypes = getDesignCreationTypes(state);
    const designPersonalizationContext = designPersonalizationContextSelector(state);
    const useRealisationEngineService = !!getUseRealisationEngineService(state);

    const useNewColorPalette = isExperimentActive(COLOR_PALETTE_VARIATIONS.Enabled, rawExperiments);
    const useMerchModules = isMerchModulesEnabled(rawExperiments);

    const experimentFlags = assembleASPExperimentFlags(
        [ASPGlobalRankingExperiment],
        rawExperiments,
        getASPExperimentFlags(state),
    );
    const configExperimentFlags = getContentQueryConfigExperimentFlags(rawExperiments);

    const {
        pageSize,
        offset,
    } = currentPageInfoSelector(state, loadMore ? loadMoreCount + 1 : DEFAULT_PAGE_STATE.loadMoreCount);

    const attributes = isL1orL2 ? metadataAttributes
        : [...metadataAttributes, ...Object.values(refinements.attributes)];

    const query: ContentQuery = {
        categories,
        facetCategories,
        templateUseCases,
        facetTemplateUseCases,
        attributes,
        useRealisationEngineService,
        bypassApproval: getBypassApproval(state),
        locale: getLocaleSelector(state),
        galleryName: getGalleryNameSelector(state),
        keyword: [...metadataKeywords, refinements.keyword].filter(isNotFalse),
        collection: refinements.collection,
        length: pageSize,
        mpvId: getMpvid(state),
        offset,
        selectedOptions: selectedOptionsSelector(state),
        noCache: getNoCache(state),
        useConstraints: getUseConstraints(state),
        rankingStrategyOverride: getRankingStrategyOverride(state),
        forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
        renderProps: getRenderPropsOverrides(state),
        templatePurposes: getTemplatePurposes(state),
        imagePlaceholderAspectRatio,
        imagePlaceholderAspectRatioTolerance,
        placeholderPurposes,
        searchBackend,
        useNewColorPalette,
        experimentFlags,
        configExperimentFlags,
        designCreationTypes,
        designPersonalizationContext,
        debug: debugEnabled,
        includeCategoryAndKeywordData: true,
        filterAltTextCompatibleTagging: true,
    };

    try {
        const contentServiceConfig = config.services.contentQueryService;
        const contentService = new ContentService(contentServiceConfig, getLogger);

        const newContent = await contentService.getContent(query);

        const shouldFetchMerchModules = useMerchModules
                && refinements.collection !== MERCH_MODULES_COLLECTION
                && offset === 0;

        const merchModules = shouldFetchMerchModules ? await contentService.getContent({
            ...query,
            collection: MERCH_MODULES_COLLECTION,
            length: MERCH_MODULES_TILE_SIZE,
            offset: 0,
        }) : null;

        const designIdQSP = getQuickViewId(state);

        const quickViewDesignID = designIdQSP?.toString() ?? designId;

        let qvContent;

        if (quickViewDesignID) {
            const qvQuery: ContentQuery = {
                bypassApproval: getBypassApproval(state),
                locale: getLocaleSelector(state),
                galleryName: getGalleryNameSelector(state),
                mpvId: getMpvid(state),
                noCache: getNoCache(state),
                useConstraints: getUseConstraints(state),
                rankingStrategyOverride: getRankingStrategyOverride(state),
                forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
                designIds: [quickViewDesignID.toString()],
                length: 1,
                selectedOptions: selectedOptionsSelector(state),
                attributes: Object.values(refinements.attributes),
                renderProps: getRenderPropsOverrides(state),
                searchBackend,
                useNewColorPalette,
                experimentFlags,
                configExperimentFlags,
                debug: debugEnabled,
            };

            [qvContent] = (await contentService.getContent(qvQuery)).results.content;

            if (!qvContent) {
                const message = `Failed to open quickView for QSP: ${quickViewDesignID}`;

                getLogger().error({
                    message,
                    query,
                });
            }
        }

        let contentUpdateAction: AnyAction = generateContentUpdate({
            newContent,
            merchModules,
            locale: state.config.locale,
            query,
            quantity,
            qvContent,
            oldState: loadMore ? state : undefined,
            getParentByChildTemplateUseCase,
            selectedTemplateUseCases: templateUseCases.flat(),
        });

        if (shouldUpdateUrl) {
            contentUpdateAction = updateUrl(contentUpdateAction);
        }

        return dispatch(contentUpdateAction);
    } catch (e) {
        // revert on the client, otherwise throw
        if (process.env.IS_CLIENT) {
            getLogger().error(e as Error);
            return dispatch(generateContentRevert(originalState, query));
        }
        throw e;
    }
};
