import { BoundedContent } from '@vp/swan';
import { AppError } from 'shared/errors/AppError';
import { useTranslations } from '~/client/hooks/useTranslations';
import { ErrorDisplay } from '~/client/pages/error/ErrorDisplay';

interface PropTypes {
    error?: AppError | Error,
    resetErrorBoundary?: () => void,
}

export const GalleryErrorPage = (props: PropTypes): JSX.Element => {
    const { error, resetErrorBoundary } = props;
    const localize = useTranslations();

    return (
        <BoundedContent>
            <ErrorDisplay error={error} errorSubheader={localize('ErrorSubheader')} resetErrorBoundary={resetErrorBoundary} />
        </BoundedContent>
    );
};

GalleryErrorPage.displayName = 'GalleryErrorPage';
