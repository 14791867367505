import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { useMemo } from 'react';
import { instrumenter } from 'client/utils/instrumentation';
import { PAGE_ACTION_TYPES } from 'client/constants';
import {
    ActionInfo, buildActionInfo, clearTimer, getTimerDuration, startTimer, TIMERS,
} from 'client/components/Gallery/Header/Personalization/utils';
import {
    ANALYTICS_CATEGORY, ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENT_DETAILS, ANALYTICS_LABEL,
} from 'shared/constants';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { ImageProcessingError, UploadProcessingError } from 'client/errors';
import { useAuth } from 'client/hooks/useAuth';
import { getLogger } from '~/client/utils/gallery/logger';

const PERSONALIZATION_CATEGORY_SUFFIX = 'PP';
const LOGGER = getLogger();

export type PersonalizationAnalytics = {
    recordPreviewButtonClicked: () => void;
    recordUploadButtonClicked: () => void;
    recordPrivacyPolicyClicked: () => void;
    recordFlyoutClosed: () => void;
    recordPersonalizationSignIn: () => void;
    recordApplyPhotosClicked: (numPhotos: number) => void;
    recordImageUploadSuccess: (actionInfo: ActionInfo) => void;
    recordClearPhotoButtonClicked: () => void;
    recordImageUploadError: (actionInfo: ActionInfo, errorMessage: string) => void;
    recordImageProcessingSuccess: (actionInfo: ActionInfo) => void;
    recordImageProcessingError: (actionInfo: ActionInfo) => void;
    recordImageLoadingSuccess: (actionInfo: ActionInfo) => void;
    recordImageLoadingError: (actionInfo: ActionInfo) => void;
    handleUploadMetrics: (
        assetPromises: Promise<void | VistaAsset>[],
        hasAssets: boolean,
        uploadedBlobs: File[],
    ) => void;
    handleProcessingMetrics: (
        previewDataPromises: Promise<Gallery.Models.Personalization.PhotoPreviewData>[],
        hasAssets: boolean,
        currentFiles: File[],
    ) => void
};

const buildPersonalizationAnalytics = (
    analytics: Gallery.Analytics.IAnalytics,
    pageProperties: Gallery.Analytics.PageProperties,
): Omit<PersonalizationAnalytics, 'handleUploadMetrics' | 'handleProcessingMetrics'> => ({
    recordPreviewButtonClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.PREVIEW_WITH_PHOTO,
            ...pageProperties,
        });
    },
    recordUploadButtonClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.UPLOAD_PHOTO,
            ...pageProperties,
        });
    },
    recordPrivacyPolicyClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.PRIVACY_CLICKED,
            ...pageProperties,
        });
    },
    recordFlyoutClosed: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.PREVIEW_WITH_PHOTO,
            ...pageProperties,
        });
    },
    recordImageUploadSuccess: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_UPLOAD_SUCCESS,
            actionInfo,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            category: ANALYTICS_CATEGORY.GALLERY,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_SUCCESS,
            ...actionInfo,
            ...pageProperties,
        });
    },
    recordClearPhotoButtonClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            category: ANALYTICS_CATEGORY.GALLERY,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_CLEAR,
            ...pageProperties,
        });
    },
    recordImageUploadError: (actionInfo: ActionInfo, errorMessage: string): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_UPLOAD_FAILURE,
            { ...actionInfo, reason: errorMessage },
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            category: ANALYTICS_CATEGORY.GALLERY,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_ERROR,
            eventDetail: errorMessage,
            ...actionInfo,
            ...pageProperties,
        });
    },
    recordImageProcessingSuccess: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_PROCESSING_SUCCESS,
            actionInfo,
        );
    },
    recordImageProcessingError: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_PROCESSING_FAILURE,
            actionInfo,
        );
    },
    recordApplyPhotosClicked: (numPhotos: number): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            category: `${ANALYTICS_CATEGORY.FLY_OUT}_${PERSONALIZATION_CATEGORY_SUFFIX}`,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.APPLY_PHOTO,
            numPhotosApplied: numPhotos,
            ...pageProperties,
        });
    },
    recordPersonalizationSignIn: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            category: `${ANALYTICS_CATEGORY.FLY_OUT}_${PERSONALIZATION_CATEGORY_SUFFIX}`,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_SIGN_IN,
            ...pageProperties,
        });
    },
    recordImageLoadingSuccess: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_LOADING_SUCCESS,
            actionInfo,
        );
    },
    recordImageLoadingError: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_LOADING_FAILURE,
            actionInfo,
        );
    },

});

export const usePersonalizationAnalytics = (): PersonalizationAnalytics => {
    const analytics = useAnalytics();
    const isSignedIn = !!useAuth()?.isSignedIn;
    const pageProperties = analytics.getPageProperties();

    const personalizationAnalytics = useMemo(
        () => buildPersonalizationAnalytics(analytics, pageProperties),
        [analytics, pageProperties],
    );

    // Records metrics for individual upload promises
    const handleUploadMetrics = useMemo(
        () => async (
            assetPromises: Promise<void | VistaAsset>[],
            hasAssets: boolean,
            uploadedBlobs: File[],
        ): Promise<void> => {
            startTimer(TIMERS.UPLOAD_TIMER);

            const recordMetricCallback = async (
                assetPromise: Promise<void | VistaAsset>,
                index: number,
            ): Promise<void> => {
                try {
                    const resolvedAsset = await assetPromise;
                    // Track timing on each upload success

                    if (resolvedAsset) {
                        const uploadDuration = getTimerDuration(TIMERS.UPLOAD_TIMER);

                        personalizationAnalytics.recordImageUploadSuccess(buildActionInfo({
                            hasAssets,
                            isSignedIn,
                            actionDuration: uploadDuration,
                            file: uploadedBlobs[index],
                            uploadId: resolvedAsset.data?.id,
                        }));
                    }
                } catch (e) {
                    const uploadDuration = getTimerDuration(TIMERS.UPLOAD_TIMER);
                    const context = {
                        hasAssets,
                        isSignedIn,
                        actionDuration: uploadDuration,
                        file: uploadedBlobs[index],
                        uploadId: e && (e as UploadProcessingError).asset
                            ? (e as UploadProcessingError).asset?.data?.id
                            : undefined,
                    };

                    LOGGER.error(`Failed while resolving personalization asset: ${e}`, e as Error, context);
                    if (e instanceof UploadProcessingError) {
                        personalizationAnalytics.recordImageUploadError(buildActionInfo(context), e.message);
                    }
                }
            };

            try {
                await Promise.allSettled(assetPromises.map(recordMetricCallback));
            } finally {
                clearTimer(TIMERS.UPLOAD_TIMER);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSignedIn],
    );

    // Records metrics for individual photo preview data promises
    const handleProcessingMetrics = useMemo(() => async (
        previewDataPromises: Promise<Gallery.Models.Personalization.PhotoPreviewData>[],
        hasAssets: boolean,
        currentFiles: File[],
    ): Promise<void> => {
        startTimer(TIMERS.PROCESSING_TIMER);

        const recordMetricCallback = async (
            photoPreviewDataPromise: Promise<Gallery.Models.Personalization.PhotoPreviewData>,
            index: number,
        ): Promise<void> => {
            try {
                const photoPreviewData = await photoPreviewDataPromise;
                const processingDuration = getTimerDuration(TIMERS.PROCESSING_TIMER);

                personalizationAnalytics.recordImageProcessingSuccess(buildActionInfo({
                    hasAssets,
                    isSignedIn,
                    actionDuration: processingDuration,
                    file: currentFiles[index],
                    uploadId: photoPreviewData.id,
                }));
            } catch (e) {
                const processingDuration = getTimerDuration(TIMERS.PROCESSING_TIMER);
                const context = {
                    hasAssets,
                    isSignedIn,
                    actionDuration: processingDuration,
                    file: currentFiles[index],
                    uploadId: e && (e as ImageProcessingError).asset
                        ? (e as ImageProcessingError).asset?.data?.id
                        : undefined,
                };

                LOGGER.error(`Failed while processing image metrics: ${e}`, e as Error, context);

                if (e instanceof ImageProcessingError) {
                    personalizationAnalytics.recordImageProcessingError(buildActionInfo(context));
                }
            }
        };

        try {
            await Promise.allSettled(previewDataPromises.map(recordMetricCallback));
        } finally {
            clearTimer(TIMERS.UPLOAD_TIMER);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn]);

    const memo = useMemo(
        () => ({
            ...personalizationAnalytics,
            handleUploadMetrics,
            handleProcessingMetrics,
        }),
        [handleProcessingMetrics, handleUploadMetrics, personalizationAnalytics],
    );

    return memo;
};
