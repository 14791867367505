/**
 * A helper function to create an object from a collection with two properties:
 *   byId and allIds.
 *
 * Ids are converted to lower case
 *
 * @param collection { T[] } an array of objects
 * @param idProperty { string } name of the id property on which to index, defaults to 'id'
 * @return object
 */
export function byId<T extends Record<string, any>>(collection: T[], idProperty = 'id'): IdMap {
    return collection.filter((item) => item).reduce(
        (memo: { byId: Util.StringDictionary<T>; allIds: string[] }, val: T) => {
            const id = val[idProperty].toString();

            // eslint-disable-next-line no-param-reassign
            memo.byId[id] = val;
            memo.allIds.push(id);
            return memo;
        },
        { byId: {}, allIds: [] },
    );
}
