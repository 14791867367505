import { AnyAction } from 'redux';

import { DEFAULT_TENANT } from 'client/constants';
import { createSelector } from 'reselect';
import { CONTENT_UPDATE } from '~/client/store/constants';

export const INITIAL_STATE: State.ConfigState = {
    locale: '' as i18n.Locale,
    name: '',
    galleryId: -1,
    h1Title: '',
    market: '',
    mpvId: '',
    designId: '',
    productKey: '',
    productVersion: 0,
    renderProperties: {} as State.RenderPropsState,
    selectedOptions: {},
    tenant: DEFAULT_TENANT,
    quantity: 100,
    url: new URL('https://localhost:3000'),
    naturalRankingStrategy: '',
    metadata: {} as State.ModifiedGalleryConfigMetadata,
    viewId: '',
    pageTitle: '',
    rawContent: [],
    lastRawContent: [],
    lastRawMerchModulesContent: [],
};

export function reducer(
    state: State.ConfigState = INITIAL_STATE,
    action: AnyAction,
): State.ConfigState {
    switch (action.type) {
        case CONTENT_UPDATE:
            return {
                ...state,
                viewId: action.payload.config.viewId,
                rawContent: action.payload.config.rawContent,
                lastRawContent: action.payload.config.lastRawContent,
                lastRawMerchModulesContent: action.payload.config.lastRawMerchModulesContent,
            };
        default:
            return state;
    }
}

export const booleanRenderPropertySelector = (state: State.GlobalState) => (renderPropertyName: RenderProperty): boolean => (
    state.config.renderProperties[renderPropertyName]?.toLowerCase() === 'true'
);

export const getLocaleSelector = (state: State.GlobalState): i18n.Locale => (state.config.locale);

export const getGalleryNameSelector = (state: State.GlobalState): string => (state.config.name);

export const getGalleryIdSelector = (state: State.GlobalState): number => (state.config.galleryId);

export const getH1Title = (state: State.GlobalState): string => (state.config.h1Title);

export const getMarket = (state: State.GlobalState): string => (state.config.market);

export const getMpvid = (state: State.GlobalState): string => (state.config.mpvId);

export const getProductKey = (state: State.GlobalState): string => (state.config.productKey);

export const getProductVersion = (state: State.GlobalState): number | null => (state.config.productVersion);

export const getMetadata = (state: State.GlobalState): State.ModifiedGalleryConfigMetadata | undefined => (state.config.metadata);

export const getBreadcrumbs = (state: State.GlobalState): Gallery.ConfigApi.Breadcrumb[] | undefined => (state.config.metadata?.breadcrumbs);

export const getProductOptionsDescriptions = (state: State.GlobalState): Gallery.ConfigApi.ProductOptionsDescription[] | undefined => (
    state.config.metadata?.productOptionsDescriptions
);

export const getQuicklinks = (state: State.GlobalState): Gallery.ConfigApi.QuickLink[] | undefined => (state.config.metadata?.quicklinks);

export const getTLPL0Url = (state: State.GlobalState): string | undefined => (state.config.metadata?.tlpL0Url);

export const getIsL1orL2 = (state: State.GlobalState): boolean => state.config.tlpLevel === 'l1' || state.config.tlpLevel === 'l2';
export const getTlpLevel = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => state.config.tlpLevel;

export const getFaqs = (state: State.GlobalState): Gallery.ConfigApi.Faq[] | undefined => (state.config.metadata?.frequentlyAskedQuestions);

export const getQuantitySelector = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<number> => (state.config.quantity);

export const getNaturalRankingStrategySelector = (state: State.GlobalState): string => (state.config.naturalRankingStrategy);

export const getForcedRankingStrategySelector = (state: State.GlobalState): string | null => (state.config.forcedRankingStrategy);

export const selectedOptionsSelector = (state: State.GlobalState): Util.StringDictionary<string | number> => (state.config.selectedOptions);

export const getTenant = (state: State.GlobalState): string => (state.config.tenant);

export const getProductName = (state: State.GlobalState): string => (state.config.metadata?.productName);

export const stringRenderPropertySelector = (state: State.GlobalState) => (
    (renderPropertyName: RenderProperty): State.RenderPropertyValue => (
        state.config.renderProperties[renderPropertyName]
    )
);

export const numberRenderPropertySelector = createSelector(
    (
        state: State.GlobalState,
        props: { renderPropertyName: RenderProperty | null | undefined },
    ): State.RenderPropertyValue => (
        props.renderPropertyName ? state.config.renderProperties[props.renderPropertyName] : null
    ),
    (value: State.RenderPropertyValue) => (value ? parseInt(value as string, 10) : null),
);

export const getUrlPathSelector = (state: State.GlobalState): URL | string | undefined => (state.config.url);

export const getViewId = (state: State.GlobalState): string => (state.config.viewId);

export const getDesignId = (state: State.GlobalState): string => (state.config.designId);

export const getForwardedQuery = (state: State.GlobalState): string | undefined => state.config.forwardedQuery;

export const getPageTitle = (state: State.GlobalState): string => state.config.pageTitle;

export const getLatsLoadMoreLength = (state: State.GlobalState): number => state.config.lastRawContent.length;
