import { useSelector } from 'react-redux';
import { BasicCollapsible } from '@vp/swan';
import { templateUseCaseByIdSelector } from '~/client/store/filterTemplateUseCases';
import { REFINEMENT_DIMENSION } from '~/shared/constants';
import { L2Filter } from 'client/components/Gallery/Filters/HierarchyFilters/L2Filter';
import { L1Filter } from 'client/components/Gallery/Filters/HierarchyFilters/L1Filter';

export interface PropTypes {
    filter: State.TemplateUseCaseFilter;
    options: RenderableTemplateUseCaseFilterOption[];
    selectedTemplateUseCaseId: string | null;
    templateUseCaseId: string;
}

export const FacetedTemplateUseCaseFilter = (props: PropTypes): JSX.Element | null => {
    const {
        filter,
        options,
        selectedTemplateUseCaseId,
        templateUseCaseId,
    } = props;

    const templateUseCaseById = useSelector(templateUseCaseByIdSelector);

    return (
        <BasicCollapsible
            aria-label={filter.title}
            className="filter"
            collapsibleId={filter.name}
            heading={filter.title}
            role={filter.title}
        >
            {selectedTemplateUseCaseId && (
                <L2Filter
                    id={templateUseCaseId}
                    options={options}
                    refinementDimension={REFINEMENT_DIMENSION.TEMPLATE_USE_CASE}
                    title={templateUseCaseById(templateUseCaseId).title}
                />
            )}
            {!selectedTemplateUseCaseId && (
                <L1Filter
                    filter={filter as State.TemplateUseCaseFilter}
                    options={options}
                    refinementDimension={REFINEMENT_DIMENSION.TEMPLATE_USE_CASE}
                />
            )}
        </BasicCollapsible>
    );
};

FacetedTemplateUseCaseFilter.displayName = 'FacetedTemplateUseCaseFilter';
