import { ReactNode, forwardRef } from 'react';
import { Card } from '@vp/swan';

interface TemplateTileProps {
    children: ReactNode;
    onMouseEnter?: () => void;
}

export const TemplateTile = forwardRef<HTMLDivElement, TemplateTileProps>(({ onMouseEnter, children }: TemplateTileProps, ref): JSX.Element => (
    <Card backgroundColor="strong" className="template-tile-wrapper" p={3} pb={6} ref={ref} onMouseEnter={onMouseEnter}>
        {children}
    </Card>
));
