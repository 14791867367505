import { useSelector } from 'react-redux';
import { PRICING_PRESENTATION_TYPES } from '~/client/constants';
import { getSource } from 'client/store/debug/reducer';

export const useAdjustedPricingPresentationType = (
    initialPricingPresentationType: State.RenderPropertyValue,
    quantity: Gallery.Models.Url.ValidParsedQsValue<number>,
): State.RenderPropertyValue => {
    const source = useSelector(getSource);

    return source === 'PLA' && quantity && quantity > 1
        ? PRICING_PRESENTATION_TYPES.QTY_STARTING_AT_PRICE
        : initialPricingPresentationType;
};
