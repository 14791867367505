import { MouseEvent, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    List,
    ListItem,
} from '@vp/swan';

import { FilterCheckboxList } from 'client/components/Gallery/Filters/Shared/FilterCheckboxList';
import { ShowAllCategoriesButton } from 'client/components/Gallery/Filters/FacetedTaxonomy/ShowAllCategoriesButton';
import { buildRefinement, refinementRemove } from 'client/store/refinement';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { selectedChildrenSelectorFactory } from 'client/components/Gallery/Filters/util';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

export interface L2FilterProps {
    id: string;
    options: RenderableFilterOption[];
    refinementDimension: REFINEMENT_DIMENSION;
    title: string;
}

export const L2Filter = (props: L2FilterProps): JSX.Element => {
    const {
        id, options, refinementDimension, title,
    } = props;
    const dispatch = useDispatch();
    const redirectToL0 = useRedirectToL0();

    const selectedChildrenSelector = useMemo(selectedChildrenSelectorFactory, []);
    const selectedValues = useSelector((state: State.GlobalState) => selectedChildrenSelector(state, id));

    const handleShowAllClick = (event: MouseEvent): void => {
        event.preventDefault();

        const refinements = Object.keys(selectedValues).map((c) => buildRefinement(c, refinementDimension));

        refinements.push(buildRefinement(id, refinementDimension));

        if (redirectToL0([], refinements)) {
            return;
        }

        dispatch(refinementRemove(refinements));
        if (isFilterModalHidden()) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    return (
        <List skin="minimal">
            <ListItem>
                <ShowAllCategoriesButton onClick={handleShowAllClick} />
            </ListItem>
            <ListItem>
                <Typography fontSize="small" fontWeight="bold">
                    {title}
                </Typography>
            </ListItem>
            <ListItem>
                <FilterCheckboxList
                    options={options}
                    refinementDimension={refinementDimension}
                    selectedValues={selectedValues}
                    title={title}
                />
            </ListItem>
        </List>
    );
};

L2Filter.displayName = 'L2Filter';
