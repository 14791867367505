import { v4 } from 'uuid';

const memo = new Map<string, string>();

export const getEntityImpressionId = (id: string): string => {
    let value = memo.get(id);

    if (value) {
        return value;
    }

    value = v4();

    memo.set(id, value);

    return value;
};
