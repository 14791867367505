/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Drawer,
    DrawerProps,
    InfiniteScrollContainer,
    UploadDropZone,
    UploadsButton,
    useUploadConfiguration,
    useUploadManager,
} from '@design-stack-vista/upload-components';
import {
    Box,
    Button,
    Divider,
    FlexBox,
    Icon,
    Link,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    tokens,
    Typography,
} from '@vp/swan';
import { useSelector } from 'react-redux';
import {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { SignInButton } from '~/client/components/common/SignInButton';
import { useAuth } from '~/client/hooks/useAuth';
import { useTranslations } from '~/client/hooks/useTranslations';
import { selectedPhotosState } from 'client/atoms/selectedPhotosAtom';
import { PHOTO_UPLOAD_STATES } from 'client/constants';
import {
    isAssetUsed, removeAssetFromPhotoPreviews, convertVistaAssetToPhotoPreviewData, convertPhotoPreviewDataToUsedAssets,
    isPhotoPreviewsEqual,
} from 'client/components/Gallery/Header/Personalization/utils';
import { photoUploadState } from 'client/atoms/photoUploadStateAtom';
import { getLogger } from 'client/utils/gallery/logger';
import { useDrawerHandlers, usePersonalizationFlyoutOpen } from 'client/components/Gallery/Header/Personalization/hooks';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { trackExperimentEngagement } from 'shared/ab-testing';
import { RenderProperty } from 'shared/renderProperties';
import { getRawExperiments } from 'client/store/experimentation';
import { photoPreviewDataSelector } from 'client/store/personalization/selectors';
import { mobilePersonalizationOpenState } from '~/client/atoms/mobilePersonalizationOpenAtom';
import { DismissableAlertBox } from '~/client/components/common/DismissableAlertBox';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { stringRenderPropertySelector } from '~/client/store/config';
import { PersonalizationImageModalPreviewButton } from './PersonalizationImageModalPreviewButton';
import { MAX_UPLOADS } from './constants';
import { useExpectImagesToBePhotos } from './useSpecifyImageCopy';

const LOGGER = getLogger();

export const PersonalizationImageModalContent = (): JSX.Element => {
    const {
        experimentName,
        experimentVariation,
        isSmallScreenPersonalizationEnabled,
    } = useContext(PersonalizationReactContext);

    const setPersonalizationModalOpen = useSetRecoilState(mobilePersonalizationOpenState);
    const { hasAssets } = useUploadManager();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const defaultToPhoto = useExpectImagesToBePhotos();
    const [selectedPhotos, setSelectedPhotos] = useRecoilState(selectedPhotosState);
    const [uploadState, setPhotoUploadState] = useRecoilState(photoUploadState);
    const photoPreviews = useSelector(photoPreviewDataSelector);
    const usedAssets = useMemo(() => selectedPhotos.map(convertPhotoPreviewDataToUsedAssets), [selectedPhotos]);
    const [uploadModalOpen, setUploadModalOpen] = usePersonalizationFlyoutOpen();
    const uploadsButtonRef = useRef<HTMLInputElement>(null);

    // THe follow state is to handle the fact InfinteScrollContainer needs to be in a fixed height div
    // This is a terrible hack and not recommended
    // TODO: QSP-793 clean this up after the underlying issues is addressed by https://vistaprint.atlassian.net/browse/DKT-389
    const [usedHeight, setUsedHeight] = useState(0);
    const navRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const errorRef = useRef<HTMLDivElement>(null);
    const uploadsZoneRef = useRef<HTMLDivElement>(null);
    const hasAssetsHeaderRef = useRef<HTMLDivElement>(null);
    const previewButtonRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const PREVIEW_BUTTON_TO_BOTTOM_MIN_MARGIN = tokens.SwanSemSpace5;

    const isSignedIn = useAuth()?.isSignedIn;
    const localize = useTranslations();
    const {
        supportedFileTypes: { fileExtensionsAsString },
    } = useUploadConfiguration();
    const updatedFileExtensions = fileExtensionsAsString
        .split(', ')
        .filter((ext: string) => ext !== '.pdf')
        .join(', ');

    const stringRenderPropSelector = useSelector(stringRenderPropertySelector);
    const imageCopyKey = stringRenderPropSelector(RenderProperty.PersonalizationImageCopy);
    const usePhotoText = imageCopyKey === 'Photo';
    const acceptedFormatsCopy = usePhotoText
        ? `${localize('PhotoPersonalizationAcceptedFormatsMessage2')} ${updatedFileExtensions}`
        : `${localize('PersonalizationModalAcceptedFormats')} ${updatedFileExtensions}`;

    /*
     * Workaround for cases where elements within modal have too much height due to copy wrapping to next line
     * in header and footer
     * More of the terrible hack
     * TODO: QSP-793 clean this up
     * Also on mobile which has compressed spacing, which allows for more space for the drawer
     */
    useEffect(() => {
        const margin = isSmallScreenPersonalizationEnabled ? 24 + 16 + 32 + 24 + 24 + 8 : 32 * 3 + 24 + 8;
        const calc = (navRef.current?.getBoundingClientRect().height ?? 0)
            + (headerRef.current?.getBoundingClientRect().height ?? 0)
            // an additional hack to account for the margin on the error popover
            + (errorRef.current?.getBoundingClientRect().height ?? -24)
            + (uploadsZoneRef.current?.getBoundingClientRect().height ?? 0)
            + (hasAssetsHeaderRef.current?.getBoundingClientRect().height ?? 0)
            + (previewButtonRef.current?.getBoundingClientRect().height ?? 0)
            + (footerRef.current?.getBoundingClientRect().height ?? 0)
            + margin;

        setUsedHeight(calc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUsedHeight, uploadModalOpen, uploadState.status,
        navRef.current, headerRef.current, errorRef.current,
        uploadsZoneRef.current, hasAssetsHeaderRef.current, previewButtonRef.current, footerRef.current]);

    const selectedPhotosAlreadyApplied = useMemo(() => (
        selectedPhotos.length === photoPreviews.length
        && selectedPhotos.every((selectedPhoto, index) => isPhotoPreviewsEqual(selectedPhoto, photoPreviews[index]))
    ), [photoPreviews, selectedPhotos]);

    const disablePhotoPreviewButton = useMemo(
        () => selectedPhotosAlreadyApplied || selectedPhotos.length > MAX_UPLOADS,
        [selectedPhotos.length, selectedPhotosAlreadyApplied],
    );

    const { handleImageLoadStart, handleImageLoadComplete, handleImageLoadError } = useDrawerHandlers();

    const handleDrawerClick: DrawerProps['onClick'] = async (asset, pageNumber) => {
        try {
            const isSelectedPhoto = isAssetUsed(selectedPhotos, asset, pageNumber);

            let selectedPhotoPreviewData;

            if (isSelectedPhoto) {
                selectedPhotoPreviewData = removeAssetFromPhotoPreviews(asset, selectedPhotos, pageNumber);

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Personalization modal image deselected',
                    eventDetail: `Personalization modal image deselected`,
                    ...analytics.getPageProperties(),
                });
            } else {
                // timeout can be 0 here because a visible drawer image implies dimension data is available
                selectedPhotoPreviewData = [
                    ...selectedPhotos,
                    await convertVistaAssetToPhotoPreviewData(asset, 0, defaultToPhoto, pageNumber),
                ];

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Personalization modal image selected',
                    eventDetail: `Personalization modal image selected`,
                    ...analytics.getPageProperties(),
                });
            }

            setSelectedPhotos(selectedPhotoPreviewData);
        } catch (e) {
            LOGGER.error(`Failed to select photos to preview: ${e}`, e as Error, { asset });
            setPhotoUploadState({
                status: PHOTO_UPLOAD_STATES.ERROR,
                message: localize('PhotoPersonalizationGeneralError'),
            });
        }
    };

    const handleUploadsClicked = (): void => {
        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Image Upload Clicked',
            experimentName,
            `Image Upload Clicked`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Image Upload Clicked',
            eventDetail: `Image Upload Clicked`,
            ...analytics.getPageProperties(),
        });
    };

    const handleClearSelectedPhotos = (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization modal selected images cleared',
            eventDetail: `Personalization modal selected images cleared`,
            ...analytics.getPageProperties(),
        });
        setSelectedPhotos([]);
    };

    useEffect(() => {
        const imageButtonFocusTimeout = setTimeout(() => {
            if (uploadModalOpen) {
                uploadsButtonRef.current?.focus();
                setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.READY });
            }
        }, 100);

        // Cleanup timeout on unmount or dependency change
        return () => clearTimeout(imageButtonFocusTimeout);
    }, [uploadModalOpen, setPhotoUploadState]);

    const handleClosePersonalization = (): void => {
        // Desktop tracking exists in PersonalizationImageModal component
        if (isSmallScreenPersonalizationEnabled) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
                eventLabel: 'Personalization Image Modal Close Button',
                eventDetail: 'Personalization Image Modal Closed',
                ...analytics.getPageProperties(),
            });
        }

        setSelectedPhotos(photoPreviews);
        setUploadModalOpen(false);
        setPersonalizationModalOpen(false);
    };

    const handleMobileUploadBackButton = (): void => {
        setUploadModalOpen(false);
        setSelectedPhotos(photoPreviews);
    };

    return (
        <ModalDialogContent>
            <ModalDialogNav mb={0} pb={isSmallScreenPersonalizationEnabled ? 0 : 7} ref={navRef}>
                {isSmallScreenPersonalizationEnabled && (
                    <Button
                        className="personalization-mobile-back-button"
                        skin="unstyled"
                        onClick={handleMobileUploadBackButton}
                    >
                        <Icon iconType="arrowLeft" pr={1} />
                        {localize('PersonalizationModalBackButtonText')}
                    </Button>
                )}
                <ModalDialogCloseButton
                    accessibleText="Close"
                    aria-label={localize('PhotoPersonalizationModalCloseButtonTitle')}
                    visuallyHiddenLabel={localize('PhotoPersonalizationModalCloseButtonTitle')}
                    onClick={handleClosePersonalization}
                />
            </ModalDialogNav>
            <Box className="personalization-modal-content" px={isSmallScreenPersonalizationEnabled ? 0 : 2}>
                <ModalDialogHeader
                    mb={isSmallScreenPersonalizationEnabled ? 'to-actions' : 7}
                    mt={isSmallScreenPersonalizationEnabled ? 6 : undefined}
                    ref={headerRef}
                >
                    <ModalDialogTitle
                        className={isSmallScreenPersonalizationEnabled ? 'personalization-mobile-modal-header' : 'personalization-modal-header'}
                        fontSkin="title-section"
                    >
                        {localize('PersonalizationModalImageHeaderText')}
                    </ModalDialogTitle>
                </ModalDialogHeader>
                {(uploadState.status === PHOTO_UPLOAD_STATES.ERROR) && (
                    <Box ref={errorRef}>
                        <DismissableAlertBox
                            toast
                            className="personalization-image-alert"
                            dismissVisuallyHiddenLabel={localize('DismissAlert')}
                            marginBottom="to-actions"
                            skin="error"
                            onRequestDismiss={(): void => setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.READY })}
                        >
                            {localize('PersonalizationImageUploadErrorMessage')}
                        </DismissableAlertBox>
                    </Box>
                )}
                <ModalDialogBody>
                    <Box ref={uploadsZoneRef}>
                        <UploadDropZone noClick noKeyboard>
                            <UploadsButton
                                multiple
                                disabled={uploadState.status === PHOTO_UPLOAD_STATES.LOADING}
                                iconPosition="left"
                                ref={uploadsButtonRef}
                                skin={hasAssets && !disablePhotoPreviewButton ? 'secondary' : 'primary'}
                                width="full-width"
                                onClick={handleUploadsClicked}
                            >
                                <Icon iconType="upload" size="20p" />
                                <span>{localize('PersonalizationModalImageUploadButtonText')}</span>
                            </UploadsButton>
                        </UploadDropZone>
                        <Typography className="accepted-formats" fontSize="small" mt="4" textColor="subtle">
                            {acceptedFormatsCopy}
                        </Typography>
                    </Box>
                    {hasAssets && (
                        <>
                            <Box ref={hasAssetsHeaderRef}>
                                <Divider mt={isSmallScreenPersonalizationEnabled ? 'between-subsections' : 7} />
                                <FlexBox
                                    alignItems="center"
                                    justifyContent="space-between"
                                    my={5}
                                >
                                    <Typography fontSize="small" fontWeight="bold">{localize('PersonalizationModalUploadedAssetsTitle')}</Typography>
                                    {(selectedPhotos.length > 0) && (
                                        <Link
                                            component="button"
                                            fontSize="small"
                                            render={(p): any => (
                                                <Button
                                                    className={p.className}
                                                    skin="link"
                                                    onClick={handleClearSelectedPhotos}
                                                >
                                                    {p.children}
                                                </Button>
                                            )}
                                        >
                                            {localize('ClearSelection')}
                                        </Link>
                                    )}
                                </FlexBox>
                            </Box>
                            <Box
                                className={(!isSignedIn)
                                    ? 'personalization-drawer-small-container' : 'personalization-drawer-container'}
                                mb={isSmallScreenPersonalizationEnabled ? 'to-actions' : 6}
                                // This is a hack to deal with InfiniteScrollContainer requiring a fixed size wrapper
                                style={{
                                    maxHeight: `calc(100dvh - ${PREVIEW_BUTTON_TO_BOTTOM_MIN_MARGIN} - ${usedHeight}px)`,
                                }}
                            >
                                <InfiniteScrollContainer infiniteScrollId="personalization-scroll-list-container">
                                    <Drawer
                                        deleteOption="confirm"
                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                        emptyResultsElement={<></>}
                                        styleOptions={{ thumbnailSize: 102, flexboxGap: 12 }}
                                        usedAssets={usedAssets}
                                        onClick={handleDrawerClick}
                                        onImageLoadCompleted={handleImageLoadComplete}
                                        onImageLoadError={handleImageLoadError}
                                        onImageLoadStart={handleImageLoadStart}
                                    />
                                </InfiniteScrollContainer>
                            </Box>
                            <Box
                                ref={previewButtonRef}
                            >
                                <PersonalizationImageModalPreviewButton
                                    disabled={disablePhotoPreviewButton}
                                    selectedPhotosAlreadyApplied={selectedPhotosAlreadyApplied}
                                    width="full-width"
                                />
                            </Box>
                        </>
                    )}
                </ModalDialogBody>
            </Box>
            {(!isSignedIn) && (
                <ModalDialogFooter
                    pinned
                    className="personalization-modal-footer"
                    py={isSmallScreenPersonalizationEnabled ? 0 : 5}
                    ref={footerRef}
                >
                    <Box
                        className="personalization-modal-footer-content"
                        mb={isSmallScreenPersonalizationEnabled ? 5 : undefined}
                        pt={isSmallScreenPersonalizationEnabled ? 6 : undefined}
                        py={isSmallScreenPersonalizationEnabled ? undefined : 4}
                    >
                        <Typography fontSkin="footnote" mb={isSmallScreenPersonalizationEnabled ? 4 : 5} textAlign="center">{localize('PersonalizationModalLogInDescription')}</Typography>
                        <SignInButton
                            isPersonalizationUI
                            className="personalization-modal-sign-in-button"
                            component="button"
                            skin="cta"
                        />
                    </Box>
                </ModalDialogFooter>
            )}
        </ModalDialogContent>
    );
};
