export enum PERSONALIZATION_UX_VARIATIONS {
    Enabled = 'PersonalizationUXEnabled',
    Control = 'PersonalizationUXControl',
}

export const PERSONALIZATION_UX_EXPERIMENT_NAME = 'gallery_6_personalization_ux';

export enum PERSONALIZATION_UX_PHASE_2_VARIATIONS {
    Enabled = 'PersonalizationUXPhase2Enabled',
    Control = 'PersonalizationUXPhase2Control',
}

export const PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME = 'gallery_6_personalization_ux_phase_2';

export enum PERSONALIZATION_UX_MOBILE_VARIATIONS {
    Enabled = 'PersonalizationUXMobileEnabled',
    Control = 'PersonalizationUXMobileControl',
}

export const PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME = 'gallery_6_mobile_personalization_ux';

export enum PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS {
    Enabled = 'PersonalizationUXHolidayExpansionEnabled',
    Control = 'PersonalizationUXHolidayExpansionControl',
}

export const PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME = 'personalization_gal6_holiday';

export enum PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS {
    Enabled = 'PersonalizationUXSignageStickerExpansionEnabled',
    Control = 'PersonalizationUXSignageStickerExpansionControl',
}

export const PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME = 'personalization_gal6_sign_sticker';
