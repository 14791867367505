import thunkMiddleware from 'redux-thunk';
import {
    AnyAction, applyMiddleware, createStore, Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import { history } from 'client/utils/history';
import { rootReducer } from 'client/store/rootReducer';
import { locationToState, stateToLocation } from 'client/store/locationState/middleware';
import { analyticsMiddlewareFactory } from 'client/store/analytics';

const defaultInitialState = {} as State.GlobalState;

// We have to check `.default` because of the way this module is imported/resolved on the server and the client.
// On the server, this iw the entire exports object (namespace), while on the client it's just the module
// @ts-expect-error
const resolvedThunkMiddleware = thunkMiddleware.default ? thunkMiddleware.default : thunkMiddleware;

/**
 * creates a store from a given state and enhancerions
 */
export const configureStore = (
    analytics: Gallery.Analytics.IAnalytics,
    initialState: State.GlobalState = defaultInitialState,
): Store<State.GlobalState> => {
    const middlewares = [analyticsMiddlewareFactory(analytics), stateToLocation(history), resolvedThunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const composedEnhancers = composeWithDevTools({ trace: true })(middlewareEnhancer);
    const store = createStore<State.GlobalState, AnyAction, unknown, unknown>(
        rootReducer,
        initialState,
        composedEnhancers,
    );

    locationToState(store.dispatch);

    analytics.hydrate(store.getState());

    return store;
};
