import {
    memo, useMemo, useRef, MouseEventHandler,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Checkbox,
    ColorSwatches,
    Icon,
    SelectionSet,
    Typography,
} from '@vp/swan';
import classnames from 'classnames';
import { getLocaleSelector } from '~/client/store/config';
import { LinkWithQuery } from '~/client/components/common/LinkWithQuery';
import { TemplateTile } from '~/client/components/common/TemplateTile';
import { Skeleton } from '~/client/components/common/Skeleton';
import { TemplateTileHeader } from '~/client/components/common/TemplateTile/components/TemplateTileHeader';
import { TemplateTileContent } from '~/client/components/common/TemplateTile/components/TemplateTileContent';
import { TemplateTileTitle } from '~/client/components/common/TemplateTile/components/TemplateTileTitle';
import { TemplateTileImage } from '~/client/components/common/TemplateTile/components/TemplateTileImage';
import { TemplateTilePricing } from '~/client/components/common/TemplateTile/components/TemplateTilePricing';
import { useFavorite } from '~/client/components/Gallery/NewDesignTile/hooks/useFavorite';
import { SpacingBlock } from '~/client/components/common/PricingDisplay/SpacingBlock';
import { useColorSwatches } from '~/client/components/Gallery/NewDesignTile/hooks/useColorSwatches';
import { NewDesignProps } from '~/client/components/Gallery/NewDesignTile/interface';
import { usePricing } from '~/client/components/Gallery/NewDesignTile/hooks/usePricing';
import { useGalleryParams } from '~/client/hooks/useGalleryParams';
import { useImage } from '~/client/components/Gallery/NewDesignTile/hooks/useImage';
import { DismissableAlertBox } from '~/client/components/common/DismissableAlertBox';
import { TileGreetingOptions } from '~/client/components/common/TileGreetingOptions';
import { DebugPop } from '~/client/components/Gallery/PreviewArea/DebugPop';
import { TemplateTileColorSwatchWrapper } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatchWrapper';
import { BoostedTerms } from '../PreviewArea/BoostedTerms';

export const NewDesignTile = memo(({
    imageProps,
    colorSwatches,
    handleColorSwatchChange,
    market,
    pricingPresentationType,
    productOptionsByProductKey,
    quantity,
    differentialPrice,
    hideVatMessage,
    hideShippingMessageInVatMessage,
    selectedProductOptions,
    pricingPresentationHTMLModifier = (html: string): string => (html),
    tileEntity,
    selectedDesignId,
    entityId,
    favoriteId,
    previewType,
    noFollow,
    currentDesignId,
    greetingOptions,
    greetingTitle,
    shouldShowGreetingOptions,
    shouldHideForSocialMedia,
    onMouseEnter,
    maxColorSwatchesPerRow,
    onLabelClick,
    refValue,
}: NewDesignProps): JSX.Element => {
    const locale = useSelector(getLocaleSelector);

    // Get Favorite button params
    const {
        isFavorite,
        onClick,
        ariaLabel,
        errorMessage,
        onRequestDismiss,
        dismissVisuallyHiddenLabel,
    } = useFavorite({
        entityId, previewType, colorSwatches, favoriteId,
    });

    // Get Preview Image params
    const { segment } = useGalleryParams();

    const {
        imgRef,
        showSpinner,
        alt,
        imageSrc,
        imageSrcSet,
        loading,
        onError,
        onLoad,
        accessibilityText,
        size,
        isError,
    } = useImage(imageProps);

    const segmentPath = useMemo(() => segment?.join('/'), [segment]);
    const linkProps = useMemo(() => ({
        to: `${segmentPath}/qv/${tileEntity.designId}`,
        state: { designId: currentDesignId },
        noFollow,
    }), [currentDesignId, segmentPath, tileEntity.designId, noFollow]);

    // Get and handle Color Swatches information
    const contentRef = useRef<HTMLDivElement>(null);
    const {
        shouldShowColorSwatches,
        onColorSwatchChangeWrapper,
        handleClickShowMoreButton,
        showMoreIcon,
        isExpanded,
        shouldShowShowMoreButton,
        isColorSwatchColorsSimilar,
    } = useColorSwatches(handleColorSwatchChange, maxColorSwatchesPerRow, colorSwatches);

    const handleStopPropagation = (e: React.MouseEvent): void => e.stopPropagation();

    const handleShowMoreButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        handleClickShowMoreButton();
        onLabelClick(e);
    };

    // Get Pricing data
    const {
        sanitizedPricingHtml,
        lines,
        templateTileTitle,
        shouldRenderSpacingBlock,
        isLoading,
        shouldShowComparativeNames,
    } = usePricing({
        tileEntity,
        differentialPrice,
        pricingPresentationType,
        market,
        quantity,
        hideVatMessage,
        hideShippingMessageInVatMessage,
        selectedProductOptions,
        productOptionsByProductKey,
        pricingPresentationHTMLModifier,
    });

    return (
        <LinkWithQuery {...linkProps} className="template-tile-link">
            <TemplateTile ref={refValue} onMouseEnter={onMouseEnter}>
                <TemplateTileHeader>
                    <>
                        {errorMessage && (
                            <DismissableAlertBox
                                toast
                                className="template-favorite-button-alert-box"
                                dismissVisuallyHiddenLabel={dismissVisuallyHiddenLabel}
                                skin="error"
                                onRequestDismiss={onRequestDismiss}
                            >
                                {errorMessage}
                            </DismissableAlertBox>
                        )}
                        <Checkbox
                            aria-label={ariaLabel}
                            checked={isFavorite}
                            skin="favorite"
                            onClick={onClick}
                        />
                    </>
                </TemplateTileHeader>
                <TemplateTileImage
                    accessibilityText={accessibilityText}
                    alt={alt}
                    isError={isError}
                    loading={loading}
                    ref={imgRef}
                    showSpinner={showSpinner}
                    size={size}
                    src={imageSrc}
                    srcSet={imageSrcSet}
                    onError={onError}
                    onLoad={onLoad}
                />
                <TemplateTileContent>
                    {shouldShowColorSwatches && (
                        <div className="color-swatches-wrapper">
                            <SelectionSet
                                className={classnames('color-swatches', { 'show-color-swatches': isExpanded })}
                                selectedValue={selectedDesignId}
                                onClick={handleStopPropagation}
                                onSelectedValueChange={onColorSwatchChangeWrapper}
                            >
                                <ColorSwatches className={classnames('color-swatches-items', { 'hide-color-swatches': !isExpanded })} ref={contentRef}>
                                    {colorSwatches.map(({ designId, colorComposition = [] }) => (
                                        <TemplateTileColorSwatchWrapper
                                            colorComposition={colorComposition}
                                            designId={designId}
                                            isColorSwatchColorsSimilar={isColorSwatchColorsSimilar}
                                            key={designId}
                                            locale={locale}
                                        />
                                    ))}
                                </ColorSwatches>
                            </SelectionSet>
                                {shouldShowShowMoreButton && (
                                <Button className="show-more-button" skin="tertiary" onClick={handleShowMoreButtonClick}>
                                    <span>{colorSwatches.length}</span>
                                    <Icon iconType={showMoreIcon} size="24p" />
                                </Button>
                                )}
                        </div>
                    )}
                    {shouldShowComparativeNames && (
                        <TemplateTileTitle>
                            {templateTileTitle}
                        </TemplateTileTitle>
                    )}
                    {!shouldHideForSocialMedia && (
                        <TemplateTilePricing>
                            {isLoading && (
                                <span className="price-block">
                                    <Skeleton />
                                </span>
                            )}
                            <Typography
                                className="price-block"
                                component="span"
                                dangerouslySetInnerHTML={{ __html: sanitizedPricingHtml }}
                            />
                            {shouldRenderSpacingBlock && <SpacingBlock lines={lines} />}
                        </TemplateTilePricing>
                    )}
                    {shouldShowGreetingOptions && (
                        <TileGreetingOptions>
                            <Typography as="span" className="tile-greeting-title" fontWeight="bold">
                                {greetingTitle}
                            </Typography>
                            {greetingOptions}
                        </TileGreetingOptions>
                    )}
                    <DebugPop entityId={tileEntity.designId} />
                    <BoostedTerms entityId={tileEntity.designId} />
                </TemplateTileContent>
            </TemplateTile>
        </LinkWithQuery>
    );
});
