import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Match, matchGalleryParams } from 'client/utils/matchGalleryParams';

export const useGalleryParams = (): Match => {
    const location = useLocation();
    const [matchedParams, setMatchedParams] = useState<Match>({ segment: null });

    useEffect(() => {
        setMatchedParams(matchGalleryParams(location.pathname));
    }, [location.pathname]);

    return matchedParams;
};
