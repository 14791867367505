import {
    Button, H3, Icon, Typography,
} from '@vp/swan';
import { memo } from 'react';
import { useTranslations } from '~/client/hooks/useTranslations';
import { MerchLogoIcon } from '~/client/components/common/Icons/MerchLogoIcon';
import { ANALYTICS_LABEL } from '~/shared/constants';
import { handleMerchAnalytics } from '~/experiments/MerchModules/utils';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';

interface DesignPicksBlockProps {
    onClick: () => void;
}

const DesignPicksBlockComponent = ({ onClick }: DesignPicksBlockProps): JSX.Element => {
    const localize = useTranslations();
    const analytics = useAnalytics();

    const handleClick = (): void => {
        handleMerchAnalytics(analytics, ANALYTICS_LABEL.CAROUSEL_VIEW_ALL);
        onClick();
    };

    return (
        <div className="design-picks-block">
            <H3 className="title-block" fontSize="large" textAlign="left">
                {localize('MerchModulesTitle')}
            </H3>
            <div className="selected-label">
                <Typography className="brand-text-left" fontSize="small">
                    <span>{localize('MerchModulesSelectionHandpickedBy')}</span>
                </Typography>
                <Typography className="brand-text-right" fontSize="small" fontWeight="bold">
                    <span>{localize('MerchModulesSelection')}</span>
                    <MerchLogoIcon className="logo-icon" />
                </Typography>
            </div>
            <Button
                className="filter-link"
                skin="unstyled"
                onClick={handleClick}
            >
                <Typography component="span">
                    {localize('MerchModulesFilterLink')}
                </Typography>
                <Icon className="arrow-icon" iconType="arrowRight" size="20p" />
            </Button>

        </div>
    );
};

export const DesignPicksBlock = memo(DesignPicksBlockComponent);
