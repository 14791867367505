import { tokensRaw } from '@vp/swan';

const MERCH_MODULES_CAROUSEL_BREAKPOINTS = {
    xxs: '320px',
    sm: tokensRaw.SwanBaseBreakpointSmStart,
    md: tokensRaw.SwanBaseBreakpointMdStart,
    lg: tokensRaw.SwanBaseBreakpointLgStart,
    xl: tokensRaw.SwanBaseBreakpointXlStart,
};

export enum MERCH_MODULES_VARIATIONS {
    EnabledRow2 = 'MerchModulesEnabledRow2',
    EnabledRow6 = 'MerchModulesEnabledRow6',
    EnabledRow12 = 'MerchModulesEnabledRow12',
    EnabledRow18 = 'MerchModulesEnabledRow18',
    Control = 'MerchModulesControl',
}

export const DEFAULT_SLIDES_COUNT_TO_SHOW = 2;
export const MERCH_MODULES_TILE_SIZE = 11;
export const CAROUSEL_PADDING_OFFSET = 68;

export const SCREEN_WIDTH_LESS_LARGE_SCREENS = tokensRaw.SwanBaseBreakpointSmEnd;
export const MOBILE_CAROUSEL_PADDING_OFFSET = tokensRaw.SwanSemSpace6;
export const SPACE_BETWEEN_TILES = tokensRaw.SwanSemSpace6;

export const CAROUSEL_BREAKPOINTS = [
    { screenWidth: MERCH_MODULES_CAROUSEL_BREAKPOINTS.xxs, slidesToShow: 2 },
    { screenWidth: MERCH_MODULES_CAROUSEL_BREAKPOINTS.sm, slidesToShow: 2 },
    { screenWidth: MERCH_MODULES_CAROUSEL_BREAKPOINTS.md, slidesToShow: 2 },
    { screenWidth: MERCH_MODULES_CAROUSEL_BREAKPOINTS.lg, slidesToShow: 3 },
    { screenWidth: MERCH_MODULES_CAROUSEL_BREAKPOINTS.xl, slidesToShow: 4 },
];

export const IMAGE_STYLE_PROPS = {
    padding: tokensRaw.SwanSemSpace2,
    aspectRatio: '16 / 10',
};

export const MERCH_MODULES_COLLECTION = 'nadesignerspicks';

export const MERCH_MODULES_WRAPPER_CLASS_NAME = 'merch-modules';
