/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import { escapeRegExp } from '~/client/components/common/Highlight/utils';

interface HighlightProps {
    fullText: string;
    searchTerm: string;
}

export const Highlight = memo(({ fullText, searchTerm }: HighlightProps): JSX.Element => {
    if (!searchTerm.trim()) {
        return <span>{fullText}</span>;
    }

    const regexStr = `(${searchTerm.trim().split(/\s+/).map(escapeRegExp).join('|')})`;
    const regex = new RegExp(regexStr, 'gi');
    const parts = fullText.split(regex);
    const final = parts
        .filter((part) => part)
        .map((part, i) => (regex.test(part) ? (
            <span key={i} style={{ fontWeight: 'bold' }}>
                {part}
            </span>
        ) : (
            <span key={i}>{part}</span>
        )));

    return <span>{final}</span>;
});
