import { AbstractService } from 'services/AbstractService';
import { ServiceError } from 'services/errors';

export class RatingsAndReviewsService extends AbstractService implements VP.MTT.Services.RatingsAndReviews.IRatingsAndReviewsService {
    static getRatingsAndReviewsParams = (tlpLevel: Gallery.Models.Url.ValidParsedQsValue<string>): object => {
        if (!tlpLevel) {
            return {};
        }

        // According to SEO requirements for TLP pages the pagination and sorting should work in the following way:
        // Show reviews with different pagination and sorting by "HighestRating"
        // L0 - start on page 1 (offset: 0)
        // L1 - start on page 2 (offset: 5)
        // L2 - start on page 3 (offset: 10)
        const TLP_LEVEL_PAGINATION: Record<string, number> = {
            l1: 5,
            l2: 10,
        };

        return {
            sortBy: 'HighestRating',
            startFrom: TLP_LEVEL_PAGINATION[tlpLevel] || 0,
        };
    };

    public async getRatingsAndReviews(
        tenant: string,
        locale: i18n.Locale,
        mpvId: string,
        tlpLevel: Gallery.Models.Url.ValidParsedQsValue<string>,
    ): Promise<VP.MTT.Models.RatingsAndReviews.RatingsReviewsResponse> {
        const url = `v1/reviews/${tenant}/${locale}/${mpvId}`;

        try {
            const params = RatingsAndReviewsService.getRatingsAndReviewsParams(tlpLevel);
            const response = await this.api.get<VP.MTT.Models.RatingsAndReviews.RatingsReviewsResponse>(url, {
                params,
            });

            return response.data;
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from ratings and reviews service: ${(e as Error).message}`,
            }, e as Error);
        }
    }
}
