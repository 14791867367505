import { ModalDialogCloseButton } from '@vp/swan';

import { useTranslations } from 'client/hooks/useTranslations';
import { SearchBox } from 'client/components/Gallery/Header/Searchbox';
import { useInView } from 'react-intersection-observer';

export interface PropTypes {
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Mobile Filter Menu
 * @param props
 */
export const FilterMenuHeader = (props: PropTypes): JSX.Element => {
    const { onClose } = props;
    const localize = useTranslations();
    const { ref: searchContainerRef, inView, entry } = useInView();
    const searchBoxIsVisible = inView || !entry;

    return (
        <div className="filter-menu-header">
            <ModalDialogCloseButton
                className="filter-menu-header-close"
                visuallyHiddenLabel={localize('FilterMenuCloseButtonText')}
                onClick={onClose}
            />
            <div className="flyout-search-container" ref={searchContainerRef}>
                {searchBoxIsVisible && <SearchBox />}
            </div>
        </div>
    );
};

FilterMenuHeader.displayName = 'FilterMenuHeader';
