export type SplitName = {
    firstname: string;
    lastname: string;
}

const regexForNames = /[."()&]/;

export const splitFullName = (fullname: string): SplitName => {
    const phrases = fullname.trim().split(',');
    const names = phrases[0].split(' ');
    let breakSpace = names.length / 2;
    let firstname = names.slice(0, Math.ceil(breakSpace)).join(' ');

    if (
        // odd number of names
        breakSpace % 1 !== 0
        && names.length > 1 // more than 1
        // middle most is more than a single character
        && names[Math.floor(breakSpace)].length > 1
        // no punctation
        && !regexForNames.test(firstname)
    ) {
        // round down
        breakSpace = Math.floor(breakSpace);
        firstname = names.slice(0, breakSpace).join(' ');
    } else {
        // round up
        breakSpace = Math.ceil(breakSpace);
    }

    let lastname = names.slice(breakSpace).join(' ');

    // add back anything after or including the first comma
    if (phrases.length > 1) {
        lastname = lastname.concat(',').concat(phrases.slice(1).join(','));
    }

    return { firstname, lastname };
};
