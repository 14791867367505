import { QueryFunctionContext } from '@tanstack/react-query';

import config from 'config';
import { RatingsAndReviewsService } from 'services/RatingsAndReviewsService';
import { getLogger } from 'client/utils/gallery/logger';

export const QUERY_KEY = 'ratings_and_reviews';

const ratingsAndReviewsService = new RatingsAndReviewsService(config.services.ratingsAndReviewsService, getLogger);

export const queryRatingsAndReviews = (
    context: QueryFunctionContext<VP.MTT.Queries.RatingsAndReviews.RatingsAndReviewsQueryKey>,
): Promise<VP.MTT.Models.RatingsAndReviews.RatingsReviewsResponse> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        tenant,
        locale,
        mpvId,
        tlpLevel,
    }] = queryKey;

    return ratingsAndReviewsService.getRatingsAndReviews(
        tenant,
        locale,
        mpvId,
        tlpLevel,
    );
};
