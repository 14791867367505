import { selectFilters } from 'client/store/filter';
import { getMetadata } from 'client/store/config/reducer';
import { filterOptionByIdSelector } from 'client/store/filterOption';
import { FILTER_TYPE } from 'shared/constants';

interface GetMetadataQueryParamsResult {
    keywords: string[],
    attributes: string[][],
}

export const getMetadataQueryParams = (state: State.GlobalState): GetMetadataQueryParamsResult => {
    const metadata = getMetadata(state);
    const keywords = metadata?.keywords ?? [];

    const colorFilter = selectFilters(state).find((f) => f.type.toLowerCase() === FILTER_TYPE.COLOR);

    if (!keywords.length || !colorFilter) {
        return {
            keywords,
            attributes: [],
        };
    }

    const getOption = filterOptionByIdSelector(state);
    const colorOptions = (colorFilter as State.AttributeFilter).options.map(getOption);
    const colorDictionary: Record<string, string> = colorOptions.reduce((accum, option) => ({
        ...accum,
        [option.title.toLocaleUpperCase()]: option.value,
    }), {});

    const newKeywords: string[] = [];
    const attributes: string[][] = [];

    keywords.forEach((keyword) => {
        const colorAttribute = colorDictionary[keyword.toLocaleUpperCase()];

        if (colorAttribute) {
            attributes.push([colorAttribute]);
        } else {
            newKeywords.push(keyword);
        }
    });

    return {
        keywords: newKeywords,
        attributes,
    };
};
