import { combineReducers } from 'redux';

import { careReducer } from 'client/store/care';
import { configReducer } from 'client/store/config';
import { designReducer } from 'client/store/design';
import { facetReducer } from 'client/store/facet';
import { filterByCategoryReducer } from 'client/store/filterByCategory';
import { filterCategoriesReducer } from 'client/store/filterCategories';
import { filterByOptionReducer } from 'client/store/filterByOption';
import { filterOptionReducer } from 'client/store/filterOption';
import { filterReducer } from 'client/store/filter';
import { quickViewFilterReducer } from 'client/store/quickViewFilter';
import { pagingReducer } from 'client/store/paging';
import { productOptionsByProductKeyReducer } from 'client/store/productOptionsByProductKey';
import { refinementReducer } from 'client/store/refinement';
import { tileEntityReducer } from 'client/store/tileEntity';
import { merchModulesTileEntitiesReducer } from 'client/store/merchModulesTileEntities';
import { uxReducer } from 'client/store/ux';
import { debugReducer } from 'client/store/debug';
import { experimentationReducer } from 'client/store/experimentation';
import { optionNameByFilterNameReducer } from 'client/store/optionNameByFilterName';
import { personalizationReducer } from 'client/store/personalization';
import { filterByTemplateUseCaseReducer } from 'client/store/filterByTemplateUseCase';
import { filterTemplateUseCasesReducer } from 'client/store/filterTemplateUseCases';
import { parentByChildTemplateUseCaseIdStateReducer } from 'client/store/parentByChildTemplateUseCaseId';
import { attributeNameByFilterNameReducer } from 'client/store/attributeNameByFilterName';
import { templateUseCaseIdByFilterNameReducer } from './templateUseCaseIdByFilterName';

export const rootReducer = combineReducers<State.GlobalState>({
    care: careReducer,
    config: configReducer,
    debug: debugReducer,
    designs: designReducer,
    facets: facetReducer,
    filterByCategory: filterByCategoryReducer,
    filterByTemplateUseCase: filterByTemplateUseCaseReducer,
    filterByOption: filterByOptionReducer,
    optionNameByFilterName: optionNameByFilterNameReducer,
    attributeNameByFilterName: attributeNameByFilterNameReducer,
    filterCategories: filterCategoriesReducer,
    filterTemplateUseCases: filterTemplateUseCasesReducer,
    filterOptions: filterOptionReducer,
    filters: filterReducer,
    quickViewFilters: quickViewFilterReducer,
    paging: pagingReducer,
    productOptionsByProductKey: productOptionsByProductKeyReducer,
    refinements: refinementReducer,
    tileEntities: tileEntityReducer,
    merchModulesTileEntities: merchModulesTileEntitiesReducer,
    ux: uxReducer,
    experimentation: experimentationReducer,
    personalization: personalizationReducer,
    parentByChildTemplateUseCaseId: parentByChildTemplateUseCaseIdStateReducer,
    templateUseCaseIdByFilterName: templateUseCaseIdByFilterNameReducer,
});
