import { Button, CarouselSlide, Typography } from '@vp/swan';
import { memo } from 'react';
import classNames from 'classnames';
import { useTranslations } from '~/client/hooks/useTranslations';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { handleMerchAnalytics } from '~/experiments/MerchModules/utils';
import { ANALYTICS_LABEL } from '~/shared/constants';
import { ViewAllBlockSlideProps } from '~/experiments/MerchModules/interface';

const ViewAllBlockSlideComponent = ({ tileWidth, onClick }: ViewAllBlockSlideProps): JSX.Element => {
    const localize = useTranslations();
    const analytics = useAnalytics();

    const handleClick = (): void => {
        handleMerchAnalytics(analytics, ANALYTICS_LABEL.CAROUSEL_LAST_SLIDE_VIEW_ALL);
        onClick();
    };

    return (
        <div className="view-all-block-wrapper">
            <CarouselSlide
                className={classNames('merch-modules-tile', 'view-all-tile')}
                style={{
                    width: `${tileWidth}px`,
                }}
            >
                <div className="view-all-info">
                    <Typography fontSize="large" fontWeight="bold">
                        {localize('MerchModulesMoreTitle')}
                    </Typography>
                    <Typography className="view-all-description" fontSize="small">
                        {localize('MerchModulesMoreDescription')}
                    </Typography>
                    <Button size="mini" skin="primary" onClick={handleClick}>
                        <Typography fontSize="xsmall">
                            {localize('MerchModulesMoreViewAll')}
                        </Typography>
                    </Button>
                </div>
            </CarouselSlide>
        </div>
    );
};

export const ViewAllBlockSlide = memo(ViewAllBlockSlideComponent);
