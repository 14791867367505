import { isExperimentActive } from '~/shared/ab-testing';
import { ASPExperiment } from './ASPExperiment';

/**
 * Prepares experiment flags based on active ASP experiment variations.
 *
 * @param aspExperiments - Array of ASP experiments
 * @param rawExperiments - Raw experiment string used to determine active variations.
 * @param forceExperimentFlags - Experiment flags that were forcibly set via the `aspExperimentFlags` URL parameter.
 * @returns - Experiment flags with active variations applied.
 */
export const assembleASPExperimentFlags = (
    aspExperiments: ASPExperiment[],
    rawExperiments: string | undefined,
    forceExperimentFlags: Gallery.Models.Url.ASPExperimentFlags,
): Record<string, string> => aspExperiments.reduce((experimentFlags, experiment) => {
    const activeVariation = Object.keys(experiment.variationsValueMap).find(
        (variation) => isExperimentActive(variation, rawExperiments),
    );

    if (!activeVariation) {
        return experimentFlags;
    }

    return {
        [experiment.experimentKey]: experiment.variationsValueMap[activeVariation],
        ...experimentFlags,
    };
}, forceExperimentFlags || {});
