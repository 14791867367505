import { useSelector } from 'react-redux';
import { getQuickViewId } from 'client/store/debug';
import { useEffect, useState } from 'react';

type UseOnLoadImpressionParam = () => void;

export const useOnLoadImpression = (impression: UseOnLoadImpressionParam): void => {
    const isDirectQuickView = useSelector(getQuickViewId);
    const [isQuickViewWasClosed, setIsQuickViewWasClosed] = useState(false);

    useEffect(() => {
        // don't record the page view if we have a quickView QSP
        if (!isDirectQuickView && !isQuickViewWasClosed) {
            setIsQuickViewWasClosed(true);
            impression();
        }
    }, [impression, isQuickViewWasClosed, isDirectQuickView]);
};
