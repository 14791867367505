import {
    ColorSwatch, SelectionSetInput, SelectionSetLabel,
} from '@vp/swan';
import { TemplateTileColorSwatchProps } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatch/interface';

export const TemplateTileColorSwatch = ({
    primaryColor, secondaryColor, designId, title,
}: TemplateTileColorSwatchProps): JSX.Element => (
    <SelectionSetInput value={designId}>
        <SelectionSetLabel className="template-color-swatch-item">
            <ColorSwatch accessibleText={title} primaryColor={primaryColor} secondaryColor={secondaryColor} />
        </SelectionSetLabel>
    </SelectionSetInput>
);
