import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
    Accordion,
    BasicCollapsible,
    Column,
    GridContainer,
    H3,
    Row,
} from '@vp/swan';
import { useSelector } from 'react-redux';
import { booleanRenderPropertySelector, getFaqs } from 'client/store/config/reducer';
import { useTranslations } from 'client/hooks/useTranslations';
import { RenderProperty } from 'shared/renderProperties';

const generateCollapsibleId = (i: number): string => `question${i}`;

export const Faq = (): JSX.Element | null => {
    const [expandedCollapsibles, setExpandedCollapsibles] = useState({
        [generateCollapsibleId(0)]: true,
        [generateCollapsibleId(1)]: true,
    });
    const faqList = useSelector(getFaqs);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const showFaq = booleanRenderProperty(RenderProperty.ShowFAQs);

    const localize = useTranslations();

    return showFaq && faqList && !!faqList.length ? (
        <GridContainer className="faqs">
            <Row>
                <Column span={12} spanLg={10} spanMd={10}>
                    <H3 fontSize="x3large" mb="6">
                        {localize('FaqTitle')}
                    </H3>
                    <Accordion
                        expandedCollapsibles={expandedCollapsibles}
                        skin="standard"
                        onRequestExpandedChange={(collapsibleId, expanded): void => {
                            setExpandedCollapsibles((prevValue) => ({
                                ...prevValue,
                                [collapsibleId]: expanded,
                            }));
                        }}
                    >
                        {faqList.map((faq, i) => {
                            const {
                                answer,
                                question,
                            } = faq;
                            const collapsibleId = generateCollapsibleId(i);

                            return (
                                <BasicCollapsible
                                    collapsibleId={collapsibleId}
                                    heading={question}
                                    key={collapsibleId}
                                >
                                    <ReactMarkdown>{answer}</ReactMarkdown>
                                </BasicCollapsible>
                            );
                        })}
                    </Accordion>
                </Column>
            </Row>
        </GridContainer>
    ) : null;
};

Faq.displayName = 'Faq';
