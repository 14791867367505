import config from 'config';
import { QueryFunctionContext } from '@tanstack/react-query';
import { getLogger } from 'client/utils/gallery/logger';
import { SEARCH_SUGGESTIONS } from 'shared/constants';
import { KeywordsAutoSuggestService } from 'services/KeywordsAutoSuggestService';

export const QUERY_KEY = SEARCH_SUGGESTIONS;
const DEFAULT_SUGGESTIONS_LIMIT = 5;

const keywordsAutoSuggestService = new KeywordsAutoSuggestService(config.services.contentQueryService, getLogger);

export const querySearchSuggestions = (
    context: QueryFunctionContext<KeywordsAutoSuggest.DefaultAutoSuggestQuery>,
): Promise<KeywordsAutoSuggest.Response | null> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        galleryName,
        keyword,
        locale,
        limit,
        useAlternateSearchProvider,
        searchBackend,
    }] = queryKey;

    return keywordsAutoSuggestService.getSuggestions({
        galleryName,
        keyword,
        locale,
        limit: limit || DEFAULT_SUGGESTIONS_LIMIT,
        useAlternateSearchProvider,
        searchBackend,
    });
};
