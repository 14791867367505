import { useSelector } from 'react-redux';

import { getShowWarningSelector } from 'client/store/ux';
import { useTranslations } from 'client/hooks/useTranslations';

export const WarningBox = (): JSX.Element | null => {
    const localize = useTranslations();
    const { warningType } = useSelector(getShowWarningSelector);

    return warningType ? (
        <div className="gallery-warning-box alert-box alert-box-warning" role="alert">
            { localize('ContentWarningMessage') }
        </div>
    ) : null;
};

WarningBox.displayName = 'WarningBox';
