import { BrandContent, BrandData } from '@vp/react-brand';
import { PURPOSE_NAMES } from './constants';
import { TemplateField } from './purposeHelpers';

const getTextFieldValuebyPurpose = (
    dpcText: Gallery.Models.Personalization.DpcText[] | undefined,
    purposeName: PURPOSE_NAMES,
): (string | undefined

    ) => dpcText?.find((t) => t.textField.purposeName === purposeName)?.textField.text;

export const dpcToBrandContent = (dpc: Gallery.Models.Personalization.DesignPersonalizationContext): BrandContent => {
    const brandContent: BrandContent = {};
    // For now, treat everything as facts.
    const facts: BrandData = {};

    const logos = dpc?.images?.filter((i) => i.purpose === 'logo');

    if (logos?.length) {
        facts.designIdentity = {
            ...facts?.designIdentity,
            logos: logos.map(({ image: { printUrl, previewUrl } }) => ({
                uds: {
                    printUrl,
                    previewUrl,
                },
            })),
        };
    }

    const companyName = getTextFieldValuebyPurpose(dpc?.text, PURPOSE_NAMES.COMPANY_NAME);

    if (companyName) {
        facts.profile = {
            ...facts?.profile,
            companyName,
        };

        brandContent.displayName = companyName;
    }

    const fullName = getTextFieldValuebyPurpose(dpc?.text, PURPOSE_NAMES.FULL_NAME);

    if (fullName) {
        facts.people = [{ fullName }];
    }

    // no relevant data
    if (Object.keys(facts).length === 0) {
        return {};
    }

    brandContent.facts = facts;

    return brandContent;
};

export function dpcToTextFields(
    purposeNames: TemplateField[],
    dpc?: Gallery.Models.Personalization.DesignPersonalizationContext,
): Record<string, string> {
    const matchedPurposes = purposeNames
        .map((p) => dpc?.text?.find((text) => p.purposeName === text.textField.purposeName));

    return matchedPurposes.reduce((result, dpcTextField) => (dpcTextField?.textField.purposeName ? {
        ...result,
        [dpcTextField?.textField.purposeName]: dpcTextField?.textField.text,
    } : result), {});
}
