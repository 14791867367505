import { booleanRenderPropertySelector, getQuicklinks } from 'client/store/config/reducer';
import { useSelector } from 'react-redux';
import {
    Typography, Link, H3,
} from '@vp/swan';
import { RenderProperty } from 'shared/renderProperties';

export const QuickLinks = (): JSX.Element | null => {
    const quickLinks = useSelector(getQuicklinks);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const showQuicklinks = booleanRenderProperty(RenderProperty.ShowQuicklinks);
    const validQuickLinks = showQuicklinks && quickLinks?.filter((ql) => !!ql.links.length);

    return validQuickLinks ? (
        <div className="quicklinks-container">
            {validQuickLinks.map((ql) => (
                <div className="quicklinks-section">
                    <H3 fontSize="large" mb="6" textAlign="left">
                        {ql.category}
                    </H3>

                    <div className="quicklinks-links">
                        {ql.links.map((link) => (
                            <Typography fontSize="standard" fontWeight="normal" mb="5" textAlign="left">
                                <Link href={link.href} skin="unstyled">
                                    {link.name}
                                </Link>
                            </Typography>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    ) : null;
};

QuickLinks.displayName = 'QuickLinks';
