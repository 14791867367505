import { useSelector } from 'react-redux';
import { stringRenderPropertySelector } from 'client/store/config';
import { RenderProperty } from 'shared/renderProperties';

/**
 * Returns a function that takes image personalization copy with corresponding equivalent copy (e.g. photo)
 * And returns that copy based on the render prop set for the active experience
 */
export const useSpecifyImageCopy = (): (copy: string) => string => {
    const stringRenderPropSelector = useSelector(stringRenderPropertySelector);
    const imageCopyKey = stringRenderPropSelector(RenderProperty.PersonalizationImageCopy)?.toString() || 'Image';

    return (copy: string): string => copy.replace('Image', imageCopyKey);
};

/**
 * Returns a boolean based on the PersonalizationImageCopy to determine
 * if unclassified uploads should be treated as photos
 */
export const useExpectImagesToBePhotos = (): boolean => {
    const stringRenderPropSelector = useSelector(stringRenderPropertySelector);

    return stringRenderPropSelector(RenderProperty.PersonalizationImageCopy)?.toString() === 'Photo';
};
