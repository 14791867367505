import { useQueryClient } from '@tanstack/react-query';
import { useAsyncList } from '@vp/swan';

import { querySearchSuggestions } from 'client/queries/gallery/querySearchSuggestions';
import { useSelector } from 'react-redux';
import { SEARCH_SUGGESTIONS } from 'shared/constants';
import { getGalleryNameSelector, getLocaleSelector } from '~/client/store/config';
import { buildItems, Item } from 'client/components/Gallery/Header/Searchbox/hooks/utils';
import { getSearchBackend } from 'client/store/debug/reducer';

export const useSuggestions = (): ReturnType<typeof useAsyncList<Item>> => {
    const queryClient = useQueryClient();

    const galleryName = useSelector(getGalleryNameSelector);
    const locale = useSelector(getLocaleSelector);
    const searchBackend = useSelector(getSearchBackend);

    return useAsyncList<Item>({
        load: async ({ filterText }) => {
            if (!filterText) {
                return buildItems([]);
            }

            const result = await queryClient.fetchQuery(
                [SEARCH_SUGGESTIONS, {
                    galleryName,
                    keyword: filterText as string,
                    locale,
                    searchBackend,
                }],
                querySearchSuggestions,
                {
                    staleTime: Infinity,
                },
            );

            if (!result?.suggestions) {
                return buildItems([]);
            }

            return buildItems(result.suggestions);
        },
    });
};
