import { Box } from '@vp/swan';

import { ReviewDisplay } from '@vp/rating-reviews-component/build/pure/index';
import { useRatingsAndReviews } from '~/client/hooks/useRatingsAndReviews';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_CATEGORY } from '~/shared/constants';
import { getMpvid, getProductKey } from '~/client/store/config/reducer';
import { useSelector } from 'react-redux';

export const reviewsId = 'gallery-reviews';

export const ReviewsCallout = (): JSX.Element | null => {
    const productKey = useSelector(getProductKey);
    const mpvId = useSelector(getMpvid);

    const analytics = useAnalytics();
    const ratingsAndReviews = useRatingsAndReviews();

    const trackingData = {
        ...analytics.getPageProperties(),
        category: ANALYTICS_CATEGORY.GALLERY,
        productId: mpvId,
        coreProductId: productKey,
    };

    return ratingsAndReviews ? (
        <Box
            className="reviews-container"
            id={reviewsId}
            paddingX="4"
        >
            <ReviewDisplay {...ratingsAndReviews} trackingData={trackingData} />
        </Box>
    ) : null;
};
