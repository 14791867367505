import { AnyAction } from 'redux';

const initialState: State.FilterByTemplateUseCaseState = {};

export function reducer(
    state: State.FilterByTemplateUseCaseState = initialState,
    action: AnyAction,
): State.FilterByTemplateUseCaseState {
    switch (action.type) {
        default:
            return state;
    }
}

export const filterByTemplateUseCaseSelector = (state: State.GlobalState) => (id: string): string => state.filterByTemplateUseCase[id];
