export interface Item {
    val: string;
}

export interface ItemList {
    items: Item[];
}

export const buildItems = (suggestions: string[]): ItemList => ({
    items: suggestions.map((key) => ({
        val: key,
    })),
});
