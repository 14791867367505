import {
    forwardRef,
    memo,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Carousel, CarouselSlide } from '@vp/swan';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { merchModulesTileEntityAllIdsSelector } from '~/client/store/merchModulesTileEntities';
import { MerchModuleTile } from 'src/experiments/MerchModules/MerchModuleTile';
import {
    CAROUSEL_BREAKPOINTS,
    CAROUSEL_PADDING_OFFSET,
    DEFAULT_SLIDES_COUNT_TO_SHOW,
    MOBILE_CAROUSEL_PADDING_OFFSET,
    SCREEN_WIDTH_LESS_LARGE_SCREENS,
    SPACE_BETWEEN_TILES,
    MERCH_MODULES_COLLECTION,
    MERCH_MODULES_WRAPPER_CLASS_NAME,
} from 'src/experiments/MerchModules/constants';
import { DesignPicksBlock } from 'src/experiments/MerchModules/DesignPicksBlock';
import { ViewAllBlockSlide } from 'src/experiments/MerchModules/ViewAllBlockSlide';
import { ANALYTICS_LABEL, REFINEMENT_DIMENSION } from '~/shared/constants';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { getMerchModulesRowClass, handleMerchAnalytics } from '~/experiments/MerchModules/utils';
import { buildRefinement, refinementAdd } from '~/client/store/refinement';
import { isFilterModalHidden } from '~/client/components/Gallery/Header/FilterMenu/utils';
import { scrollUpToElement } from '~/client/utils/scrollToElement';
import { galleryHeaderId } from '~/client/components/Gallery/Header/constants';
import { getRawExperiments } from '~/client/store/experimentation';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

const MerchModulesComponent = forwardRef<HTMLDivElement>((props, ref): JSX.Element => {
    const dispatch = useDispatch();
    const tileEntityIds = useSelector(merchModulesTileEntityAllIdsSelector);
    const [tileWidth, setTileWidth] = useState(0);
    const [slideCount, setSlideCount] = useState(DEFAULT_SLIDES_COUNT_TO_SHOW);
    const rawExperiments = useSelector(getRawExperiments);
    const merchModulesRowClass = getMerchModulesRowClass(rawExperiments);
    const redirectToL0 = useRedirectToL0();

    const analytics = useAnalytics();

    const containerRef = useRef<HTMLDivElement>(null);

    const updateTileWidth = (): void => {
        const screenWidth = window.innerWidth;
        let slidesToShow = 1;

        for (const breakpoint of CAROUSEL_BREAKPOINTS) {
            if (screenWidth >= parseInt(breakpoint.screenWidth, 10)) {
                slidesToShow = breakpoint.slidesToShow;
                setSlideCount(slidesToShow);
            }
        }

        const CAROUSEL_CONTAINER_PADDING_OFFSSET = screenWidth > parseInt(SCREEN_WIDTH_LESS_LARGE_SCREENS, 10)
            ? CAROUSEL_PADDING_OFFSET
            : parseInt(MOBILE_CAROUSEL_PADDING_OFFSET, 10);

        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth - CAROUSEL_CONTAINER_PADDING_OFFSSET;

            setTileWidth((containerWidth - ((slidesToShow - 1) * parseInt(SPACE_BETWEEN_TILES, 10))) / slidesToShow);
        }
    };

    const handleBeforeChange = (currentSlide: number, nextSlide: number): void => {
        if (nextSlide < currentSlide) {
            handleMerchAnalytics(analytics, ANALYTICS_LABEL.CAROUSEL_PREVIOUS_SLIDE);
        } else if (nextSlide > currentSlide) {
            handleMerchAnalytics(analytics, ANALYTICS_LABEL.CAROUSEL_NEXT_SLIDE);
        }
    };

    const handleViewAll = (): void => {
        const refinement = buildRefinement(MERCH_MODULES_COLLECTION, REFINEMENT_DIMENSION.COLLECTION);

        if (redirectToL0([refinement], [])) {
            return;
        }

        dispatch(refinementAdd([refinement]));

        if (isFilterModalHidden()) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    useEffect(() => {
        updateTileWidth();
        window.addEventListener('resize', updateTileWidth);

        return () => window.removeEventListener('resize', updateTileWidth);
    }, []);

    return (
        <div className={classNames(MERCH_MODULES_WRAPPER_CLASS_NAME, merchModulesRowClass)} ref={ref}>
            <DesignPicksBlock onClick={handleViewAll} />
            <div className="carousel-desktop-container" ref={containerRef}>
                <Carousel
                    variableWidth
                    accessibleTextForNext=""
                    accessibleTextForPrevious=""
                    beforeChange={handleBeforeChange}
                    infinite={false}
                    progressIndicator="dots"
                    slidesToScroll={slideCount}
                    slidesToShow={slideCount}
                >
                    {tileEntityIds.map((entityId: string) => (
                        <div key={entityId}>
                            <CarouselSlide className="merch-modules-tile" style={{ width: `${tileWidth}px` }}>
                                <MerchModuleTile entityId={entityId} />
                            </CarouselSlide>
                        </div>
                    ))}
                    <ViewAllBlockSlide tileWidth={tileWidth} onClick={handleViewAll} />
                </Carousel>
            </div>
        </div>
    );
});

export const MerchModules = memo(MerchModulesComponent);
