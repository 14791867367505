import { atom } from 'recoil';
import { PHOTO_UPLOAD_STATES } from 'src/client/constants';

type State = Omit<Gallery.Models.Personalization.PhotoUploadState, PHOTO_UPLOAD_STATES.ERROR> | {
    status: PHOTO_UPLOAD_STATES.ERROR,
    message: string,
};

export const photoUploadState = atom<State>({
    key: 'photoUploadState',
    default: {
        status: PHOTO_UPLOAD_STATES.READY,
        message: null,
    },
});
