import { AnyAction } from 'redux';

const initialState: State.QuickViewFilterState = {} as State.QuickViewFilterState;

export function reducer(
    state: State.QuickViewFilterState = initialState,
    action: AnyAction,
): State.QuickViewFilterState {
    switch (action.type) {
        default:
            return state;
    }
}

export const selectQuickViewFilterNames = (state: State.GlobalState): string[] => state.quickViewFilters.allIds;

export const quickViewFilterByIdSelector = (state: State.GlobalState) => (name: string): State.QuickViewFilter => state.quickViewFilters.byId[name];
