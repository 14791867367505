import { useSelector } from 'react-redux';

import { maxPreviewHeightSelector } from 'client/store/design';
import { MINIMUM_PRICING_HEIGHT } from 'client/constants';

export const usePreviewDimensions = (): number => {
    const maxPreviewHeight = useSelector(maxPreviewHeightSelector);

    return maxPreviewHeight[0] + MINIMUM_PRICING_HEIGHT;
};
