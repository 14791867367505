import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

import { SET_EXPANDED } from 'client/store/filter/actions';

const initialState: State.FilterState = {
    byId: {},
    allIds: [],
};

function setExpandedForFilter(state: State.FilterState, payload: Actions.SetExpandedForFilterPayload): IdMap {
    const newState = { ...state };

    newState.byId[payload.filterId].collapsed = !payload.expanded;

    return newState;
}

export function reducer(
    state: State.FilterState = initialState,
    action: AnyAction,
): State.FilterState {
    switch (action.type) {
        case SET_EXPANDED:
            return setExpandedForFilter(state, action.payload as Actions.SetExpandedForFilterPayload);
        default:
            return state;
    }
}

export const selectFilters = (state: State.GlobalState): State.Filter[] => state.filters.allIds.map((id) => state.filters.byId[id]);

export const selectFilterExpandedState = createSelector(
    (state: State.GlobalState) => selectFilters(state),
    (filters) => filters.reduce((accum, filter) => ({
        ...accum,
        [filter.name]: !filter.collapsed,
    }), {} as ExpandedCollapsibles),
);

export const filterByIdSelector = (state: State.GlobalState) => (name: string): State.Filter => state.filters.byId[name];
