import { useEffect, useRef } from 'react';
/**
 * Hook that handles checking if addEventListener is supported, adds the event listener, and removes it on cleanup.
 *
 * @see https://usehooks.com/useEventListener/
 */
export const useEventListener = (
    eventName: string,
    handler: (event: UIEvent) => void,
    element?: HTMLElement | Window,
    pathWithinWindow?: string,
): void => {
    const savedHandler = useRef(handler);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        let target = element || window;

        if (target === window && pathWithinWindow) {
            target = pathWithinWindow.split('.').reduce((object, curr) => object[curr as any], target);
        }
        const isSupported = target && target.addEventListener;

        if (!isSupported) {
            return undefined;
        }
        const eventListener = (event: UIEvent): void => savedHandler.current(event);

        target.addEventListener(eventName, eventListener as EventListenerOrEventListenerObject);
        return (): void => {
            target.removeEventListener(eventName, eventListener as EventListenerOrEventListenerObject);
        };
    }, [eventName, element, pathWithinWindow]);
};
