import classnames from 'classnames';
import equal from 'fast-deep-equal/es6/index';
import { memo, forwardRef } from 'react';

interface PropTypes extends React.HTMLProps<HTMLUListElement> {
    tilePreviewSize: State.RenderPropertyValue;
}

export const PreviewListComponent = forwardRef<HTMLUListElement, PropTypes>((props, ref): JSX.Element => {
    const {
        className,
        tilePreviewSize,
        children,
        ...rest
    } = props;

    return (
        <ul
            className={classnames(
                'preview-area',
                `preview-area-${tilePreviewSize?.toLowerCase()}`,
                'stylized-list',
                'stylized-list-minimal',
                className,
            )}
            {...rest}
            ref={ref}
        >
            {children}
        </ul>
    );
});

export const PreviewList = memo(PreviewListComponent, equal);
