import { PRICING_PRESENTATION_TYPES } from 'client/constants';

interface GetPriceDataResult {
    price?: number;
    priceQuantity?: number;
}

export const getPriceData = (
    differentialPriceData?: VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null,
    productQuantity?: number,
    pricingPresentationType?: State.RenderPropertyValue,
): GetPriceDataResult => {
    if (pricingPresentationType === PRICING_PRESENTATION_TYPES.AS_LOW_AS_PRICE_PER_PIECE) {
        return {
            price: differentialPriceData?.baseSelectionsUnitDiscountedPrice?.taxed,
            priceQuantity: 1,
        };
    }

    return {
        price: differentialPriceData?.baseSelectionsDiscountedPrice?.taxed,
        priceQuantity: productQuantity,
    };
};
