import { i18nColorName } from '@design-stack-vista/i18n-color-name';
import queryString from 'query-string';

type redirectUrlParams = {
    designId: string;
    colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[];
    url: string | undefined;
    locale: string;
    productOptions: Util.StringDictionary<string | number>;
    currentColorSwatch: string;
};
type colorListType = { key: string | undefined; name: string, currentColorSwatch: boolean }[];
const getColorList = (
    colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[],
    locale: string,
    currentColorSwatch: string,
): colorListType => {
    const colorsList: colorListType = [];

    colorSwatchObjects?.forEach((colorEntity) => {
        if (colorEntity?.color) {
            colorsList.push({
                key: colorEntity.color,
                name: i18nColorName(colorEntity?.color || '', {
                    culture: locale,
                    colorPalette: 'gallery',
                }),
                currentColorSwatch: currentColorSwatch === colorEntity.designId,
            });
        }
    });
    return colorsList;
};

const filterRequiredOptions = (
    productOptions: Util.StringDictionary<string | number>,
    requiredProductOptions: string[],
): Util.StringDictionary<string | number> => {
    const filteredOptions: Util.StringDictionary<string | number> = {};

    requiredProductOptions.forEach((option) => {
        if (productOptions[option]) {
            filteredOptions[option] = productOptions[option];
        }
    });

    return filteredOptions;
};

export const getTileRedirectionUrl = (
    redirectUrlParams: redirectUrlParams,
): string => {
    const {
        designId,
        colorSwatchObjects,
        url,
        locale,
        productOptions,
        currentColorSwatch,
    } = redirectUrlParams;
    const colorList = getColorList(colorSwatchObjects, locale, currentColorSwatch);
    const swatchColorList = JSON.stringify(colorList);
    const requiredProductOptions = ['Trim', 'Backside'];
    const productOptionsForQueryParam = filterRequiredOptions(productOptions, requiredProductOptions);

    // const sandboxUrl = 'https://www.vistaprint.com/static/merch/vised-pdp-flow-product-pages-v2-vp-na-en-us.feature/en-us/weddinginvitations/experiment-revised_design_first_flow_variation-test/index.html '; // sandbox pdp link
    const prodUrl = url;
    const experimentLink = queryString.stringifyUrl({ url: prodUrl || '', query: { designId, colorSwatches: swatchColorList, ...productOptionsForQueryParam } });

    return experimentLink;
};
