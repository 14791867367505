import classnames from 'classnames';
import {
    HTMLProps, memo, useContext,
} from 'react';
import { FilterMenu } from 'client/components/Gallery/Header/FilterMenu';
import { SearchBox } from 'client/components/Gallery/Header/Searchbox';
import { FavoritesCTA } from 'client/components/Gallery/Header/Favorites';
import { useAuth } from 'client/hooks/useAuth';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { SearchButton } from 'client/components/Gallery/Header/SearchButton';
import { MobilePersonalization } from '~/client/components/Gallery/Header/Personalization/MobilePersonalization';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';

export const StickyHeader = memo((props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { className, ...rest } = props;
    const { isSmallScreenPersonalizationEnabled } = useContext(PersonalizationReactContext);
    const showFavorites = useFavorites();
    const auth = useAuth();

    const shouldShowFavorites = showFavorites && auth;

    return (
        <div
            className={classnames('sticky-header', 'mobile-sticky-header-visible', className)}
            {...rest}
        >
            <div className="mobile-sticky-header-bounded-content">
                <div className="mobile-sticky-header-actions">
                    <div className="subheader-actions">
                        <SearchBox />
                        <SearchButton shouldHideText={isSmallScreenPersonalizationEnabled} />
                        {shouldShowFavorites && (<FavoritesCTA className="mobile-rounded-icon-button" userInteractionLocation="staticHeader" />)}
                        <FilterMenu shouldRenderMobilePersonalizationFilter={isSmallScreenPersonalizationEnabled} />
                        {isSmallScreenPersonalizationEnabled && <MobilePersonalization />}
                    </div>
                </div>
            </div>
        </div>
    );
});

StickyHeader.displayName = 'StickyHeader';
