import { useSelector } from 'react-redux';

import { useTranslations } from 'client/hooks/useTranslations';
import { Typography } from '@vp/swan';
import { getLocaleSelector } from 'client/store/config';
import { buildFullyQualifiedVistaprintUrl } from 'client/utils/vistaprintUrlBuilder';

export const NoContentMessage = (): JSX.Element => {
    const localize = useTranslations();
    const locale = useSelector(getLocaleSelector);
    const productsText = localize('Products');

    const productsLink = `<a href="${buildFullyQualifiedVistaprintUrl({ path: '', locale })}" aria-label=${productsText}>${productsText}</a>`;

    return (
        <>
            <Typography component="p" fontSize="large" fontWeight="bold">
                {localize('GalleryNoContentMessage')}
            </Typography>
            <Typography component="p" dangerouslySetInnerHTML={{ __html: localize('GalleryNoContentMessageLineOne', { productsLink }) }} />
        </>
    );
};

NoContentMessage.displayName = 'NoContentMessage';
