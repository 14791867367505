import { AnyAction } from 'redux';
import { CONTENT_UPDATE } from 'client/store/constants';

export const INITIAL_STATE: State.TileEntityState = {
    byId: {},
    allIds: [],
};

const updateState = (
    action: AnyAction,
    state: State.TileEntityState = INITIAL_STATE,
): State.TileEntityState => ({
    ...state,
    byId: action.payload.merchModulesTileEntities.byId,
    allIds: action.payload.merchModulesTileEntities.allIds,
});

export function reducer(
    state: State.TileEntityState = INITIAL_STATE,
    action: AnyAction,
): State.TileEntityState {
    switch (action.type) {
        case CONTENT_UPDATE:
            return updateState(action, state);
        default:
            return state;
    }
}

export const merchModulesTileEntityAllIdsSelector = (state: State.GlobalState): string[] => (state.merchModulesTileEntities.allIds);

export const merchModulesTileEntityByIdSelector = (state: State.GlobalState) => (id: string): State.TileEntity => state.merchModulesTileEntities.byId[id];
