import config from 'config';
import { getLogger } from 'client/utils/gallery/logger';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';

type GalleryExperiment = Gallery.ABExperimentation.GalleryExperiment;

function getGalleryExperiments(ctxExperiments: string | undefined): GalleryExperiment[] {
    return ctxExperiments
        ? JSON.parse(ctxExperiments) as GalleryExperiment[]
        : [];
}

export const isExperimentActive = (featureFlag: string, ctxExperiments: string | undefined): boolean => {
    try {
        const experiments = getGalleryExperiments(ctxExperiments);

        return experiments.some((c) => c.FeatureFlag === featureFlag);
    } catch (e) {
        getLogger().warning(
            `Experiment reader in isExperimentActive() failed.`,
            e as Error,
            { featureFlag },
        );
        return false;
    }
};

export const trackExperimentImpression = async (
    featureFlag: string,
    ctxExperiments: string | undefined,
    analytics: Gallery.Analytics.IAnalytics,
): Promise<void> => {
    try {
        const experiments = getGalleryExperiments(ctxExperiments);

        if (experiments.length) {
            const experiment = experiments.find((c) => c.FeatureFlag === featureFlag);

            if (experiment && experiment.ExperimentId && experiment.ExperimentVariationId) {
                analytics.trackExperiment({
                    experimentId: experiment.ExperimentId,
                    experimentVariationId: experiment.ExperimentVariationId,
                });
            }
        }
    } catch (e) {
        getLogger().warning(
            `Experiment impression tracking failed.`,
            e as Error,
            { featureFlag },
        );
    }
    return undefined;
};

export const trackExperimentEngagement = async (
    featureFlag: string,
    ctxExperiments: string | undefined,
    analytics: Gallery.Analytics.IAnalytics,
    label: string,
    navigationDetail: string,
    experimentName?: string,
    eventDetail?: string,
): Promise<void> => {
    try {
        const experiments = getGalleryExperiments(ctxExperiments);

        if (experiments.length) {
            const experiment = experiments.find((c) => c.FeatureFlag === featureFlag);
            const experimentData = {
                action: ANALYTICS_EVENT_ACTIONS.EXPERIMENT_CLICKED,
                eventLabel: label,
                trackingTenant: config.tenant,
                variationName: featureFlag,
                navigationDetail,
                experimentName,
                eventDetail,
            };

            if (experiment?.ExperimentId && experiment?.ExperimentVariationId) {
                analytics.trackEvent({
                    ...experimentData,
                    experimentId: experiment.ExperimentId,
                    variationId: experiment.ExperimentVariationId,
                });
            } else if (experiment) {
                // if in a force variation, log to console to make testing these events easier
                // eslint-disable-next-line no-console
                console.log({
                    ...experimentData,
                    featureFlag: experiment.FeatureFlag,
                });
            }
        }
    } catch (e) {
        getLogger().warning(
            `Experiment engagement tracking failed.`,
            e as Error,
            { featureFlag },
        );
    }
    return undefined;
};
