import axios from 'axios';
import config from 'config';
import { useState, useEffect } from 'react';

export const useCdpPdpUrls = (locale: string, mpvId: string, shouldShowRevisedPdp: boolean): { data: string; loading: boolean; error: Error | unknown } => {
    const [data, setData] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | unknown>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const fetchCdpUrls = async () => {
            try {
                setLoading(true);
                const UrlServiceBaseUrl = 'https://url.prod.merch.vpsvc.com';
                const response = await axios.get(`${UrlServiceBaseUrl}/v3/all/vistaprint/${locale.toLowerCase()}`, {
                    params: {
                        pageType: 'ConsumerDetailPage',
                        requestor: 'Gallery',
                    },
                });

                if (!response) {
                    throw new Error('Urls service failed to fetch cdp urls');
                }

                const pdpPath = response?.data[`cdp:${mpvId}`]?.url;
                const vistaprintRoot = config.client.vistaprintRoot[locale.toLowerCase()];
                const CdpPdpUrl = vistaprintRoot + pdpPath;

                setData(CdpPdpUrl);
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        };

        if (shouldShowRevisedPdp) fetchCdpUrls();
    }, [locale, mpvId, shouldShowRevisedPdp]);

    return { data, loading, error };
};
