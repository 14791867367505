import { AnyAction } from 'redux';
import {
    CUSTOM_IMAGES_UPDATE, DESIGN_PERSONALIZATION_UPDATE, LOGO_APPLIED_UPDATE, PERSONALIZED_TEXT_UPDATE,
} from './reducer';

export const logoAppliedUpdate = (logoApplied: boolean): AnyAction => ({
    type: LOGO_APPLIED_UPDATE,
    payload: {
        logoApplied,
    },
});

export const personalizedTextUpdate = (text: Gallery.Models.Personalization.PersonalizedTextData, galleryId?: number): AnyAction => ({
    type: PERSONALIZED_TEXT_UPDATE,
    payload: { text, galleryId },
});

export const customImageDataUpdate = (images: Gallery.Models.Personalization.PhotoPreviewData[], galleryId?: number): AnyAction => ({
    type: CUSTOM_IMAGES_UPDATE,
    payload: { images, galleryId },
});

export const designPersonalizationContextUpdate = (dpc: Gallery.Models.Personalization.DesignPersonalizationContext): AnyAction => ({
    type: DESIGN_PERSONALIZATION_UPDATE,
    payload: { dpc },
});
