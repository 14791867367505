import { forwardRef, memo } from 'react';
import {
    Box, FluidImage, Spinner,
} from '@vp/swan';
import { TemplateTileImageProps } from '~/client/components/common/TemplateTile/components/TemplateTileImage/interface';
import classnames from 'classnames';
import { TemplateTileFallbackPreview } from '~/client/components/common/TemplateTile/components/TemplateTileFallbackPreview';
import { TILE_PREVIEW_SIZE } from '~/client/constants';

export const TemplateTileImage = memo(forwardRef<HTMLImageElement, TemplateTileImageProps>((
    {
        alt,
        loading,
        src,
        srcSet,
        onError,
        onLoad,
        showSpinner,
        accessibilityText,
        size,
        isError,
    },
    ref,
): JSX.Element => (
    <Box className="template-image-wrapper">
        {isError && (
        <TemplateTileFallbackPreview size={size} />
        )}
        {showSpinner && (
        <div className="template-tile-spinner">
            <Spinner accessibleText={accessibilityText} size="standard" />
        </div>
        )}
        <FluidImage
            alt={alt}
            className={classnames(
                'template-tile-image',
                {
                    'template-tile-image-large': size === TILE_PREVIEW_SIZE.LARGE,
                    'template-tile-image-hide': isError,
                },
            )}
            loading={loading}
            ref={ref}
            src={src}
            srcSet={srcSet}
            onError={onError}
            onLoad={onLoad}
        />
    </Box>
)));
