import { memo, useMemo } from 'react';
import { TemplateTileColorSwatch } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatch';
import { TemplateTileColorSwatchWrapperProps } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatchWrapper/interface';
import { getColorSwatchColors } from '~/client/utils/getColorSwatchColors';

export const TemplateTileColorSwatchWrapper = memo(({
    designId, locale, colorComposition, isColorSwatchColorsSimilar,
}: TemplateTileColorSwatchWrapperProps): JSX.Element => {
    const {
        primaryColor,
        secondaryColor,
        title,
    } = useMemo(
        () => getColorSwatchColors(locale, colorComposition, isColorSwatchColorsSimilar),
        [locale, colorComposition, isColorSwatchColorsSimilar],
    );

    return (
        <TemplateTileColorSwatch
            designId={designId}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            title={title}
        />
    );
});
