import { Route } from 'react-router-dom';
import { Root } from 'client/components/common/Root';
import { SitewidePromoBar } from '@vp/sitewide-promo-bar';
import { ComponentProps } from 'react';
import { Index } from 'client/components/Gallery';
import { GalleryErrorPage } from 'client/pages/error/GalleryErrorPage';
import { Locale } from '~/client/components/common/Locale';
import { NotFound } from '~/client/pages/notFound/NotFound';

interface PropTypes {
    messages: i18n.TranslationMap;
    sitewidePromobarConfig?: ComponentProps<typeof SitewidePromoBar>['promoBarConfig'];
}

export const App = (props: PropTypes): JSX.Element => {
    const {
        messages,
        sitewidePromobarConfig,
    } = props;

    return (
        <Route
            element={(
                <Root
                    messages={messages}
                    sitewidePromobarConfig={sitewidePromobarConfig}
                />
            )}
            path="/"
        >
            <Route element={<Locale />} path=":locale">
                <Route element={<NotFound />} path="404" />
                <Route element={<GalleryErrorPage />} path="error" />
            </Route>
            <Route element={<Index />} errorElement={<GalleryErrorPage />} path="*" />
        </Route>
    );
};
