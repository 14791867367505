import config from 'config';
import qs from 'query-string';
import { AxiosError } from 'axios';

import { AbstractService } from 'services/AbstractService';
import { ServiceError } from 'services/errors';

const DEFAULT_PAGE_TYPES = ['other'];

export class MttUrlService extends AbstractService implements Services.IMttUrlService {
    /**
     * Request a localized URL from the MTT URL Service
     * @param locale
     */
    public async getLocalizedUrls(locale: i18n.Locale): Promise<VP.MTT.Urls | null> {
        const url = `v3/all/${config.tenant}/${locale}`;

        try {
            const response = await this.api.get<VP.MTT.Urls>(url, {
                params: {
                    pageType: DEFAULT_PAGE_TYPES,
                    requestor: config.appName,
                },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
            });

            if (!response) {
                throw new Error('No response returned');
            }

            return response.data;
        } catch (e) {
            this.logger.error(new ServiceError({
                url,
                message: `MTTUrlService.getLocalizedUrls call failed: ${(e as Error).message}`,
                response: (e as AxiosError).response,
            }, e as Error));
            return null;
        }
    }

    /**
     * Request a localized URL given urlId from the MTT URL Service
     * @param locale
     * @param urlId
     */
    public async getSpecificUrl(locale: i18n.Locale, urlId: string): Promise<string> {
        const url = `v3/url/${config.tenant}/${locale}/${urlId}`;

        try {
            const response = await this.api.get<VP.MTT.Url>(url, {
                params: {
                    requestor: config.appName,
                },
            });

            if (!response) {
                throw new Error('No response returned');
            }

            return response.data.url;
        } catch (e) {
            this.logger.error(new ServiceError({
                url,
                message: `MTTUrlService.getSpecificUrl call failed: ${(e as Error).message}`,
                response: (e as AxiosError).response,
            }, e as Error));
            return '';
        }
    }
}
