import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { filterDesignVariationToVariableSelections } from 'client/components/Gallery/PreviewArea/GalleryQuickView/utils/filterDesignVariationToVariableSelections';
import { queryDesignVariations } from 'client/queries/queryDesignVariations';

export const DESIGN_VARIATIONS_QUERY_KEY_NAME = 'DESIGN_VARIATIONS';

export const DESIGN_VARIATIONS_QUERY_SETTINGS = {
    staleTime: Infinity,
};

type ResponseDesignVariation = DesignVariations.DesignVariationResponse[];

type DesignVariationsResult = Omit<UseQueryResult<ResponseDesignVariation, Error>, 'data'> & {
    data: DesignVariations.DesignVariationsByHash;
};

export const useDesignVariations = (
    query: DesignVariations.DesignVariationsInput,
): DesignVariationsResult => {
    const queryKey: DesignVariations.QueryKey = [DESIGN_VARIATIONS_QUERY_KEY_NAME, query];
    const { data, ...rest } = useQuery(queryKey, queryDesignVariations, {
        ...DESIGN_VARIATIONS_QUERY_SETTINGS,
        refetchOnWindowFocus: false,
    });

    let dvResponse = data || [];

    // The same TT can have multiple TUCs, if we show all of those TUCs the user will have
    // a seemingly broken experience because the previews won't update when switching options.
    // Remove duplicates directly from the DV response so we never attempt to show.
    if (query.variableTemplateUseCases?.length) {
        // Sort by value for each parent TUC in order
        const sortedTemplateUseCases = query.variableTemplateUseCases.sort();
        const sortedDv = dvResponse.sort(
            (dv1, dv2) => sortedTemplateUseCases.reduce((accum, parentUseCase) => {
                if (accum !== 0) {
                    return accum;
                }

                const dv1Tuc = dv1.templateUseCases.find((tuc) => tuc.parentTemplateUseCase === parentUseCase)?.templateUseCase ?? '';
                const dv2Tuc = dv2.templateUseCases.find((tuc) => tuc.parentTemplateUseCase === parentUseCase)?.templateUseCase ?? '';

                return dv1Tuc.localeCompare(dv2Tuc);
            }, 0),
        );

        const dedupedDv = sortedDv.reduce((accum, dv) => {
            const key = {
                ...dv.productOptions,
                numPlaceholders: dv.numberOfPlaceholders,
                templateToken: dv.templateToken,
            };
            const hash = getProductOptionsHash(key);

            return query.templateUseCases.includes(dv.templateUseCases[0]?.templateUseCase) || !accum[hash] ? {
                ...accum,
                [hash]: dv,
            } : accum;
        }, {} as Record<string, DesignVariations.DesignVariationResponse>);

        dvResponse = Object.values(dedupedDv);
    }

    const designVariations = dvResponse.reduce((accum, dv) => {
        const designVariationsTemplateUseCaseDict = dv.templateUseCases
            .reduce((dvAccum, { templateUseCase, parentTemplateUseCase }) => ({
                ...dvAccum,
                [parentTemplateUseCase]: templateUseCase,
            }), {} as Record<string, string>);

        const variationSelections = filterDesignVariationToVariableSelections(
            dv.productOptions,
            query.variableOptions,
            designVariationsTemplateUseCaseDict,
            dv.numberOfPlaceholders,
            query.variableAttributes,
            query.variableTemplateUseCases,
        );

        return {
            ...accum,
            [getProductOptionsHash(variationSelections)]: {
                ...dv,
                variationSelections,
            },
        };
    }, {} as DesignVariations.DesignVariationsByHash);

    return { data: designVariations, ...rest } as DesignVariationsResult;
};
