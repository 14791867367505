/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    basicImageFileTypeMappings,
    LocalizationProvider,
    UploadConfigurationProvider,
    UploadManagerProvider,
    UploadManagerProviderProps,
} from '@design-stack-vista/upload-components';
import { useDispatch, useSelector } from 'react-redux';
import {
    ComponentProps,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import config from 'config';
import { BrandProfileProvider } from '@vp/react-brand';
import { useAuth } from '~/client/hooks/useAuth';
import { useTranslations } from '~/client/hooks/useTranslations';
import { selectedPhotosState } from 'client/atoms/selectedPhotosAtom';
import { LOCAL_STORAGE_KEYS, MAX_PHOTO_UPLOADS, PHOTO_UPLOAD_STATES } from 'client/constants';
import {
    convertVistaAssetToPhotoPreviewData,
} from 'client/components/Gallery/Header/Personalization/utils';
import { getLogger } from 'client/utils/gallery/logger';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { trackExperimentEngagement } from 'shared/ab-testing';
import { getRawExperiments } from 'client/store/experimentation';
import { photoPreviewDataSelector } from 'client/store/personalization/selectors';
import { SubBrands, Tenants, VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { customImageDataUpdate, designPersonalizationContextUpdate, logoAppliedUpdate } from '~/client/store/personalization/actions';
import { contentUpdate } from '~/client/store/content';
import { booleanRenderPropertySelector, getGalleryIdSelector } from '~/client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import { photoUploadState } from '~/client/atoms/photoUploadStateAtom';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { useExperimentation } from '~/client/hooks/useExperimentation';
import {
    PERSONALIZATION_UX_EXPERIMENT_NAME,
    PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME,
    PERSONALIZATION_UX_PHASE_2_VARIATIONS,
    PERSONALIZATION_UX_VARIATIONS,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS,
} from '~/experiments/tilePersonalization/constants';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { shouldRestorePhotosState } from '~/client/atoms/shouldRestorePhotosAtom';
import { MAX_UPLOADS } from './constants';
import { useExpectImagesToBePhotos } from './useSpecifyImageCopy';

const LOGGER = getLogger();

export const PersonalizationWrapper = (props: ComponentProps<typeof UploadConfigurationProvider>): JSX.Element | null => {
    const {
        children,
    } = props;

    const rawExperiments = useSelector(getRawExperiments);
    const isExperimentActive = useExperimentation();
    const analytics = useAnalytics();
    const defaultToPhoto = useExpectImagesToBePhotos();
    const [selectedPhotos, setSelectedPhotos] = useRecoilState(selectedPhotosState);
    const dispatch = useDispatch();
    const galleryId = useSelector(getGalleryIdSelector);
    const [localUploadedAssets, setLocalUploadedAssets] = useState<VistaAsset[]>();
    const setPhotoUploadState = useSetRecoilState(photoUploadState);
    const auth = useAuth();
    const photoPreviewData = useSelector(photoPreviewDataSelector);
    const [textfieldValues, setTextfieldValues] = useState<Record<string, string>>({});
    const [mobileTextfieldValues, setMobileTextfieldValues] = useState<Record<string, string>>({});
    const [experimentVariation, setExperimentVariation] = useState<string>(PERSONALIZATION_UX_VARIATIONS.Enabled);
    const [experimentName, setExperimentName] = useState(PERSONALIZATION_UX_EXPERIMENT_NAME);
    const isClientSide = useMemo(() => typeof window !== 'undefined', []);
    const localStorageKey = LOCAL_STORAGE_KEYS.PERSISTED_DPC + galleryId;
    const setShouldRestorePhotos = useSetRecoilState(shouldRestorePhotosState);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const [isSmallScreenPersonalizationEnabled,
        setIsSmallScreenPersonalizationEnabled] = useState(booleanRenderProperty(RenderProperty.ShowPersonalizationUI));
    const localize = useTranslations();

    const brandAuth = useMemo(() => (auth?.authorizationHeader ? { type: '', token: auth.authorizationHeader } : ''), [auth]);

    useEffect(() => {
        if (!isSmallScreen()) {
            // Even with only one experiment, calling isExperimentActive here will fire the experiment impression
            if (isExperimentActive(PERSONALIZATION_UX_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_EXPERIMENT_NAME);
            } else if (isExperimentActive(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME);
            } else if (isExperimentActive(PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME);
            } else if (isExperimentActive(PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME);
            }
            setIsSmallScreenPersonalizationEnabled(false);
        }
    }, [isExperimentActive]);

    const handleAssetDelete = async (asset: VistaAsset): Promise<void> => {
        const previewData = await convertVistaAssetToPhotoPreviewData(asset, 0, defaultToPhoto);

        // Remove deleted asset from selected photos to prevent selection state from desyncing
        const newPreviews = selectedPhotos.filter((photo) => photo.id !== previewData.id);

        setSelectedPhotos(newPreviews);

        // If applied photos doesn't include the deleted asset, don't do anything
        if (!photoPreviewData.find((photo) => photo.id === previewData.id)) {
            return;
        }

        dispatch(customImageDataUpdate(newPreviews, galleryId));
        dispatch(logoAppliedUpdate(!!newPreviews.length));
        dispatch(contentUpdate());

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Image Deleted',
            eventDetail: `Image Deleted`,
            ...analytics.getPageProperties(),
        });
    };

    const handleUploadError = useCallback((error?: Error, uploadedAssets?: VistaAsset[]): void => {
        setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.ERROR, message: error?.message });

        LOGGER.error(`Failed to upload image(s): ${error}`, error, { uploadedAssets });
        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            'Image Upload Error',
            experimentName,
            `Image Upload Error: ${error}`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Error',
            eventDetail: `Image Upload Error: ${error}`,
            ...analytics.getPageProperties(),
        });
    }, [analytics, experimentName, experimentVariation, rawExperiments, setPhotoUploadState]);

    const handleUploadComplete = useCallback(() => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Success',
            eventDetail: 'Image Upload Success',
            ...analytics.getPageProperties(),
        });
    }, [analytics]);

    const handleUploadStart: UploadManagerProviderProps['onUpload'] = async (selectedAssets, _uploadedBlobs, nonUploadedBlobs) => {
        setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.LOADING });

        // Do nothing if there are no selected assets or there are any non-uploaded files
        if (!selectedAssets.length || nonUploadedBlobs.length) {
            setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.READY });
            return;
        }

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            'Image Upload Started',
            experimentName,
            `Image Upload Started`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Started',
            eventDetail: `Image Upload Started`,
            ...analytics.getPageProperties(),
        });

        /**
         * Wait for all files to finish uploading
         * onError handler could be called for any of these uploads which doesn't result in a thrown error
         */
        const uploadedAssets = (await Promise.all(selectedAssets)).filter((asset) => !!asset) as VistaAsset[];
        const allAssetsUploaded: boolean = uploadedAssets.length === selectedAssets.length;

        /**
         * If not all files resolve with an asset, don't apply any of the uploads as any failed uploads
         * would have triggered an error state.
         */
        if (!allAssetsUploaded) {
            setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.ERROR });
            return;
        }

        setLocalUploadedAssets(uploadedAssets);
    };

    // Add uploaded images to selected images after they finish uploading
    useEffect(() => {
        const convertAndSetPreviews = async (uploadedAssets: VistaAsset[], photosToSelect: number): Promise<void> => {
            const previews = await Promise.all(uploadedAssets.slice(0, photosToSelect).map(
                (asset) => convertVistaAssetToPhotoPreviewData(asset, 30000, defaultToPhoto),
            ));

            setSelectedPhotos([...selectedPhotos, ...previews]);

            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
                eventLabel: 'Image Upload Completed',
                eventDetail: `Image Upload(s) and Processing Completed`,
                ...analytics.getPageProperties(),
            });
        };

        if (localUploadedAssets) {
            try {
                const photosToSelect = Math.max(0, MAX_UPLOADS - selectedPhotos.length);

                convertAndSetPreviews(localUploadedAssets, photosToSelect);
            } catch (e) {
                handleUploadError(e as Error, localUploadedAssets);
            } finally {
                setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.READY });
                setLocalUploadedAssets(undefined);
            }
        }
    }, [analytics,
        defaultToPhoto,
        handleUploadError,
        localUploadedAssets,
        selectedPhotos,
        setPhotoUploadState,
        setSelectedPhotos]);

    const uploadAuthProvider = useMemo(() => (auth ? {
        getAuthHeaders: async (): Promise<{ Authorization: string }> => ({ Authorization: `Bearer ${auth?.accessToken}` }),
    } : undefined), [auth]);

    useEffect(() => {
        const localStorageData = localStorage.getItem(localStorageKey);

        if (!localStorageData || !isClientSide) {
            return;
        }

        const restoredDpc: Gallery.Models.Personalization.DesignPersonalizationContext = JSON.parse(localStorageData);

        setShouldRestorePhotos(true);

        // Restore Redux state
        dispatch(designPersonalizationContextUpdate(restoredDpc));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClientSide]);

    const contextValue = useMemo(() => ({
        experimentName,
        experimentVariation,
        textfieldValues,
        setTextfieldValues,
        isSmallScreenPersonalizationEnabled,
        mobileTextfieldValues,
        setMobileTextfieldValues,
    }), [experimentName,
        experimentVariation,
        isSmallScreenPersonalizationEnabled,
        mobileTextfieldValues,
        textfieldValues]);

    return (
        <PersonalizationReactContext.Provider value={contextValue}>
            <UploadConfigurationProvider
                maxUploads={MAX_PHOTO_UPLOADS}
                supportedFileTypes={basicImageFileTypeMappings.filter((type) => type.fileType !== 'application/pdf')}
            >
                <UploadManagerProvider
                    assetsExpireOn={process.env.NODE_ENV === 'production' ? 'never' : 'P1D'}
                    authProvider={uploadAuthProvider}
                    experience="gallery-brand-personalization"
                    subBrand={SubBrands.VistaPrint}
                    writeTenant={process.env.NODE_ENV === 'production' ? Tenants.VistaPrint : Tenants.VistaNonProd}
                    onAssetDeleted={handleAssetDelete}
                    onError={handleUploadError}
                    onUpload={handleUploadStart}
                    onUploadCompleted={handleUploadComplete}
                >
                    <LocalizationProvider
                        localizedValues={{
                            deletingLabel: localize('PhotoPersonalizationImageSelectionAssetDeletingLabel'),
                            deleteConfirmationKeys: {
                                closeButton: 'Close Delete Image Dialog',
                                dialogTitle: localize('PersonalizationModalDeleteImageConfirmationHeader'),
                                dialogMessage: localize('PersonalizationModalDeleteImageConfirmationDescription'),
                                cancelButton: localize('Cancel'),
                                confirmButton: localize('Confirm'),
                            },
                            deleteButtonTitle: localize('PhotoPersonalizationDeleteImageButton'),
                            assetInUseLabel: localize('PhotoPersonalizationImageUsedHoverText'),
                            loadingLabel: localize('PhotoPersonalizationImageSelectionAssetLoadingLabel'),
                            uploadingLabel: localize('PhotoPersonalizationImageSelectionAssetUploadingLabel'),
                        }}
                    >
                        <BrandProfileProvider
                            includeSpeculations
                            authorization={brandAuth}
                            requestor={config.appName}
                        >
                            {children}
                        </BrandProfileProvider>
                    </LocalizationProvider>
                </UploadManagerProvider>
            </UploadConfigurationProvider>
        </PersonalizationReactContext.Provider>
    );
};
