import { AnyAction } from 'redux';

/**
 * Determines if query in the query string should be deleted from the
 * query string or not, based on if the provided `value` is falsey or not.
 * If `value` is falsey or empty, `true` is returned; otherwise false is
 * returned.
 *
 * Note, this currently considers false boolean and empty strings as an
 * indication that the query should be removed from the query string. It may be
 * perfectly valid in the future to have empty strings or stringified booleans
 * in the query string, so this can be updated to only check for null and
 * undefined instead of doing `!value`.
 * @param value
 */
export const shouldDelete = (value: unknown): boolean => (
    !value
    || Array.isArray(value) && !value.length
    || typeof value === 'object' && !Object.keys(value as Record<string, any>).length
);

/**
 * Either deletes the provided query from the query object or adds/replaces the
 * key with the provided queryPart.
 * Contains side-effects on `query`
 * @param key
 * @param queryPart
 * @param query
 */
export function updateQuery(key: string, queryPart: unknown, query: Record<string, any>): void {
    if (shouldDelete(queryPart)) {
        // eslint-disable-next-line no-param-reassign
        delete query[key];
    } else {
        // eslint-disable-next-line no-param-reassign
        query[key] = queryPart;
    }
}

/**
 * Helper function that adds a meta property to an action to update the url
 */
export function updateUrl(action: AnyAction): AnyAction {
    return {
        ...action,
        meta: {
            ...action.meta,
            updateUrl: true,
        },
    };
}
