import {
    FlexBox, Icon, IconProps, Typography,
} from '@vp/swan';

interface PersonalizationAppliedImageIconProps extends Omit<IconProps, 'src' | 'classname'> {
    photoPreviews: Gallery.Models.Personalization.PhotoPreviewData[]
}

export const PersonalizationAppliedImageIcon = (props: PersonalizationAppliedImageIconProps): JSX.Element => {
    const { photoPreviews, ...rest } = props;

    return (
        <FlexBox>
            <Icon className="personalization-change-image-button-icon" src={photoPreviews[0].thumbnailUrl} {...rest} />
            {photoPreviews.length > 1 && (
                <FlexBox alignItems="center" className="personalization-extra-images-icon" justifyContent="center">
                    <Typography fontSize="small">
                        {`+${photoPreviews.length - 1}`}
                    </Typography>
                </FlexBox>
            )}
        </FlexBox>
    );
};
