import { H2 } from '@vp/swan';

declare const PUBLIC_PATH: string;

export interface CareContactPropTypes {
    carePhone: VP.Care.Models.ChannelProviderService.PhoneData | VP.Care.CarePhone;
    translations: VP.Care.Translations;
}

const PHONE_ICON_SRC = `${PUBLIC_PATH}images/care-phone.svg`;

export const PhoneIcon = (): JSX.Element => (<img alt="" className="care-icon graphic-button" src={PHONE_ICON_SRC} />);

PhoneIcon.displayName = 'PhoneIcon';

export const CareContactInfo = (props: CareContactPropTypes): JSX.Element => {
    const { translations, carePhone } = props;
    const { gallery } = translations;

    return (
        <div>
            <H2 fontSize="standard">
                <PhoneIcon />
                {gallery.careTitle}
            </H2>
            <p>
                {(gallery.careSubTitle).replace('<phone />', carePhone.phoneNumber)}
            </p>
            <ul className="stylized-list stylized-list-minimal">
                {carePhone.shortFormatSchedule.map((hours) => (<li key={hours}>{hours}</li>))}
            </ul>
        </div>
    );
};

CareContactInfo.displayName = 'CareContactInfo';
