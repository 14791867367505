import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';

export type ActionInfo = {
    actionDuration: number;
    fileSize: number;
    fileType: string;
    hasAssets: boolean;
    isSignedIn: boolean;
    uploadId?: string;
};

type BuildActionInfo = {
    actionDuration: number;
    file: File | VistaAsset | string;
    hasAssets: boolean;
    isSignedIn: boolean;
    uploadId?: string;
};

const buildFileSize = (file: BuildActionInfo['file']): number => {
    if (file instanceof File) {
        return file.size;
    }

    if (file instanceof VistaAsset) {
        return file.data?.info.storage?.fileSizeBytes || 0;
    }

    return 0;
};

const buildFileType = (file: BuildActionInfo['file']): string => {
    if (file instanceof File) {
        return file.type;
    }

    if (file instanceof VistaAsset) {
        return file.data?.info.image?.format || '';
    }

    return '';
};

export const buildActionInfo = ({
    actionDuration,
    file,
    hasAssets,
    isSignedIn,
    uploadId,
}: BuildActionInfo): ActionInfo => ({
    actionDuration,
    uploadId,
    hasAssets,
    isSignedIn,
    fileSize: buildFileSize(file),
    fileType: buildFileType(file),
});
