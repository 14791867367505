import { AnyAction } from 'redux';

const initialState: State.TemplateUseCaseIdByFilterNameState = {};

export function reducer(
    state: State.TemplateUseCaseIdByFilterNameState = initialState,
    action: AnyAction,
): State.TemplateUseCaseIdByFilterNameState {
    switch (action.type) {
        default:
            return state;
    }
}

export const templateUseCaseIdByFilterNameSelector = (state: State.GlobalState) => (filterName: string): string => state.templateUseCaseIdByFilterName[filterName];
