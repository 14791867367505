/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Button,
    Icon,
} from '@vp/swan';
import { useSelector } from 'react-redux';
import {
    useContext,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslations } from '~/client/hooks/useTranslations';
import { selectedPhotosState } from 'client/atoms/selectedPhotosAtom';
import { usePersonalizationFlyoutOpen } from 'client/components/Gallery/Header/Personalization/hooks';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { trackExperimentEngagement } from 'shared/ab-testing';
import { RenderProperty } from 'shared/renderProperties';
import { getRawExperiments } from 'client/store/experimentation';
import { mobilePersonalizationOpenState } from '~/client/atoms/mobilePersonalizationOpenAtom';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { getLocaleSelector, stringRenderPropertySelector } from '~/client/store/config';
import { useSpecifyImageCopy } from './useSpecifyImageCopy';
import { PersonalizationAppliedImageIcon } from './PersonalizationAppliedImageIcon';

export const ImageModalOpenButton = (): JSX.Element => {
    const {
        experimentName,
        experimentVariation,
    } = useContext(PersonalizationReactContext);

    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const selectedPhotos = useRecoilValue(selectedPhotosState);
    const personalizationModalOpen = useRecoilValue(mobilePersonalizationOpenState);
    const [, setUploadModalOpen] = usePersonalizationFlyoutOpen();
    const specifyImageCopy = useSpecifyImageCopy();
    const locale = useSelector(getLocaleSelector);
    const imageButtonRef = useRef<HTMLInputElement>(null);

    const localize = useTranslations();
    const stringRenderPropSelector = useSelector(stringRenderPropertySelector);
    const imageCopyKey = stringRenderPropSelector(RenderProperty.PersonalizationImageCopy);
    const usePhotoText = imageCopyKey === 'Photo';

    useEffect(() => {
        if (personalizationModalOpen) {
            imageButtonRef.current?.focus();
        }
    }, [personalizationModalOpen]);

    useEffect(() => {
        const imageButtonFocusTimeout = setTimeout(() => {
            if (personalizationModalOpen) {
                imageButtonRef.current?.focus();
            }
        }, 100);

        // Cleanup timeout on unmount or dependency change
        return () => clearTimeout(imageButtonFocusTimeout);
    }, [personalizationModalOpen]);

    const onPreviewButtonClick = (): void => {
        setUploadModalOpen(true);

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            'Personalization Upload Modal',
            experimentName,
            `Personalization Upload Modal`,
        );
        if (selectedPhotos.length) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
                eventLabel: 'Personalization Upload Modal',
                eventDetail: 'Personalization Upload Modal: Change Image',
                ...analytics.getPageProperties(),
            });
        } else {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
                eventLabel: 'Personalization Upload Modal',
                eventDetail: 'Personalization Upload Modal: Add Image',
                ...analytics.getPageProperties(),
            });
        }
    };

    // TODO QSP-764: replace this with a more sustainable solution for translated copy that fits within its UI
    const addImageButtonCopy = useMemo(() => {
        if (locale === 'pt-pt') {
            return localize(specifyImageCopy('PersonalizationAddImageButton'));
        }

        if (!usePhotoText && locale.includes('en-')) {
            return localize(specifyImageCopy('PersonalizationAddLogoOrPhotoButton'));
        }

        return localize(specifyImageCopy('PersonalizationAddYourImageButton'));
    }, [locale, localize, specifyImageCopy, usePhotoText]);

    return (
        <Button iconPosition="left" id="personalization-image-modal-open-button" ref={imageButtonRef} width="full-width" onClick={onPreviewButtonClick}>
            {selectedPhotos.length ? <PersonalizationAppliedImageIcon objectFit="cover" photoPreviews={selectedPhotos} size="28p" /> : <Icon iconType="image" size="20p" />}
            <span>
                {selectedPhotos.length
                    ? localize(specifyImageCopy('PersonalizationChangeImageButton'))
                    : addImageButtonCopy}
            </span>
        </Button>
    );
};
