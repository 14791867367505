/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    ModalDialog,
} from '@vp/swan';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { usePersonalizationFlyoutOpen } from 'client/components/Gallery/Header/Personalization/hooks';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { photoPreviewDataSelector } from 'client/store/personalization/selectors';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { useSetRecoilState } from 'recoil';
import { selectedPhotosState } from '~/client/atoms/selectedPhotosAtom';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { ImageModalOpenButton } from './ImageModalOpenButton';
import { PersonalizationImageModalContent } from './PersonalizationImageModalContent';

export const PersonalizationImageModal = (): JSX.Element => {
    const { isSmallScreenPersonalizationEnabled } = useContext(PersonalizationReactContext);
    const analytics = useAnalytics();
    const setSelectedPhotos = useSetRecoilState(selectedPhotosState);
    const photoPreviews = useSelector(photoPreviewDataSelector);
    const [uploadModalOpen, setUploadModalOpen] = usePersonalizationFlyoutOpen();

    const handleDialogClose = (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
            eventLabel: 'Personalization modal closed',
            eventDetail: `Personalization modal closed`,
            ...analytics.getPageProperties(),
        });
        if (!isSmallScreen()) {
            setSelectedPhotos(photoPreviews);
        }
        setUploadModalOpen(false);
    };

    return (
        <>
            <ImageModalOpenButton />
            {!isSmallScreenPersonalizationEnabled
            && (
            <ModalDialog
                onlyRenderWhenOpen
                bodyWidth="capped"
                className="personalization-image-modal"
                isOpen={uploadModalOpen}
                variant="panel-right"
                onRequestDismiss={handleDialogClose}
            >
                <PersonalizationImageModalContent />
            </ModalDialog>
            )}
        </>
    );
};
