import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { CustomImage } from '@design-stack-vista/react-vortex';
import { UploadProcessingError } from 'client/errors';

type DimensionData = Pick<CustomImage, 'height' | 'width'>;

/**
 * When the VistaAsset is first returned from the UploadComponent, the image data containing the image's dimension
 * properties is not immediately available. This is likely to do with a bug in the VistaAssetsSDK/Sherbert service
 * where the upload is being returned before it is actually ready. This is a decent work around to poll the asset
 * for the dimension properties until they're available.
 *
 * It also appears there is a decent correlation between when the dimension data is available and the asset urls
 * are ready to use which addresses the issue where the urls were being returned too early and causing rendering
 * previews to fail.
 *
 * @param asset A VistaAsset to search for height/width dimension properties
 * @param timeout The number of milliseconds to search before giving up
 * @returns { height: number; width: number }
 */
export const waitForDimensionData = (asset: VistaAsset, pageNumber: number, timeout: number): Promise<DimensionData> => new Promise<DimensionData>((res, rej) => {
    let timeoutRef: NodeJS.Timeout | undefined;
    const timer = Date.now();

    const checkForDimensionData = async (): Promise<void> => {
        try {
            if (asset.data?.info?.image && 'numPages' in asset.data.info.image) {
                const response = await asset.getPageImageInfo({ pageNo: pageNumber }) as
                    { height?: number, width?: number };

                const height = response.height && Math.floor(response.height);
                const width = response.width && Math.floor(response.width);

                clearTimeout(timeoutRef);
                res({ height, width });
                return;
            }

            // Give up if we have exceeded the timeout
            if (timeout > 0 && Date.now() - timer > timeout) {
                throw new UploadProcessingError('Exceeded max timeout', asset);
            }

            /**
             * There is an issue with the VistaAsset where the image format is always coming back as unknown
             * which is not typed to have height and width properties. However, the asset does in fact have
             * a height and width so we can ignore this until it's fixed.
             */
            // @ts-ignore
            const height = asset.data?.info?.image?.height;
            // @ts-ignore
            const width = asset.data?.info?.image?.width;

            // If there is no height or width, check again in a bit
            if (height === undefined || width === undefined) {
                timeoutRef = setTimeout(checkForDimensionData, 50);
                return;
            }

            // If the width and height are found resolve with the dimension values
            clearTimeout(timeoutRef);
            res({ height, width });
        } catch (e) {
            // Reject with the original error so it can be caught by the caller
            clearTimeout(timeoutRef);
            rej(e);
        }
    };

    checkForDimensionData();
});

/**
 * Pulls information from a VistaAsset to generate PhotoPreviewData
 *
 * @param asset A VistaAsset to pull PhotoPreviewData from
 * @param timeout The number of milliseconds to search before giving up when retrieving dimension data
 * @returns { id: string; previewUrl: string; printUrl: string; thumbnailUrl: string; height: number; width: number; }
 */
export const convertVistaAssetToPhotoPreviewData = async (asset: VistaAsset, dimensionDataTimeout: number, defaultToPhoto: boolean, pageNumber = 1): Promise<Gallery.Models.Personalization.PhotoPreviewData> => {
    const { height, width } = await waitForDimensionData(asset, pageNumber, dimensionDataTimeout);
    const {
        data,
        print,
        thumbnail,
        webPreview,
    } = asset;

    // We might get better clasification results by using
    // (await asset.getImageInfo(data?.id)).lineartness
    // isPhoto seems to be the same as lineartness < 4
    // Perhaps checking lineartness < 8 would be better
    let classifiedAsPhoto;

    try {
        classifiedAsPhoto = data ? (await asset.getImageInfo?.(data?.id))?.isPhoto : defaultToPhoto;
    } catch (_error) {
        classifiedAsPhoto = defaultToPhoto;
    }
    const purpose = classifiedAsPhoto || defaultToPhoto ? 'photo' : 'logo';

    return {
        id: data?.id || '',
        previewUrl: webPreview.getUrl({ includeSignature: true, pageNum: pageNumber }),
        printUrl: print.getUrl({ includeSignature: true }),
        originalSourceUrl: asset.getUrl({ includeSignature: true, pageNum: pageNumber }),
        thumbnailUrl: thumbnail.getUrl({ includeSignature: true, pageNum: pageNumber }),
        height: height ?? 0,
        width: width ?? 0,
        page: pageNumber,
        purpose,
    };
};

export const convertPhotoPreviewDataToUsedAssets = (photo: Gallery.Models.Personalization.PhotoPreviewData): Gallery.Models.Personalization.UsedAssets => ({ assetId: photo.id || '', pages: [photo.page ?? 1] });

export const isPhotoPreviewsEqual = (a: Gallery.Models.Personalization.PhotoPreviewData, b?: { id?: string, page?: number }): boolean => a.id === b?.id && a.page === b?.page;

export const isAssetUsed = (selectedPhotos: Gallery.Models.Personalization.PhotoPreviewData[], asset: VistaAsset, pageNumber: number): boolean => selectedPhotos.some((photo) => isPhotoPreviewsEqual(photo, { id: asset.data?.id, page: pageNumber }));

export const removeAssetFromPhotoPreviews = (
    asset: VistaAsset,
    photoPreviews: Gallery.Models.Personalization.PhotoPreviewData[],
    pageNumber?: number,
): Gallery.Models.Personalization.PhotoPreviewData[] => photoPreviews.filter(
    (photoPreview) => !isPhotoPreviewsEqual(photoPreview, { id: asset.data?.id, page: pageNumber }),
);
