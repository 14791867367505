import classnames from 'classnames';
import { Button } from '@vp/swan';

import { useClearRefinements } from 'client/hooks/useClearRefinements';
import { useTranslations } from 'client/hooks/useTranslations';

interface PropTypes extends React.ComponentProps<typeof Button> {
    refinements: Refinements.Refinements;
}

/**
 *
 * @param props
 */
export const ClearAllRefinementsTextButton = (props: PropTypes): JSX.Element => {
    const { refinements, ...rest } = props;
    const localize = useTranslations();

    const { curryClearAll } = useClearRefinements();
    const refinementsCount = Object.values(refinements).length;
    const hasRefinements = refinementsCount > 0;

    return (
        <Button
            {...rest}
            aria-label={localize('ClearAllRefinementsSelection')}
            className={classnames('clear-all-button', 'secondary')}
            onClick={curryClearAll(refinements)}
        >
            <span className="clearAllBtnText">
                {!hasRefinements && localize('SelectedRefinementsClearAll')}
                {hasRefinements && localize('SelectedRefinementsClearAllWithCount', { count: `${refinementsCount}` })}
            </span>
        </Button>
    );
};

ClearAllRefinementsTextButton.displayName = 'ClearAllRefinementsTextButton';
