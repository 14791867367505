import { QueryFunctionContext } from '@tanstack/react-query';

import config from 'config';
import { getLogger } from 'client/utils/gallery/logger';
import { MttUrlService } from '~/services/MttUrlService';

export const QUERY_KEY = 'url_service';

const urlService = new MttUrlService(config.services.mttUrlService, getLogger);

export const queryUrl = (
    context: QueryFunctionContext<VP.MTT.Queries.Url.UrlQueryKey>,
): Promise<string> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        locale,
        urlId,
    }] = queryKey;

    return urlService.getSpecificUrl(
        locale,
        urlId,
    );
};
