import {
    Box,
    Collapsible,
    CollapsibleContent,
    CollapsibleSummary,
    CollapsibleSummaryButton,
} from '@vp/swan';
import { useEffect, useState } from 'react';
import { Filters } from 'client/components/Gallery/Filters';
import { PersonalizationFilter } from 'client/components/Gallery/Filters/PersonalizationFilter';
import { useTranslations } from 'client/hooks/useTranslations';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL } from '~/shared/constants';
import { PersonalizationHeader } from './PersonalizationHeader';
import { PersonalizationWrapper } from './PersonalizationWrapper';

export const PersonalizationSidebar = (): JSX.Element => {
    const [expanded, setExpanded] = useState(true);
    const localize = useTranslations();
    const analytics = useAnalytics();

    const handlePersonalizationCollapsiblePress = (exp: boolean): void => {
        setExpanded(exp);
        if (exp) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Personalization sidebar collapsible opened',
                eventDetail: 'Personalization sidebar collapsible opened',
                ...analytics.getPageProperties(),
            });
        } else {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Personalization sidebar collapsible closed',
                eventDetail: 'Personalization sidebar collapsible closed',
                ...analytics.getPageProperties(),
            });
        }
    };

    const handleFilterCollapsiblePress = (exp: boolean): void => {
        if (exp) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Filter collapsible opened',
                eventDetail: 'Filter collapsible opened',
                ...analytics.getPageProperties(),
            });
        } else {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Filter collapsible closed',
                eventDetail: 'Filter collapsible closed',
                ...analytics.getPageProperties(),
            });
        }
    };

    useEffect(() => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.PERSONALIZATION_VIEWED,
            eventLabel: ANALYTICS_LABEL.PERSONALIZATION_VIEWED,
            eventDetail: 'Personalization Sidebar Viewed',
            ...analytics.getPageProperties(),
        });
    }, [analytics]);

    return (
        <div className="personalization-sidebar-container">
            <Collapsible
                defaultExpanded
                className="personalization-sidebar-collapsible"
                expanded={expanded}
                mb="between-subsections"
                mx={3}
                pb={6}
                pt={0}
                px={4}
                onRequestExpandedChange={handlePersonalizationCollapsiblePress}
            >
                <CollapsibleSummary className="sidebar-collapsible-summary">
                    <CollapsibleSummaryButton className="sidebar-collapsible-button" pb={6} pt={6}>
                        <PersonalizationHeader />
                    </CollapsibleSummaryButton>
                </CollapsibleSummary>
                <CollapsibleContent className="sidebar-collapsible-content" pb={0} pr={0}>
                    <PersonalizationWrapper>
                        <PersonalizationFilter />
                    </PersonalizationWrapper>
                </CollapsibleContent>
            </Collapsible>
            <Box mx={4}>
                <Collapsible defaultExpanded mt={0} onRequestExpandedChange={handleFilterCollapsiblePress}>
                    <CollapsibleSummary className="sidebar-collapsible-summary">
                        <CollapsibleSummaryButton className="sidebar-filter-parent-button" pb={0}>{localize('PersonalizationAlternativeFiltersMenuHeader')}</CollapsibleSummaryButton>
                    </CollapsibleSummary>
                    <CollapsibleContent pr={0}>
                        <Filters />
                    </CollapsibleContent>
                </Collapsible>
            </Box>
        </div>
    );
};

PersonalizationSidebar.displayName = 'PersonalizationSidebar';
