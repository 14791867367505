import { createContext, Dispatch, SetStateAction } from 'react';
import { PERSONALIZATION_UX_EXPERIMENT_NAME, PERSONALIZATION_UX_VARIATIONS } from '~/experiments/tilePersonalization/constants';

export interface PersonalizationReactContextInterface {
    experimentName: string;
    experimentVariation: string;
    textfieldValues: Record<string, string>;
    setTextfieldValues: (Dispatch<SetStateAction<Record<string, string>>>) | null;
    isSmallScreenPersonalizationEnabled: boolean
    mobileTextfieldValues: Record<string, string>;
    setMobileTextfieldValues: (Dispatch<SetStateAction<Record<string, string>>>) | null;
}

export const PersonalizationReactContext = createContext<PersonalizationReactContextInterface>({
    experimentName: PERSONALIZATION_UX_EXPERIMENT_NAME,
    experimentVariation: PERSONALIZATION_UX_VARIATIONS.Enabled,
    textfieldValues: {},
    setTextfieldValues: null,
    isSmallScreenPersonalizationEnabled: false,
    mobileTextfieldValues: {},
    setMobileTextfieldValues: null,
});
