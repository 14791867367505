import {
    ModalDialogBody,
    ModalDialogContent,
    ModalDialogNav,
    ModalDialogCloseButton,
    Box,
} from '@vp/swan';
import { PersonalizationHeader } from 'client/components/Gallery/Filters/PersonalizationFilter/PersonalizationHeader';
import { PersonalizationFilter } from 'client/components/Gallery/Filters/PersonalizationFilter';

export const MobilePersonalizationModalContent = (): JSX.Element | null => (
    <ModalDialogContent fullBleed aria-label="mobile-personalization-content">
        <ModalDialogNav>
            <ModalDialogCloseButton accessibleText="Close" />
        </ModalDialogNav>
        <ModalDialogBody>
            <Box margin={5} marginTop={6}>
                <PersonalizationHeader mobileStyle />
                <PersonalizationFilter />
            </Box>
        </ModalDialogBody>
    </ModalDialogContent>
);

MobilePersonalizationModalContent.displayName = 'MobilePersonalizationModalContent';
