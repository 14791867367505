import { Outlet } from 'react-router-dom';
import { Header, Footer, BOOKEND_TYPES } from '@vp/esi-bookends';
import { SitewidePromoBar } from '@vp/sitewide-promo-bar/build/pure/index';
import config from 'config';
import { ComponentProps, useMemo } from 'react';
import { useAuth } from '~/client/hooks/useAuth';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { IntlContext } from '~/client/contexts/IntlContext';
import { useSelector } from 'react-redux';
import { getLocaleSelector } from '~/client/store/config';

interface PropTypes {
    messages: i18n.TranslationMap;
    sitewidePromobarConfig?: ComponentProps<typeof SitewidePromoBar>['promoBarConfig'];
}

export const Root = (props: PropTypes): JSX.Element => {
    const {
        messages,
        sitewidePromobarConfig,
    } = props;
    const locale = useSelector(getLocaleSelector);
    const auth = useAuth();
    const analytics = useAnalytics();
    const trackingConfiguration = analytics.getPageProperties();
    const localizations = useMemo(() => ({ messages }), [messages]);
    const { tenant } = config;

    return (
        <>
            <Header
                hideSearch
                hideVat={false}
                locale={locale}
                tenant={tenant}
                trackingConfiguration={trackingConfiguration}
                type={BOOKEND_TYPES.FULL}
            />
            {sitewidePromobarConfig && (
                <SitewidePromoBar
                    canonicalId={auth?.canonicalId || ''}
                    locale={locale}
                    pageType={config.sitewidePromobar.pageType}
                    promoBarConfig={sitewidePromobarConfig}
                    tenant={tenant}
                    trackingConfiguration={trackingConfiguration}
                />
            )}
            <IntlContext.Provider value={localizations}>
                <Outlet />
            </IntlContext.Provider>
            <Footer
                locale={locale}
                tenant={tenant}
                trackingConfiguration={trackingConfiguration}
                type={BOOKEND_TYPES.FULL}
            />
        </>
    );
};
