import { TILE_PREVIEW_SIZE } from 'client/constants';

export function calculateHeightFromAspectRatio(aspectRatio: number, width: number): number {
    return Math.floor(width / aspectRatio);
}

/**
 * Calculates preview width from a given aspect ratio.
 * This formula was derived by plotting the aspect ratio and widths of Gal5 previews
 * and coming up with a line of best fit
 * @param aspectRatio
 */
export function calculateWidthFromAspectRatio(aspectRatio: number, previewSize: TILE_PREVIEW_SIZE = TILE_PREVIEW_SIZE.DEFAULT): number {
    let calculatedWidth = 230 - (50 / aspectRatio);

    if (previewSize === TILE_PREVIEW_SIZE.LARGE) {
        calculatedWidth *= 1.4;
    }

    return Math.max(Math.ceil(calculatedWidth), 85);
}
