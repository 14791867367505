import { AnyAction } from 'redux';

const initialState: State.FilterTemplateUseCaseState = {};

export function reducer(
    state: State.FilterTemplateUseCaseState = initialState,
    action: AnyAction,
): State.FilterTemplateUseCaseState {
    switch (action.type) {
        default:
            return state;
    }
}

export const templateUseCaseByIdSelector = (state: State.GlobalState) => (templateUseCaseId: string): State.TemplateUseCase => state.filterTemplateUseCases[templateUseCaseId];

export const parentByChildTemplateUseCaseSelector = (state: State.GlobalState) => (templateUseCaseId: string): string => state.parentByChildTemplateUseCaseId[templateUseCaseId];
