import { useSelector } from 'react-redux';

import { useTranslations } from 'client/hooks/useTranslations';
import { getH1Title } from 'client/store/config';

export const usePreviewImageAlt = (comparativeName?: string): string => {
    const localize = useTranslations();
    const h1Title = useSelector(getH1Title);

    return localize('DefaultPreviewImageAlt', {
        productName: h1Title,
        comparativeName: comparativeName ? `, ${comparativeName}` : '',
    });
};
