import { FilterOption } from 'client/components/Gallery/Filters/FilterOption';
import { SingleSelectFilter } from 'client/components/Gallery/Filters/SingleSelect';

export interface PropTypes {
    filter: State.Filter;
}

export const RadioFilter = (props: PropTypes): JSX.Element => {
    const { filter } = props;

    return (
        <SingleSelectFilter filter={filter as State.AttributeFilter}>
            {(o: RenderableFilterOption): JSX.Element => (
                <FilterOption facetCount={o.facetCount} title={o.title} />
            )}
        </SingleSelectFilter>
    );
};

RadioFilter.displayName = 'RadioFilter';
