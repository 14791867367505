import { booleanRenderPropertySelector, getProductOptionsDescriptions } from 'client/store/config/reducer';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import {
    Accordion,
    BasicCollapsible,
    BasicResponsiveImage,
    Column,
    GridContainer,
    List,
    ListItem,
    Row,
    StandardTile,
    StandardTileContents,
    StandardTileDescription,
    StandardTileImage,
    StandardTileName,
    H3,
} from '@vp/swan';
import classnames from 'classnames';

import { useTranslations } from 'client/hooks/useTranslations';
import { RenderProperty } from 'shared/renderProperties';

/*
 * Inline components prevent react-markdown props from being passed directly to the components
 * One of these props is `ordered` which is a boolean prop that returns true if the parent is an `ol` (false if `ul`).
 * See: https://github.com/remarkjs/react-markdown#appendix-b-components
 * This prop causes the browser console error: "Warning: Received false for a non-boolean attribute ordered"
 */
const markdownComponents = {
    ul: function MarkdownList({ children }: { children: React.ReactNode }): JSX.Element | null {
        return <List>{children}</List>;
    },
    li: function MarkdownListItem({ children }: { children: React.ReactNode }): JSX.Element | null {
        return <ListItem>{children}</ListItem>;
    },
};

export const ProductOptionsDescriptions = (): JSX.Element | null => {
    const productOptionsDescriptions = useSelector(getProductOptionsDescriptions);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const showProductOptionsDescription = booleanRenderProperty(RenderProperty.ShowProductOptionsDescription);

    const localize = useTranslations();

    return showProductOptionsDescription
        && productOptionsDescriptions
        && productOptionsDescriptions.length ? (
            <>
                <H3 className="product-options-descriptions" fontSize="large">
                    {localize('ProductOptions')}
                </H3>
                <Accordion>
                    {productOptionsDescriptions.map((pod) => (
                        <BasicCollapsible className="product-collapsible" collapsibleId={pod.name} heading={pod.name}>
                            <GridContainer>
                                <Row>
                                    {pod.valueDescriptions.map((vd) => (
                                        <Column span={4}>
                                            <StandardTile layout="vertical">
                                                <StandardTileImage>
                                                    <BasicResponsiveImage
                                                        aspectRatio={1}
                                                        srcSet={vd.image}
                                                    />
                                                </StandardTileImage>
                                                <StandardTileContents className="standard-tile-contents">
                                                    <StandardTileName>{vd.title}</StandardTileName>
                                                    {vd.text && (
                                                        <StandardTileDescription>
                                                            <ReactMarkdown
                                                                className={classnames(
                                                                    'list-bullet',
                                                                    'text-size-6',
                                                                    'text-color-dark-grey',
                                                                )}
                                                                components={markdownComponents}
                                                            >
                                                                {vd.text}
                                                            </ReactMarkdown>
                                                        </StandardTileDescription>
                                                    )}
                                                </StandardTileContents>
                                            </StandardTile>
                                        </Column>
                                    ))}
                                </Row>
                            </GridContainer>
                        </BasicCollapsible>
                    ))}
                </Accordion>
            </>
        ) : null;
};

ProductOptionsDescriptions.displayName = 'ProductOptionsDescriptions';
