import { useSelector } from 'react-redux';
import { Box, Button } from '@vp/swan';

import { ClearAllRefinementsTextButton } from 'client/components/common/ClearAllRefinementsTextButton';
import { findAllRemovableRefinements } from 'client/components/Gallery/Subheader/RefinementsList/findAllRemovableRefinements';
import { useTranslations } from 'client/hooks/useTranslations';

export interface PropTypes {
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Mobile Filter Menu
 * @param props
 */
export const FilterMenuFooter = (props: PropTypes): JSX.Element => {
    const { onClose } = props;
    const localize = useTranslations();
    const refinements = useSelector(findAllRemovableRefinements);

    return (
        <Box className="filter-menu-footer" mb={0}>
            <ClearAllRefinementsTextButton refinements={refinements} width="full-width" />
            <Button
                skin="primary"
                width="full-width"
                onClick={onClose}
            >
                {localize('FilterMenuDoneButtonText')}
            </Button>
        </Box>
    );
};

FilterMenuFooter.displayName = 'FilterMenuFooter';
