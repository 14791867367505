import { useSelector } from 'react-redux';
import { booleanRenderPropertySelector } from 'client/store/config';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { RenderProperty } from 'shared/renderProperties';
import { TEMPLATE_NEW_TILE_VARIATIONS } from '~/experiments/TemplateNewTile/constants';

export const useShowNewTemplateTile = (): boolean => {
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);

    const isExperimentActive = useExperimentation();
    const isNewTemplateTileEnabled = isExperimentActive(
        TEMPLATE_NEW_TILE_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );

    return isNewTemplateTileEnabled || booleanRenderProperty(RenderProperty.ShowNewTemplateTile);
};
