export enum TIMERS {
    PROCESSING_TIMER = 'PROCESSING_TIMER',
    UPLOAD_TIMER = 'UPLOAD_TIMER',
    LOADING_TIMER = 'LOADING_TIMER',
}

export const startTimer = (name: TIMERS, id = ''): void => {
    performance.mark(`${name}_${id}`);
};

export const clearTimer = (name: TIMERS, id = ''): void => {
    performance.clearMarks(`${name}_${id}`);
};

export const getTimerDuration = (name: TIMERS, id = ''): number => {
    // if the error handler fired before the upload start handler, just use the current time
    const startTime = performance.getEntriesByName(`${name}_${id}`).length
        ? performance.getEntriesByName(`${name}_${id}`)[0].startTime
        : performance.now();

    const endTime = performance.now();

    return (endTime - startTime) / 1000;
};
