export const scrollToElement = (elementId: string, scrollOptions?: ScrollIntoViewOptions): void => {
    const element = document.getElementById(elementId);

    if (element) {
        element.scrollIntoView(scrollOptions);
    }
};

export const scrollUpToElement = (elementId: string, scrollOptions?: ScrollIntoViewOptions): void => {
    const element = document.getElementById(elementId);

    if (!element) {
        return;
    }

    const elementOffsetTop = element.getBoundingClientRect().top;

    if (elementOffsetTop < 0) {
        element.scrollIntoView(scrollOptions);
    }
};
