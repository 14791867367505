import { Button, Icon, Typography } from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { useSetRecoilState } from 'recoil';
import { filtersFlyoutOpenState, searchAutoFocusedState } from 'client/atoms/filtersFlyout';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { useSelector } from 'react-redux';
import { getRawExperiments } from '~/client/store/experimentation';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME, PERSONALIZATION_UX_MOBILE_VARIATIONS } from '~/experiments/tilePersonalization/constants';
import { isSmallScreen } from '~/client/utils/deviceDetection';

interface SearchButtonProps {
    shouldHideText?: boolean;
}

export const SearchButton = (props: SearchButtonProps): JSX.Element => {
    const { shouldHideText } = props;
    const localize = useTranslations();
    // Header Hierarchry Test: Boths recoil states are needed to allow the mobile search button to open
    // the filters flyout and focus the search input.
    const setFiltersFlyoutOpen = useSetRecoilState(filtersFlyoutOpenState);
    const setSearchAutoFocused = useSetRecoilState(searchAutoFocusedState);

    // hooks added for temporary Personalization UX experiment tracking
    const isExperimentActive = useExperimentation();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();

    const handleTransitionEnd = (): void => {
        setSearchAutoFocused(true);
        setFiltersFlyoutOpen(true);

        if (isSmallScreen()) {
            if (
                isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)
            ) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_MOBILE_VARIATIONS.Control,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Search Button',
                    PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
                    'Search Button Clicked',
                );
            } else if (isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Search Button',
                    PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
                    'Search Button Clicked',
                );
            }
        }
    };

    return (
        <Button
            className="search-button"
            iconPosition="right"
            title={localize('HeaderHierarchySearchDesigns')}
            width={shouldHideText ? 'standard' : 'full-width'}
            onClick={handleTransitionEnd}
        >
            {!shouldHideText && <Typography as="span" fontSize="standard" fontWeight="normal">{localize('HeaderHierarchySearchDesigns')}</Typography>}
            <Icon iconType="search" />
        </Button>
    );
};

SearchButton.displayName = 'SearchButton';
