import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { tileEntityByIdSelector } from 'client/store/tileEntity';
import { useSelector } from 'react-redux';
import { usePreviewImageAlt } from 'client/hooks/usePreviewImageAlt';
import { colorSwatchSelector, maxPreviewHeightSelector } from 'client/store/design';
import {
    PREVIEW_TYPE,
} from 'shared/constants';
import { resolveInitialSwatchId } from 'shared/resolveInitialSwatchId';
import { getBypassApproval } from 'client/store/debug';
import { MerchModulesTileEntityProviderProps } from '~/experiments/MerchModules/interface';
import { shouldRestorePhotosState } from '~/client/atoms/shouldRestorePhotosAtom';

export const MerchModulesTileEntityProvider = (props: MerchModulesTileEntityProviderProps): JSX.Element | null => {
    const { tileEntityId, children } = props;
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const selectColorSwatch = useSelector(colorSwatchSelector);
    const merchTileEntity = tileEntityById(tileEntityId);
    const defaultAltText = usePreviewImageAlt(merchTileEntity.comparativeName);
    const [currentDesignId, setCurrentDesignId] = useState(resolveInitialSwatchId(merchTileEntity));
    const [shouldRestorePhotos] = useRecoilState(shouldRestorePhotosState);

    const currentColorSwatch = selectColorSwatch(
        currentDesignId,
        tileEntityId,
        PREVIEW_TYPE.TILE,
        shouldRestorePhotos,
    );
    const previewAlt = currentColorSwatch.altText ?? merchTileEntity.altText ?? defaultAltText;

    const maxPreviewHeight = useSelector(maxPreviewHeightSelector);
    const bypassedApproval = useSelector(getBypassApproval);

    const {
        previewWidth,
        previewsUrls,
    } = currentColorSwatch.renderablePreviews;

    const imageProps = {
        alt: previewAlt,
        previewWidth,
        previewsUrls,
        maxPreviewHeight,
        bypassedApproval,
    };

    useEffect(() => {
        setCurrentDesignId(resolveInitialSwatchId(merchTileEntity));
    }, [merchTileEntity]);

    const handleColorSwatchChange = (newDesignId: string): void => {
        setCurrentDesignId(newDesignId);
    };

    return children(
        merchTileEntity,
        imageProps,
        handleColorSwatchChange,
        currentColorSwatch.colorSwatches,
        currentColorSwatch,
        currentDesignId,
    );
};
