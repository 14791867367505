import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';

export class ImageProcessingError extends Error {
    asset: VistaAsset;

    constructor(message: string, asset: VistaAsset) {
        super(message);

        this.asset = asset;
    }
}
