import { useSelector } from 'react-redux';
import { Typography } from '@vp/swan';

import { RefinementsList } from 'client/components/Gallery/Subheader/RefinementsList';
import { totalEntityCountSelector } from '~/client/store/paging';
import { useTranslations } from '~/client/hooks/useTranslations';

export const Subheader = (): JSX.Element => {
    const localize = useTranslations();
    const resultCount = useSelector(totalEntityCountSelector);
    const resultCopy = `${resultCount} ${resultCount === 1 ? localize('Result') : localize('Results')}`;

    return (
        <section className="gallery-subheader">
            {resultCount > 0 && (
                <Typography className="result-count" fontSize="xsmall">
                    {resultCopy}
                </Typography>
            )}
            <RefinementsList />
        </section>
    );
};

Subheader.displayName = 'Subheader';
