import { AnyAction } from 'redux';

import { CONTENT_UPDATE } from 'client/store/constants';

const initialState: State.ProductOptionsByProductKeyState = {};

export function reducer(
    state: State.ProductOptionsByProductKeyState = initialState,
    action: AnyAction,
): State.ProductOptionsByProductKeyState {
    switch (action.type) {
        case CONTENT_UPDATE:
            return {
                ...action.payload.productOptionsByProductKeyMap,
            };
        default:
            return state;
    }
}

export const productOptionsByProductKeySelector = (state: State.GlobalState): Gallery.Product.ProductOptionsGroupsByProductKey => state.productOptionsByProductKey;
