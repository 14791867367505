import { PaginationButton as SwanPaginationButton } from '@vp/swan';

import { useLinkParameters } from 'client/hooks/useLinkParameters';
import { ComponentProps } from 'react';

export interface PropTypes extends ComponentProps<typeof SwanPaginationButton> {
    pageNumber: number;
}

export const PaginationButton = (props: PropTypes): JSX.Element => {
    const {
        disabled,
        onClick,
        pageNumber,
        ...rest
    } = props;

    const linkHref = useLinkParameters(pageNumber);

    return (
        <SwanPaginationButton
            {...rest}
            disabled={disabled}
            href={linkHref}
            onClick={disabled ? undefined : onClick}
        />
    );
};

PaginationButton.displayName = 'PaginationButton';
